import React, { useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import JsonView from '@uiw/react-json-view';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import {
  StyledCopyButton,
  StyledTitle,
  StyledValue
} from './jsonViewItemStyles';
import Strings from '../../../i18n';

export const JsonViewItem = ({
  title,
  values
}: {
  title: string;
  values: string;
}) => {
  const formatValues = values.replace(/"/g, '');
  const inputRef = useRef<HTMLInputElement>(null);

  const copyText = async () => {
    if (inputRef.current) {
      inputRef.current.select();
      try {
        await navigator.clipboard.writeText(values);
        toast.success(Strings.common.copiedText);
      } catch (err) {
        toast.error(Strings.common.errorCopyText);
      }
    }
  };

  const isJsonArray = (str: string) => {
    try {
      const parsed = JSON.parse(str);
      return Array.isArray(parsed);
    } catch (e) {
      return false;
    }
  };

  return (
    <div>
      <StyledTitle>
        <Tooltip title={Strings.common.copyText}>
          <StyledCopyButton onClick={copyText} type="button">
            <i className="pi pi-clone" />
          </StyledCopyButton>
        </Tooltip>
        {title}
      </StyledTitle>
      <div>
        {isJsonArray(values) ? (
          <JsonView
            value={JSON.parse(values)}
            displayDataTypes={false}
            displayObjectSize={false}
            indentWidth={2}
            shortenTextAfterLength={1000}
          />
        ) : (
          <StyledValue>{formatValues}</StyledValue>
        )}
      </div>
      <InputText
        value={formatValues}
        style={{ display: 'none' }}
        ref={inputRef}
      />
    </div>
  );
};
