/* eslint-disable no-shadow */
export enum viewTypeEnum {
  tileView = 'tile',
  listView = 'list',
  mapView = 'map',
  foldersView = 'folders'
}
export enum tokenCustomClaimsEnum {
  userId = 'http://havenlighting.com/userid'
}

export const BRIGHTNESS_VALUES = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

export enum LIGHT_COLOR_TYPE {
  Off = 1,
  White = 2,
  Solid = 3,
  Pattern = 4,
  Sparkle = 5,
  default = 0
}

export enum TAB_COLOR_INDEX {
  colors = 'Color',
  patterns = 'Pattern',
  sparkles = 'Sparkle',
  effects = 'Effects',
  tapeMeasure = 'Tape Measure',
  addEffect = 'Add Effect'
}

export const LIGHT_CAPABILITIES = [
  {
    name: 'BRIGHTNESS'
  },
  {
    name: 'LEGACY_SOLID_COLORS'
  },
  { name: 'SPARKLE' },
  {
    name: 'EXTENDED_SOLID_COLORS'
  },
  {
    name: 'EXTENDED_PATTERNS'
  },
  {
    name: 'LEGACY_PATTERNS'
  },
  {
    name: 'TEMPERATURE'
  },
  {
    name: 'PLAYGROUND_SCENES'
  },
  { name: 'TEMPERATURE' },
  { name: 'HALLOWEEN_AS_A_SOLID_COLOR' },
  { name: 'FORCE_UPDATE' },
  { name: 'TRIM_LIGHT' }
];

export enum LightCapabilitiesEnum {
  BRIGHTNESS = 'BRIGHTNESS',
  LEGACY_SOLID_COLORS = 'LEGACY_SOLID_COLORS',
  EXTENDED_SOLID_COLORS = 'EXTENDED_SOLID_COLORS',
  SPARKLE = 'SPARKLE',
  LEGACY_PATTERNS = 'LEGACY_PATTERNS',
  EXTENDED_PATTERNS = 'EXTENDED_PATTERNS',
  TEMPERATURE = 'TEMPERATURE',
  PLAYGROUND_SCENES = 'PLAYGROUND_SCENES',
  HALLOWEEN_AS_A_SOLID_COLOR = 'HALLOWEEN_AS_A_SOLID_COLOR',
  SOLID_COLORS = 'SOLID_COLORS',
  FORCE_UPDATE = 'FORCE_UPDATE',
  TRIM_LIGHT = 'TRIM_LIGHT'
}

export enum tokenUserIdEnum {
  notFound = 0,
  unauthorized = -1
}

export enum globalSearchEnum {
  locations = 'Locations',
  devices = 'Devices',
  users = 'Users',
  ssid = 'SSID',
  shared = 'Shared'
}

export const GlobalSearchTypes = [
  globalSearchEnum.locations,
  globalSearchEnum.devices,
  globalSearchEnum.users,
  globalSearchEnum.ssid,
  globalSearchEnum.shared
];

export const CommandsTypesItemEnum = {
  Light: 'Light',
  Zone: 'Zone',
  Group: 'Group',
  Location: 'Location',
  Organization: 'Organization'
};

export enum severityEnum {
  low = 1,
  medium = 2,
  high = 3
}

export enum homeListTypes {
  location = 'Location',
  businessAccount = 'BusinessAccount',
  organization = 'Organization'
}

export enum shareLocationWithTargetsEnum {
  email = 1,
  businessAccount,
  domain
}

export enum locationRoleEnum {
  root = 1,
  admin = 2,
  production = 3
}

export enum ActionType {
  Link = 'Link',
  Button = 'Button'
}

export enum ActionVerb {
  GET = 'GET',
  POST = 'POST'
}

export enum TypesInput {
  string = 'string',
  int = 'int',
  date = 'date',
  list = 'list'
}

export enum ComponentName {
  endDate = 'End Date',
  startDate = 'Start Date'
}

export enum DataType {
  ARRAY = 'ARRAY',
  JSON = 'JSON',
  BOOLEAN = 'BOOLEAN',
  DATETIME = 'DATETIME',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  LONGSTRING = 'LONGSTRING',
  DATE = 'DATE'
}
export enum directionSort {
  ascending = 'ascending',
  descending = 'descending'
}

export enum SortOrder {
  ASCENDING = 1,
  DESCENDING = -1
}
