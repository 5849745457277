/* eslint-disable no-shadow */
export enum systemModuleEnum {
  devices = 'Devices',
  products = 'Products',
  versions = 'Versions',
  locations = 'Locations',
  organizations = 'Organizations',
  serviceHealth = 'Service/Health',
  users = 'Users',
  deviceAnnounce = 'DeviceAnnounce',
  firmwareSchedule = 'FirmwareSchedule',
  reports = 'Reports'
}

export enum systemModulePermissionsEnum {
  assignLater,
  read = 'Read',
  readWrite = 'Write',
  noPermissions = 'NoPermissions'
}
