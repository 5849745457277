import { Box, FormControlLabel, FormLabel, TextField } from '@mui/material';
import styled from 'styled-components';

export const ModalContainer = styled(Box)(({ theme }) => ({
  height: '80%',
  padding: '10px',
  '@media (max-width: 768px)': {
    width: '630px',
    height: '380px',
    padding: '10px'
  },
  '@media (max-width: 480px)': {
    width: '450px',
    height: '330px'
  },
  '@media (max-width: 320px)': {
    width: '190px',
    height: '120px'
  }
}));

export const FilterReportInputs = styled(TextField)(({ theme }) => ({
  width: '90%',
  marginBottom: '10px',
  boxShadow: '2px 2px 2px 1px rgb(64 60 67 / 16%)'
}));

export const ReportsContainer = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  width: '100%',
  height: '75%',
  marginTop: '10px'
}));

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: '10px'
}));

export const CategoryReports = styled(FormLabel)(({ theme }) => ({
  marginBottom: '10px'
}));
