import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';
import {
  styledCheckboxHeader,
  styledColumnHeaderCheckbox
} from '../../../components/layout/main/mainStyles';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { useGetGroupsByLocationIdMutation } from '../../../services/groups/groupsService';
import { GroupTemplateProps } from './groupsListTemplateProps';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { AddGroupBar } from './addGroup/addGroup';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import { StyledGroupTotalLightsButton } from './groupsStyle';
import { IGroup } from '../../../types/Groups/groupsInterfaces';
import { DetailsGroupBar } from './detailsGroup/detailsGroup';
import { BrightnessSelectorBtn } from '../../../components/commons/BrightnessSelector/brightnessSelector';
import { ColorSelector } from '../../../components/commons/ColorSelector/ColorSelector';
import { SwitchOnOff } from '../../../components/commons/SwitchOnOff/switchOnOff';
import {
  useSendColorCommandMutation,
  useSendBrightnessCommandMutation,
  useSendOnOffCommandMutation
} from '../../../services/commandsService/commandsService';
import {
  ICommandBrightness,
  ICommandSetColor,
  ICommandOnOff
} from '../../../types/commandsInterface';
import Strings from '../../../i18n';
import { ContainerTable } from '../../locations/locationsList/locationListStyles';

export const GroupsListTemplate = ({
  setOpenSideBar,
  openRightSideBarForMethod,
  openSideBar,
  sideBarOpenFor
}: GroupTemplateProps) => {
  const { locationId } = useParams();
  const [selectedGroups, setSelectedGroups] = useState<IGroup[]>([]);
  const [
    getGroups,
    { isSuccess: successGroups, data: dataGroups, isLoading: isLoadingGroups }
  ] = useGetGroupsByLocationIdMutation();

  const [setColor, { isSuccess: isSuccessSetColor }] =
    useSendColorCommandMutation();

  const [setBrightness, { isSuccess: isSuccessSetBrightness }] =
    useSendBrightnessCommandMutation();

  const [setSwitch, { isSuccess: isSuccessSetSwitch }] =
    useSendOnOffCommandMutation();

  const reloadTable = () => {
    if (locationId) {
      getGroups(+locationId);
    }
  };
  useEffect(() => {
    reloadTable();
  }, []);
  const sendBrightnessCommand = (payload: ICommandBrightness) => {
    setBrightness(payload);
  };

  const sendColorCommand = (payload: ICommandSetColor) => {
    setColor(payload);
  };

  const sendSwitchCommand = (payload: ICommandOnOff) => {
    setSwitch(payload);
  };
  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: dataGroups && dataGroups.length ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={(dataGroups && selectedGroups.length > 0) ?? false}
        onChange={() => {
          setSelectedGroups([]);
        }}
      />
    );
  };
  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.addGroup:
        return <div />;
      case viewRightSideBarEnum.groupDetails:
        return (
          selectedGroups.length === 1 && (
            <DetailsGroupBar
              reloadTable={reloadTable}
              group={selectedGroups[0]}
            />
          )
        );
      default:
        return <AddGroupBar reloadTable={() => reloadTable()} />;
    }
  };

  const getLightsByGroup = (rowData: any) => {
    return (
      <div>
        <StyledGroupTotalLightsButton
          type="button"
          onClick={() => {
            setSelectedGroups([rowData]);
            openRightSideBarForMethod(viewRightSideBarEnum.groupDetails);
          }}
        >
          <Visibility className="mr-2" />
          {rowData.lights.length}
        </StyledGroupTotalLightsButton>
      </div>
    );
  };
  const getCommands = (item: IGroup) => {
    return (
      <div className="d-flex align-items-center my-1">
        <SwitchOnOff
          lightingStatusId={item.lightingStatus}
          onSwitch={(value) =>
            sendSwitchCommand({
              id: item.groupId,
              method: value ? 'On' : 'Off',
              type: 'Group'
            })
          }
        />
        <BrightnessSelectorBtn
          lightBrightnessId={item.brightnessId}
          onChange={(brightnessId) =>
            sendBrightnessCommand({
              brightness: brightnessId,
              id: item.groupId,
              type: 'Group'
            })
          }
        />
        <ColorSelector
          status={{
            colorId: item.colorId,
            patternSpeed: item.patternSpeed,
            sparkleDuration: 0
          }}
          capabilities={item.capabilities}
          onColorChange={(colorId) => {
            sendColorCommand({
              id: item.groupId,
              colorId,
              type: 'Group'
            });
          }}
          selectedItem={{ id: item.groupId, type: 'Group' }}
        />
      </div>
    );
  };
  useEffect(() => {
    if (selectedGroups.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.groupDetails);
    } else if (selectedGroups.length > 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.multipleGroupsDetails);
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedGroups]);

  useEffect(() => {
    if (isSuccessSetColor) {
      toast.success(Strings.trimLights.setColorSuccess);
    }
  }, [isSuccessSetColor]);

  useEffect(() => {
    if (isSuccessSetBrightness) {
      toast.success(Strings.commands.setBrightnessSuccess);
    }
  }, [isSuccessSetBrightness]);

  useEffect(() => {
    if (isSuccessSetSwitch) {
      toast.success(Strings.commands.commandSentSuccessfully);
    }
  }, [isSuccessSetSwitch]);
  return (
    <>
      {isLoadingGroups && <LoadingRowsComponent />}
      {successGroups && dataGroups && (
        <ContainerTable component="main">
          <DataTable
            selection={selectedGroups}
            selectionMode="checkbox"
            onSelectionChange={(e) => setSelectedGroups(e.value)}
            className="mt-3"
            rowHover
            value={dataGroups}
            size="small"
            scrollable
            scrollHeight="flex"
            paginator={dataGroups && dataGroups.length >= 50}
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[
              5,
              10,
              20,
              50,
              dataGroups ? dataGroups.length : 50
            ]}
            tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={styledColumnHeaderCheckbox}
              header={getHeaderCheckbox}
              frozen
            />
            <Column field="groupName" header="Name" sortable />
            <Column field="shortName" header="Short Name" sortable />
            <Column
              field="lights"
              header="Lights"
              sortable
              body={getLightsByGroup}
            />
            <Column field="" header="Commands" body={getCommands} />
          </DataTable>
        </ContainerTable>
      )}
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
          setSelectedGroups([]);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
