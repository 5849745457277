import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { LocationListProps } from './locationListProps';
import {
  StatusProcess,
  OwnerBox,
  TriangleOrg,
  StyledButtonName,
  StyledLocationName,
  ContainerTable
} from './locationListStyles';
import Strings from '../../../i18n';
import { selectSystemLocationsReadWrite } from '../../../store/slices/systemPermissions/systemPermissions';
import { useUserSelectedLocationMutation } from '../../../services/device/deviceService';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { setOpenSideBar } from '../../../store/slices/app/app';
import { setNameOrganization } from '../../../store/slices/organization/organization';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import {
  styledCheckboxHeader,
  styledColumnHeaderCheckbox,
  styledColumnItem
} from '../../../components/layout/main/mainStyles';
import { ILocOrgItem } from '../../../types/location/location';

const triangleOrg = require('../../../assets/triangleOrg.png');

export const LocationsList = ({
  locOrgByUser,
  selectedLocations,
  setSelectedLocations,
  setOpenRightSideBar,
  openRightSideBarForMethod
}: LocationListProps) => {
  const permissionReadWrite = useSelector(selectSystemLocationsReadWrite);
  const contextUser = useSelector(selectCurrentUser);
  const [selectLocationPost] = useUserSelectedLocationMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectLocation = (params: any, isOrg?: boolean) => {
    if (isOrg) {
      dispatch(setNameOrganization(params.name));
      navigate(`/Organizations/${params.id}`);
    } else {
      if (!permissionReadWrite) return;
      selectLocationPost({
        locationId: params.id,
        userId: contextUser.userId || 0
      });

      dispatch(setOpenSideBar(true));
      navigate(`/locations/${params.id}/devices`);
    }
  };
  const goToBusinessAccount = (id: number) => {
    navigate(`/BusinessAccount/${id}`);
  };
  const getIconColum = (item: any) => {
    switch (item.type) {
      case 'Organization':
        return <TriangleOrg src={triangleOrg} alt="triangleOrg" />;
      case 'Location':
        return <StatusProcess />;
      case 'BusinessAccount':
        return <i className="pi pi-briefcase" style={{ fontSize: '.8rem' }} />;
      default:
        return '';
    }
  };
  const getName = (item: ILocOrgItem) => {
    switch (item.type) {
      case 'Organization':
        return (
          <StyledLocationName>
            <StyledButtonName
              onClick={() => selectLocation(item, true)}
              badge={item.locationCount}
            >
              {item.name}
            </StyledButtonName>
          </StyledLocationName>
        );
      case 'Location':
        return (
          <StyledLocationName>
            <StyledButtonName onClick={() => selectLocation(item)}>
              {item.name}
            </StyledButtonName>
          </StyledLocationName>
        );
      case 'BusinessAccount':
        return (
          <StyledLocationName>
            <StyledButtonName
              onClick={() => goToBusinessAccount(item.id)}
              badge={item.locationCount}
            >
              {item.name}
            </StyledButtonName>
          </StyledLocationName>
        );
      default:
        return '';
    }
  };
  const getRoleColumn = (item: any) => {
    const type = item.isOwner
      ? Strings.locations.owner
      : Strings.locations.shared;
    return (
      <OwnerBox>
        {item.businessAccountName
          ? `${item.businessAccountName}: ${type}`
          : type}
      </OwnerBox>
    );
  };

  const getOwnerColumn = (item: ILocOrgItem) => {
    return item.isOwner
      ? `${contextUser.userInfo?.firstName} ${contextUser.userInfo?.lastName}`
      : item.owner;
  };

  useEffect(() => {
    if (selectedLocations.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.locationDetails);
    } else if (selectedLocations.length > 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.multipleLocationsDetails);
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenRightSideBar(false);
    }
  }, [selectedLocations]);

  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedLocations.length ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={
          (locOrgByUser && selectedLocations && selectedLocations.length > 0) ??
          false
        }
        onChange={() => {
          setSelectedLocations([]);
        }}
      />
    );
  };

  return (
    <ContainerTable component="main">
      <DataTable
        className="mt-3"
        selection={selectedLocations}
        selectionMode="checkbox"
        onSelectionChange={(e) => setSelectedLocations(e.value)}
        rowHover
        value={locOrgByUser}
        size="small"
        scrollable
        scrollHeight="flex"
        paginator={locOrgByUser && locOrgByUser.length >= 20}
        selectionPageOnly
        rows={20}
        rowsPerPageOptions={[
          5,
          10,
          20,
          locOrgByUser ? locOrgByUser.length : 50
        ]}
        tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
      >
        <Column
          selectionMode="multiple"
          headerStyle={styledColumnHeaderCheckbox}
          header={getHeaderCheckbox}
          frozen
        />
        <Column
          field=""
          header=""
          body={getIconColum}
          frozen
          style={{ width: '30px' }}
        />
        <Column
          field="name"
          header="Name"
          sortable
          body={getName}
          style={styledColumnItem}
          frozen
        />
        <Column
          field="isOwner"
          header="Role"
          sortable
          body={getRoleColumn}
          style={{ textAlign: 'left', ...styledColumnItem }}
        />
        <Column
          field="owner"
          header="Owner"
          sortable
          style={styledColumnItem}
          body={getOwnerColumn}
        />
        <Column
          field="address"
          header="Address"
          sortable
          style={styledColumnItem}
        />
        <Column field="status" header="Status" sortable />
      </DataTable>
    </ContainerTable>
  );
};
