import { styled, Box, Grid, Button } from '@mui/material';
import { colors } from '../../../constants/colors';

export const CardsContainer = styled(Box)({
  backgroundColor: colors.white,
  height: '100%',
  overflow: 'scroll'
});

export const ColumnGrid = styled(Grid)({
  alignContent: 'center',
  height: '100%',
  padding: '15px'
});

export const RowGrid = styled(Grid)(({ theme }) => ({
  margin: `10px auto`
}));

export const ItemContainer = styled(Box)(({ theme }) => ({
  margin: `10px`
}));

export const SearchButton = styled(Button)(({ theme }) => ({
  margin: '10px'
}));
