/* eslint-disable */
import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Checkbox, IconButton, Typography } from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import '../responsiveTableStyles.css';
import {
  capitalizeFirstLetter,
  createPath,
  formatDate,
  secondsToSecondsAndMinutes,
  utcDateToLocalDateTime
} from '../../../utils/dynamicReportsUtils';
import { DynamicTableProps } from './dynamicTableProps';
import {
  TableContainer,
  NoDataContainer,
  HeaderContainer,
  IconContainer,
  TitleTableContainer,
  ValueTableContainer
} from './dynamicTableStyles';
import { ColumnItem } from '../../../types/dynamicReports/dynamicReportsInterface';
import { ModalJson } from '../modalJson/modalJson';
import { PopUpArrays } from '../popUpArrays/popUpArrays.component';
import { ModalLongString } from '../modalLongString/modalLongString';
import { useDynamicReportsConfig } from '../../../constants/context/dynamicReportsProvider/useDynamicReportsProvider';
import { DataType, directionSort } from '../../../constants/commonEnums';
import { HeaderDynamicReport } from '../../../constants/headerDynamicReport';
import { useSortableData } from '../../../hooks/useSortTableData';
import { useNavigate } from 'react-router-dom';

export const DynamicTable: React.FunctionComponent<DynamicTableProps> = ({
  columns,
  rows
}) => {
  const { configDynamicReports } = useDynamicReportsConfig();
  const { items, requestSort, sortConfig } = useSortableData(rows);
  const [limit, setLimit] = useState<number>(50);
  const [openModalJson, setOpenModalJson] = useState<boolean>(false);
  const [openArrayPopUp, setOpenArrayPopUp] = useState<boolean>(false);
  const [openModalLongString, setOpenModalLongString] =
    useState<boolean>(false);
  const [json, setJson] = useState<null | any>(null);
  const [array, setArray] = useState<null | any>(null);
  const [longString, setLongString] = useState<string>('');
  const navigate = useNavigate();
  const handleCloseArraysPopUp = () => {
    setOpenArrayPopUp(false);
  };

  const handleCloseModalJson = () => {
    setOpenModalJson(false);
  };

  const handleCloseModalLongString = () => {
    setOpenModalLongString(false);
  };

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      setLimit((prev) => prev + 10);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getColumnSort = (name: string) => {
    if (!sortConfig) {
      return <ImportExportIcon />;
    }
    if (sortConfig.key === name) {
      if (sortConfig.direction === directionSort.ascending) {
        return <ArrowDownwardIcon />;
      }
      if (sortConfig.direction === directionSort.descending) {
        return <ArrowUpwardIcon />;
      }
    }
    return <ImportExportIcon />;
  };

  const filteredItems = items?.filter(
    (item: any) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(
          configDynamicReports.filterText
            ? configDynamicReports.filterText.toLowerCase()
            : ''
        ) !== -1
  );

  if (
    !columns ||
    !rows ||
    (columns && !columns.length) ||
    (rows && !rows.length)
  ) {
    return (
      <NoDataContainer>
        <Typography>No data...</Typography>
      </NoDataContainer>
    );
  }

  const renderDynamicTable = (columnsTable: ColumnItem[], rowsTable: any[]) =>
    rowsTable?.slice(0, limit).map((row, index) => (
      <Tr key={index}>
        {columnsTable.map((column: ColumnItem) => {
          if (column.dataType === DataType.DATE) {
            return (
              <Td key={column.name}>
                <LazyLoad height={30}>
                  {utcDateToLocalDateTime(
                    row[capitalizeFirstLetter(column.name)]
                  )}
                </LazyLoad>
              </Td>
            );
          }
          if (column.dataType === DataType.LONGSTRING) {
            return (
              <Td key={column.name}>
                <LazyLoad height={30}>
                  <IconButton
                    onClick={() => {
                      setLongString(row[capitalizeFirstLetter(column.name)]);
                      setOpenModalLongString(true);
                    }}
                  >
                    <OpenInBrowserIcon color="action" />
                  </IconButton>
                </LazyLoad>
              </Td>
            );
          }
          if (
            capitalizeFirstLetter(column.name) ===
              HeaderDynamicReport.sunRiseDifference ||
            capitalizeFirstLetter(column.name) ===
              HeaderDynamicReport.sunSetDifference
          ) {
            return (
              <Td key={column.name}>
                <LazyLoad height={30}>
                  {`${
                    secondsToSecondsAndMinutes(
                      row[capitalizeFirstLetter(column.name)]
                    )[0]
                  }:${
                    secondsToSecondsAndMinutes(
                      row[capitalizeFirstLetter(column.name)]
                    )[1]
                  }`}
                </LazyLoad>
              </Td>
            );
          }
          if (column.isLink && column.linkDataParameter) {
            return (
              <Td key={column.name}>
                <LazyLoad height={30}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={createPath(
                      row[capitalizeFirstLetter(column.linkDataParameter)],
                      column.linkPath
                    )}
                  >
                    {row[capitalizeFirstLetter(column.name)]}
                  </a>
                </LazyLoad>
              </Td>
            );
          }

          if (
            !row[capitalizeFirstLetter(column.name)] &&
            column.dataType !== DataType.BOOLEAN &&
            row[capitalizeFirstLetter(column.name)] < 0
          ) {
            return (
              <Td key={column.name}>
                <LazyLoad height={30}>N.A</LazyLoad>
              </Td>
            );
          }

          if (column.dataType === DataType.BOOLEAN) {
            return (
              <Td key={column.name}>
                <LazyLoad height={30}>
                  <Checkbox
                    checked={row[capitalizeFirstLetter(column.name)]}
                    color="success"
                  />
                </LazyLoad>
              </Td>
            );
          }
          if (column.dataType === DataType.DATETIME) {
            return (
              <Td key={column.name}>
                <LazyLoad height={30}>
                  {formatDate(row[capitalizeFirstLetter(column.name)])}
                </LazyLoad>
              </Td>
            );
          }
          if (column.dataType === DataType.ARRAY) {
            return (
              <Td key={column.name}>
                <LazyLoad height={30}>
                  <IconButton
                    onClick={() => {
                      setArray(row[capitalizeFirstLetter(column.name)]);
                      setOpenArrayPopUp(true);
                    }}
                  >
                    <OpenInBrowserIcon color="action" />
                  </IconButton>
                </LazyLoad>
              </Td>
            );
          }
          if (column.dataType === DataType.JSON) {
            return (
              <Td key={column.name}>
                <LazyLoad height={30}>
                  <IconButton
                    onClick={() => {
                      setJson(row[capitalizeFirstLetter(column.name)]);
                      setOpenModalJson(true);
                    }}
                  >
                    <OpenInBrowserIcon color="action" />
                  </IconButton>
                </LazyLoad>
              </Td>
            );
          }
          return (
            <Td key={column.name}>
              <LazyLoad height={30}>
                <ValueTableContainer>
                  {row[capitalizeFirstLetter(column.name)]}
                </ValueTableContainer>
              </LazyLoad>
            </Td>
          );
        })}
      </Tr>
    ));

  return (
    <>
      <div className="container-dynamic-table">
        <TableContainer className="dynamicTable-tableContainer">
          <Table className="dynamicTable-responsiveTable">
            <Thead className="dynamicTable-thead">
              <Tr className="dynamicTable-tr">
                {columns &&
                  columns.map((column, index) => (
                    <Th
                      key={index}
                      className="dynamicTable-th"
                      onClick={() => {
                        if (
                          column.dataType === DataType.DATETIME ||
                          column.dataType === DataType.NUMBER ||
                          column.dataType === DataType.STRING
                        ) {
                          requestSort(capitalizeFirstLetter(column.name));
                        }
                      }}
                    >
                      <HeaderContainer className="dynamicTable-headerContainer">
                        <TitleTableContainer className="dynamicTable-titleTableContainer">
                          {column.displayName}
                        </TitleTableContainer>
                        <IconContainer className="dynamicTable-iconContainer">
                          {column.dataType === DataType.DATETIME ||
                          column.dataType === DataType.NUMBER ||
                          column.dataType === DataType.STRING
                            ? getColumnSort(capitalizeFirstLetter(column.name))
                            : null}
                        </IconContainer>
                      </HeaderContainer>
                    </Th>
                  ))}
              </Tr>
            </Thead>
            <Tbody className="dynamicTable-tbody">
              {renderDynamicTable(columns, filteredItems || [])}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
      <ModalJson
        open={openModalJson}
        handleClose={handleCloseModalJson}
        json={json}
      />
      <PopUpArrays
        open={openArrayPopUp}
        handleClose={handleCloseArraysPopUp}
        array={array}
      />
      <ModalLongString
        open={openModalLongString}
        handleClose={handleCloseModalLongString}
        longString={longString}
      />
    </>
  );
};
