import React, { useState, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import Select from 'react-select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { FilterComponentsProps } from './filterComponentsProps';
import { TypesInput } from '../../../constants/commonEnums';
import { CustomInput, customStyles } from './filterComponentsStyles';
import { rangeDate } from '../../../utils/dynamicReportsUtils';
import { useGetSelectOptionsMutation } from '../../../services/dynamicReports/dynamicReportsService';

export const FilterComponents: React.FC<FilterComponentsProps> = ({
  componentName,
  typeComponent,
  componentPath,
  onValueChanged,
  inputsValues,
  queryString
}) => {
  const [getSelectOptions, { data: optionsList, isLoading }] =
    useGetSelectOptionsMutation();
  const [inputNumberValue, setInputNumberValue] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [textEntered, setTextEntered] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [autoCompleteValue, setAutoCompleteValue] = useState<any | null>(null);

  const searchValues = () => {
    if (inputValue.length > 2) {
      getSelectOptions(`${componentPath}${inputValue}`);
    }
  };
  useEffect(() => {
    searchValues();
  }, [inputValue]);

  const handleInputNumberValue = (event: any) => {
    const value = parseInt(event.target.value, 10);
    setInputNumberValue(value);
    onValueChanged(value);
  };

  const handleSelectedDate = (date: Date) => {
    setSelectedDate(date);
    onValueChanged(date.toJSON());
  };

  const handleInputText = (event: any) => {
    const text = event.target.value;
    setTextEntered(text);
    onValueChanged(text);
  };

  const handleValueChange = (value: any) => {
    setAutoCompleteValue(value);
    onValueChanged(value);
  };

  useEffect(() => {
    const findInputValue = inputsValues.find(
      (value) => value.queryStringParam === queryString
    );
    if (typeComponent === TypesInput.date) {
      handleSelectedDate(rangeDate(componentName));
      if (findInputValue && findInputValue.value) {
        setSelectedDate(findInputValue.value);
      }
    }
    if (typeComponent === TypesInput.list) {
      handleValueChange(null);
      if (findInputValue && findInputValue.value) {
        setAutoCompleteValue(findInputValue.value);
      }
    }
    if (typeComponent === TypesInput.int) {
      onValueChanged(null);
      if (findInputValue && findInputValue.value) {
        setInputNumberValue(findInputValue.value);
      }
    }
    if (typeComponent === TypesInput.string) {
      onValueChanged(null);
      if (findInputValue && findInputValue.value) {
        setTextEntered(findInputValue.value);
      }
    }
  }, []);

  const filterOptions = (
    candidate: { label: string; value: string; data: any },
    input: string
  ) => {
    if (input && optionsList) {
      optionsList.forEach(
        (option: { name: string }) => candidate.label === option.name
      );
    }
    return true;
  };

  if (typeComponent === TypesInput.list) {
    return (
      <Select
        filterOption={filterOptions}
        styles={customStyles}
        menuPortalTarget={document.body}
        isLoading={isLoading}
        isClearable
        getOptionLabel={(x) => x.name}
        inputValue={inputValue}
        value={autoCompleteValue}
        placeholder={componentName}
        onChange={handleValueChange}
        onInputChange={(newValue) => {
          setInputValue(newValue);
        }}
        options={optionsList || []}
      />
    );
  }

  if (typeComponent === TypesInput.int) {
    return (
      <CustomInput
        placeholder={componentName}
        id={componentName}
        type="number"
        size="small"
        value={inputNumberValue}
        onChange={handleInputNumberValue}
      />
    );
  }

  if (typeComponent === TypesInput.date) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(selectedDate)}
          onChange={(newValue) => {
            if (newValue) {
              handleSelectedDate(newValue.toDate());
            }
          }}
          slotProps={{
            textField: {
              size: 'small',
              id: componentName
            }
          }}
        />
      </LocalizationProvider>
    );
  }

  return (
    <CustomInput
      id="outlined-string"
      placeholder={componentName}
      size="small"
      value={textEntered}
      onChange={handleInputText}
    />
  );
};
