import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, IconButton, Grid, Button } from '@mui/material';
// eslint-disable-next-line
import { JsonToTable } from 'react-json-to-table';
import { ModalJsonProps } from './modalJsonProps';
import { ModalContainerStyled, CustomGrid } from './modalJsonStyles';
import Strings from '../../../i18n';

export const ModalJson: React.FunctionComponent<ModalJsonProps> = ({
  open,
  handleClose,
  json
}) => {
  const jsonParse = JSON.parse(json);
  if (jsonParse) {
    if (jsonParse.Success) {
      jsonParse.Success = jsonParse.Success.toString();
    }
    if (jsonParse.MetaData) {
      jsonParse.MetaData = jsonParse.MetaData.toString();
    }
  }

  const downloadFile = ({
    data,
    fileName,
    fileType
  }: {
    data: any;
    fileName: any;
    fileType: any;
  }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToJson = (e: any) => {
    e.preventDefault();
    downloadFile({
      data: JSON.stringify(jsonParse),
      fileName: 'payload.json',
      fileType: 'text/json'
    });
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainerStyled>
        <CustomGrid container justifyContent="space-between">
          <Grid item>
            <Button variant="contained" onClick={exportToJson}>
              {Strings.common.download}
            </Button>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon color="primary" />
            </IconButton>
          </Grid>
        </CustomGrid>
        <JsonToTable json={jsonParse} />
      </ModalContainerStyled>
    </Modal>
  );
};
