import { Box, Button } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const StatusProcess = styled(Box)<{ IsActive?: boolean }>`
  && {
    height: 10px;
    width: 10px;
    border-radius: 20px;
    background-color: ${({ IsActive }) =>
      IsActive ? colors.green : colors.red};
  }
`;

export const ContainerTable = styled(Box)<{ IsActive?: boolean }>`
  && {
    height: 90%;
  }
`;

export const LogoOrg = styled.img`
  && {
    height: 15px;
    width: 15px;
    margin-left: -10px;
  }
`;

export const TriangleOrg = styled.img`
  && {
    height: 10px;
    width: 10px;
    margin-bottom: 5px;
  }
`;

export const QuitCheckboxHeader = {
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
    {
      display: 'none'
    }
};

export const OwnerBox = styled.span`
  && {
    background-color: ${colors.lightGray};
    border-radius: 20px;
    padding: 5px 10px;
    font-weight: 700;
    display: inline-block;
    justify-content: left;
  }
`;

export const StyledButtonName = styled(Button)<{ badge?: number }>`
  && {
    color: ${colors.black};
    text-transform: capitalize;
    font-weight: 400;
    padding: 8px 10px !important;
    min-width: auto !important;
    :hover {
      background-color: ${colors.lightGray};
      transition: 0.3s;
    }
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    position: relative;
  }

  ${({ badge }) =>
    badge &&
    `
    &::after {
      content: '${badge}';
      position: absolute;
      top: 4px;
      right: -10px;
      background-color: #f7cc7d;
      color: black;
      border-radius: 50%;
      padding: 2px 6px;
      font-size: 10px;
      line-height: 1;
      text-align: center;
    }
  `}
`;

export const StyledLocationName = styled.div``;
