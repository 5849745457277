import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import { config } from '../../config/config';
import {
  DeviceDetails,
  IMethodsInfoByDeviceId,
  NewDevice,
  RegisteredDevice,
  TelemetryDeviceGet,
  IPostSingletonStaticMethod,
  IGlobalDeviceAnnounceItem,
  ILastDeviceAnnouncePayloadResponse,
  IDeviceAnnounceByDeviceId,
  IRecordsSyncStatus,
  ISetWifiCredentialsPayload,
  IDetailsRecordDbData,
  IFileDevice,
  IScheduleFirmwareData,
  IDealerDeviceStateCount,
  IFilesSyncByDevice,
  ISystemStateData,
  IErrorResponse
} from '../../types/Device/DeviceInterfaces';
import { Folder } from '../../types/folders/folder';
import customFetchBase from '../expiredToken/expiredToken';
import {
  AddLocationInOrg,
  Location,
  LocationAssign,
  LocationAssignToOrganization,
  LocationByUser,
  LocationInOrganization,
  LocationNew,
  LocationSelected,
  LocationUserSelected,
  TransferLocationWithEmail
} from '../../types/location/location';
import {
  AddBusinessAccountUser,
  BusinessAccount,
  BusinessAccountUser,
  EditBusinessAccount
} from '../../types/businessAccount/businessAccount';
import {
  Organization,
  TransferOrganizationWithEmail
} from '../../types/organizations/organizations';
import { UsersByLocation } from '../../types/users';
import { PieChartStatus } from '../../constants/enums/dashboardEnums';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiDevices = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getDeviceMethods: builder.mutation<IMethodsInfoByDeviceId[], string>({
      query: (deviceId) => ({
        url: `api/Device/DeviceMethodsByDeviceId?deviceId=${deviceId}`,
        method: 'GET'
      })
    }),
    addDevice: builder.mutation<{}, NewDevice>({
      query: (data) => ({
        url: 'api/RegisterDevice',
        method: 'POST',
        body: {
          deviceId: data.macAddress,
          controllerTypeId: data.productType
        }
      })
    }),
    getCredentials: builder.mutation<string[], string>({
      query: (deviceId) => ({
        url: `api/Device/GetCredentials/${deviceId}`,
        method: 'GET'
      })
    }),
    deleteDevice: builder.mutation<{}, string[]>({
      query: (deviceIds) => ({
        url: `/api/Device/RegisteredDevice?ids=${deviceIds.join(',')}`,
        method: 'DELETE'
      })
    }),
    getDevices: builder.mutation<RegisteredDevice[], {}>({
      query: () => ({
        url: '/api/Device/RegisteredDevices',
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseHandler: async (response) => {
          const data = await response.json();
          return data.map((device: RegisteredDevice) => ({
            ...device,
            statusLabel:
              device.connectionStatus === PieChartStatus.onlineMoreThan15 ||
              device.connectionStatus === PieChartStatus.onlineLessThan15
                ? `On since ${
                    device.lastUpdate !== '1/1/0001 12:00:00 AM'
                      ? moment
                          .utc(device.lastUpdate)
                          .local()
                          .format('YYMMDD HH:mm')
                      : '-'
                  }`
                : `Off since ${
                    device.lastUpdate !== '1/1/0001 12:00:00 AM'
                      ? moment
                          .utc(device.lastUpdate)
                          .local()
                          .format('YYMMDD HH:mm')
                      : '-'
                  }`
          }));
        }
      })
    }),
    getDebugDevice: builder.mutation<[], string>({
      query: (deviceId) => ({
        url: `api/Device/Debug/${deviceId}`,
        method: 'GET'
      })
    }),
    postStaticMethod: builder.mutation<any, {}>({
      query: (data) => ({
        url: 'api/Device/DeviceStaticMethod',
        method: 'POST',
        body: data
      })
    }),
    postSingletonMethod: builder.mutation<any, IPostSingletonStaticMethod[]>({
      query: (data) => ({
        url: 'api/Device/DeviceSingletonMethod',
        method: 'POST',
        body: data
      })
    }),

    updateMethodsOrder: builder.mutation<
      any,
      { data: IPostSingletonStaticMethod[]; type: string }
    >({
      query: (data) => ({
        url: `api/Device/Device${data.type}Method`,
        method: 'PUT',
        body: data.data
      })
    }),

    deleteStaticMethod: builder.mutation<any, number>({
      query: (deviceId) => ({
        url: `api/Device/DeviceStaticMethod/${deviceId}`,
        method: 'DELETE'
      })
    }),
    deleteSingletonMethod: builder.mutation<any, number>({
      query: (deviceId) => ({
        url: `api/Device/DeviceSingletonMethod/${deviceId}`,
        method: 'DELETE'
      })
    }),
    getDeviceAnnounce: builder.mutation<IDeviceAnnounceByDeviceId[], string>({
      query: (deviceId) => ({
        url: `api/Device/DeviceAnnounce/${deviceId}`,
        method: 'GET'
      })
    }),
    getLocationsByLoggedUser: builder.mutation<LocationByUser[], {}>({
      query: () => ({
        url: `api/Location/GetLoggedUserLocations`,
        method: 'GET'
      })
    }),
    getLocations: builder.mutation<Location[], {}>({
      query: () => ({
        url: 'api/Location/AllLocations',
        method: 'GET'
      })
    }),
    getFoldersTree: builder.mutation<Folder[], {}>({
      query: () => ({
        url: 'api/Folder/UserFolderTrees',
        method: 'GET'
      })
    }),
    getFoldersUserTree: builder.mutation<Folder[], string>({
      query: (userId) => ({
        url: `api/Folder/${userId}/UserFolderTrees`,
        method: 'GET'
      })
    }),
    postFolders: builder.mutation<
      [],
      {
        Name: string;
        ParentId: number | null;
      }
    >({
      query: (data) => ({
        url: `api/Folder`,
        method: 'POST',
        body: data
      })
    }),
    postLocation: builder.mutation<[], LocationNew>({
      query: (data) => ({
        url: `api/Location`,
        method: 'POST',
        body: data
      })
    }),
    putLocation: builder.mutation<[], LocationNew>({
      query: (data) => ({
        url: `api/Location`,
        method: 'PUT',
        body: data
      })
    }),
    assignLocationToFolder: builder.mutation<[], LocationAssign>({
      query: (data) => ({
        url: `/api/Folder/FolderLocation`,
        method: 'POST',
        body: data
      })
    }),
    getOrganizations: builder.mutation<Organization[], string>({
      query: () => ({
        url: `/api/Organization/OrganizationsSummary`,
        method: 'GET'
      })
    }),
    postOrganization: builder.mutation<
      number,
      {
        Name: string;
        ParentId: number | null;
        OwnerId: number | null;
      }
    >({
      query: (data) => ({
        url: `api/Organization`,
        method: 'POST',
        body: data
      })
    }),
    deleteOrganization: builder.mutation<any, number[]>({
      query: (organizationIds) => ({
        url: `/api/Organization?organizationIds=${organizationIds.join(',')}`,
        method: 'DELETE'
      })
    }),
    assignLocationToOrganization: builder.mutation<
      any,
      LocationAssignToOrganization
    >({
      query: (data) => ({
        url: `/api/Organization/OrganizationLocation`,
        method: 'POST',
        body: data
      })
    }),
    deleteLocationInOrganization: builder.mutation<any, number>({
      query: (organizationId) => ({
        url: `/api/Organization/OrganizationLocation?organizationLocationId=${organizationId}`,
        method: 'DELETE'
      })
    }),
    getOrganizationLocations: builder.mutation<
      LocationInOrganization[],
      string
    >({
      query: (organizationId) => ({
        url: `/api/Organization/OrganizationLocations/${organizationId}`,
        method: 'GET'
      })
    }),
    transferOrg: builder.mutation<{}, TransferOrganizationWithEmail>({
      query: (data) => ({
        url: `/api/Organization/TransferOrganizationUsingEmail`,
        method: 'POST',
        body: data
      })
    }),
    transferLoc: builder.mutation<{}, TransferLocationWithEmail>({
      query: (data) => ({
        url: `/api/Location/TransferLocationUsingEmail`,
        method: 'POST',
        body: data
      })
    }),
    ShareOrg: builder.mutation<
      {},
      {
        UserId: number;
        OrganizationRoleId: number;
        OrganizationId: number;
      }
    >({
      query: (data) => ({
        url: `api/Organization/ShareOrganization`,
        method: 'POST',
        body: data
      })
    }),
    ShareLoc: builder.mutation<
      {},
      {
        userId: number;
        locationRoleId: number;
        locationId: number;
      }
    >({
      query: (data) => ({
        url: `api/Location/ShareLocation`,
        method: 'POST',
        body: data
      })
    }),
    getRolesLocation: builder.mutation<{ id: number; name: string }[], string>({
      query: () => ({
        url: `api/LocationPermission/Roles`,
        method: 'GET'
      })
    }),
    getRolesOrganization: builder.mutation<
      { id: number; name: string }[],
      string
    >({
      query: () => ({
        url: `api/OrganizationPermission/Roles`,
        method: 'GET'
      })
    }),
    getLocationsInOrganization: builder.mutation<
      { locations: LocationInOrganization[]; organizations: [] },
      string
    >({
      query: (organizationId) => ({
        url: `/api/Organization/LocationsAndOrganizations/${organizationId}`,
        method: 'GET'
      })
    }),
    getAllBusinessAccount: builder.mutation<BusinessAccount[], string>({
      query: () => ({
        url: `/api/BusinessAccount/AllBusinessAccounts`,
        method: 'GET'
      })
    }),
    getBusinessAccountByUser: builder.mutation<BusinessAccount[], string>({
      query: (idOwner) => ({
        url: `/api/BusinessAccount/${idOwner}`,
        method: 'GET'
      })
    }),
    getBusinessAccountUsers: builder.mutation<BusinessAccountUser[], string>({
      query: (idBusinessAccount) => ({
        url: `/api/BusinessAccountUser/${idBusinessAccount}`,
        method: 'GET'
      })
    }),
    addBusinessAccountUser: builder.mutation<{}, AddBusinessAccountUser>({
      query: (data) => ({
        url: `api/BusinessAccountUser`,
        method: 'POST',
        body: data
      })
    }),
    editBusinessAccountUser: builder.mutation<{}, AddBusinessAccountUser>({
      query: (data) => ({
        url: `api/BusinessAccountUser`,
        method: 'PUT',
        body: data
      })
    }),
    deleteBusinessAccountUser: builder.mutation<{}, string>({
      query: (userId) => ({
        url: `api/BusinessAccountUser/${userId}`,
        method: 'DELETE'
      })
    }),
    editBusinessAccount: builder.mutation<{}, EditBusinessAccount>({
      query: (data) => ({
        url: `/api/BusinessAccount`,
        method: 'PUT',
        body: data
      })
    }),
    deleteBusinessAccount: builder.mutation<{}, string>({
      query: (businessId) => ({
        url: `api/BusinessAccount/${businessId}`,
        method: 'DELETE'
      })
    }),
    userSelectedLocation: builder.mutation<{}, LocationSelected>({
      query: (data) => ({
        url: `/api/Location/UserSelectedLocation`,
        method: 'POST',
        body: data
      })
    }),
    getUserSelectedLocation: builder.mutation<LocationUserSelected[], string>({
      query: (UserId) => ({
        url: `/api/Location/UserSelectedLocation/${UserId}`,
        method: 'GET'
      })
    }),
    getAllProductNames: builder.mutation<
      { id: number; name: string }[],
      string
    >({
      query: () => ({
        url: `/api/Product/AllProductNames`,
        method: 'GET'
      })
    }),
    shareOrganizationUsingEmail: builder.mutation<
      {},
      {
        email: string;
        organizationIds: number[];
        organizationRoleId: number;
      }
    >({
      query: (data) => ({
        url: `/api/Organization/ShareOrganizationUsingEmail`,
        method: 'POST',
        body: data
      })
    }),
    shareLocationWithBusinessAccount: builder.mutation<
      {},
      {
        businessAccountTag: string;
        locationRoleId: number;
        locationIds: number[];
      }
    >({
      query: (data) => ({
        url: `/api/Location/ShareLocationWithBusinessAccountUsingTag`,
        method: 'POST',
        body: data
      })
    }),
    shareOrganizationWithBusinessAccount: builder.mutation<
      {},
      {
        businessAccountTag: string;
        organizationRoleId: number;
        organizationIds: number[];
      }
    >({
      query: (data) => ({
        url: `/api/Organization/ShareOrganizationWithBusinessAccountUsingTag`,
        method: 'POST',
        body: data
      })
    }),
    addLocationInOrg: builder.mutation<{}, AddLocationInOrg>({
      query: (data) => ({
        url: `/api/Organization/OrganizationLocation`,
        method: 'POST',
        body: data
      })
    }),
    deleteUserFromLocation: builder.mutation<{}, {}>({
      query: (userLocationId: number) => ({
        url: `api/Location/LocationUser/${userLocationId}`,
        method: 'DELETE'
      })
    }),
    getLocationsUsersByLocationId: builder.mutation<UsersByLocation[], {}>({
      query: (locationId: number) => ({
        url: `api/Location/GetLocationUsersByLocationId/${locationId}`,
        method: 'GET'
      })
    }),
    getRecordsByDb: builder.mutation<IDetailsRecordDbData, TelemetryDeviceGet>({
      query: (data) => ({
        url: `api/Device/Records?deviceId=${data.deviceId}&dbName=${data.dbName}&Page=${data.page}&Size=${data.pageSize}`,
        method: 'GET'
      })
    }),
    getRecordsSyncStatus: builder.mutation<IRecordsSyncStatus[], string>({
      query: (deviceId) => ({
        url: `api/Device/Records/SyncStatus/${deviceId}`,
        method: 'GET'
      })
    }),
    getRecordsDbNames: builder.mutation<string[], string>({
      query: (deviceId) => ({
        url: `api/Device/Records/${deviceId}/DbNames`,
        method: 'GET'
      })
    }),
    putRegenerateCredentials: builder.mutation<{}, string>({
      query: (deviceId) => ({
        url: `api/Device/RegenerateCredentials`,
        body: {
          deviceId
        },
        method: 'PUT'
      })
    }),
    getDeviceInfo: builder.mutation<DeviceDetails, string>({
      query: (deviceId) => ({
        url: `api/Device/Details/${deviceId}`,
        method: 'GET'
      })
    }),
    deleteMultipleDeviceMethods: builder.mutation<
      {},
      { singletonIds: number[]; staticIds: number[] }
    >({
      query: (data) => ({
        url: `api/Device/DeviceMethod?singletonIds=${data.singletonIds.join(
          ','
        )}&staticIds=${data.staticIds.join(',')}`,
        method: 'DELETE'
      })
    }),
    postCustomFileDevice: builder.mutation<
      {},
      { deviceId: string; data: FormData }
    >({
      query: (data) => ({
        url: `api/TestMessage/CustomFIle/${data.deviceId}`,
        method: 'POST',
        body: data.data
      })
    }),
    deleteDbFromDevice: builder.mutation<
      {},
      { deviceId: string; dbName: string }
    >({
      query: (data) => ({
        url: `api/Device/Records?DeviceId=${data.deviceId}&DBName=${data.dbName}`,
        method: 'DELETE'
      })
    }),
    getGlobalDeviceAnnounce: builder.mutation<
      IGlobalDeviceAnnounceItem[],
      void
    >({
      query: () => ({
        url: `api/Device/GlobalDeviceAnnounceReport`,
        method: 'GET'
      })
    }),
    getLastDeviceAnnounceByDeviceId: builder.mutation<
      ILastDeviceAnnouncePayloadResponse,
      string
    >({
      query: (deviceId) => ({
        url: `api/Device/LastDeviceAnnounce/${deviceId}`,
        method: 'GET'
      })
    }),
    getFilesSyncByDeviceId: builder.mutation<any, string>({
      query: (deviceId) => ({
        url: `/api/FilesSync/DesiredProperties/${deviceId}`,
        method: 'GET'
      })
    }),
    toggleSyncStatusRecordDb: builder.mutation<
      {},
      { deviceId: string; dbName: string; toggleText: string }
    >({
      query: (data) => ({
        url: `api/Device/Records/SyncStatus/${data.toggleText}`,
        method: 'POST',
        body: {
          deviceId: data.deviceId,
          dbName: data.dbName
        }
      })
    }),
    getFileSyncDataRetriever: builder.mutation<
      { columns: { name: string; dataType: string }[]; rows: [] }[],
      string
    >({
      query: (url) => ({
        url: `/api/FilesSync/DataRetriever`,
        method: 'POST',
        body: { url }
      })
    }),
    deviceRebootCommand: builder.mutation<{}, string[]>({
      query: (deviceIds) => ({
        url: `api/Commands/Reboot`,
        method: 'POST',
        body: { deviceIds }
      })
    }),
    deviceSetWifiCredentials: builder.mutation<{}, ISetWifiCredentialsPayload>({
      query: (data) => ({
        url: `api/Commands/SetWifiCredentials`,
        method: 'POST',
        body: data
      })
    }),
    getListOfFilesCommandByDeviceId: builder.mutation<IFileDevice[], string>({
      query: (deviceId) => ({
        url: `api/Commands/FileList/${deviceId}`,
        method: 'GET'
      })
    }),
    sendFileThroughEmail: builder.mutation<
      {},
      { deviceId: string; fileNames: string[] }
    >({
      query: (data) => ({
        url: `api/Commands/SendFilesThroughEmail`,
        method: 'POST',
        body: data
      })
    }),
    scheduleFirmwareUpdate: builder.mutation<
      {},
      { versionId: number; deviceIds: string[]; upgradeTypeId: number }
    >({
      query: (data) => ({
        url: `api/Firmware/Schedule`,
        method: 'POST',
        body: data
      })
    }),

    getFirmwareVersions: builder.mutation<
      { data: { id: number; name: string }[] },
      number[]
    >({
      query: (productTypeIds) => ({
        url: `api/Firmware/Version?supportedModels=${productTypeIds.join(',')}`,
        method: 'GET'
      })
    }),
    getSchedulesFirmwareUpdate: builder.mutation<
      { data: IScheduleFirmwareData[] },
      void
    >({
      query: () => ({
        url: `api/Firmware/Schedule`,
        method: 'GET'
      })
    }),
    getDealerDeviceStateCount: builder.mutation<
      IDealerDeviceStateCount[],
      void
    >({
      query: () => ({
        url: `api/Device/DealerDevicesStateCount`,
        method: 'GET'
      })
    }),
    deleteDeviceCredentials: builder.mutation<{}, string>({
      query: (deviceId) => ({
        url: `api/Commands/DeleteCredentials`,
        method: 'POST',
        body: {
          deviceId
        }
      })
    }),
    fileSyncDesiredProperties: builder.mutation<IFilesSyncByDevice[], string>({
      query: (deviceId) => ({
        url: `/api/FilesSync/DesiredProperties/${deviceId}`,
        method: 'GET'
      })
    }),
    sendDirectMethod: builder.mutation<
      {},
      { deviceIds: string[]; plainStringCommand: string }
    >({
      query: (data) => ({
        url: `api/Commands/PlainJsonString`,
        method: 'POST',
        body: data
      })
    }),
    refreshStatusDevices: builder.mutation<{}, number>({
      query: (locationId) => ({
        url: `api/Device/ConnectionState/${locationId}`,
        method: 'PUT'
      })
    }),
    getSystemState: builder.mutation<ISystemStateData, string>({
      query: (deviceId) => ({
        url: `api/Commands/SystemState/${deviceId}`,
        method: 'GET'
      }),
      transformErrorResponse: (baseQueryReturnValue: FetchBaseQueryError) => {
        return {
          success: false,
          returnCode: baseQueryReturnValue.status,
          message: (baseQueryReturnValue.data as { message?: string })?.message
        };
      }
    }),
    getTwinInfo: builder.mutation<{}, string>({
      query: (deviceId) => ({
        url: `api/Device/TwinInfo/${deviceId}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useAddDeviceMutation,
  useGetDevicesMutation,
  useDeleteDeviceMutation,
  useGetDeviceMethodsMutation,
  useGetDebugDeviceMutation,
  usePostSingletonMethodMutation,
  usePostStaticMethodMutation,
  useDeleteSingletonMethodMutation,
  useDeleteStaticMethodMutation,
  useGetDeviceAnnounceMutation,
  useGetCredentialsMutation,
  useGetLocationsByLoggedUserMutation,
  useGetLocationsMutation,
  useGetFoldersTreeMutation,
  useGetFoldersUserTreeMutation,
  usePostFoldersMutation,
  usePostLocationMutation,
  useAssignLocationToFolderMutation,
  useGetOrganizationsMutation,
  usePostOrganizationMutation,
  useDeleteOrganizationMutation,
  useAssignLocationToOrganizationMutation,
  useDeleteLocationInOrganizationMutation,
  useGetOrganizationLocationsMutation,
  useTransferOrgMutation,
  useTransferLocMutation,
  useShareOrgMutation,
  useShareLocMutation,
  useGetRolesLocationMutation,
  useGetRolesOrganizationMutation,
  useGetLocationsInOrganizationMutation,
  useGetAllBusinessAccountMutation,
  useGetBusinessAccountByUserMutation,
  useGetBusinessAccountUsersMutation,
  useAddBusinessAccountUserMutation,
  useEditBusinessAccountUserMutation,
  useDeleteBusinessAccountUserMutation,
  useDeleteBusinessAccountMutation,
  useUserSelectedLocationMutation,
  useGetUserSelectedLocationMutation,
  useGetAllProductNamesMutation,
  useShareOrganizationUsingEmailMutation,
  useShareLocationWithBusinessAccountMutation,
  useShareOrganizationWithBusinessAccountMutation,
  useEditBusinessAccountMutation,
  useAddLocationInOrgMutation,
  useDeleteUserFromLocationMutation,
  useGetLocationsUsersByLocationIdMutation,
  useGetRecordsByDbMutation,
  usePutLocationMutation,
  useGetRecordsDbNamesMutation,
  usePutRegenerateCredentialsMutation,
  useGetDeviceInfoMutation,
  useDeleteMultipleDeviceMethodsMutation,
  usePostCustomFileDeviceMutation,
  useDeleteDbFromDeviceMutation,
  useGetGlobalDeviceAnnounceMutation,
  useGetLastDeviceAnnounceByDeviceIdMutation,
  useUpdateMethodsOrderMutation,
  useGetRecordsSyncStatusMutation,
  useToggleSyncStatusRecordDbMutation,
  useGetFilesSyncByDeviceIdMutation,
  useGetFileSyncDataRetrieverMutation,
  useDeviceRebootCommandMutation,
  useDeviceSetWifiCredentialsMutation,
  useGetListOfFilesCommandByDeviceIdMutation,
  useSendFileThroughEmailMutation,
  useScheduleFirmwareUpdateMutation,
  useGetFirmwareVersionsMutation,
  useGetSchedulesFirmwareUpdateMutation,
  useGetDealerDeviceStateCountMutation,
  useDeleteDeviceCredentialsMutation,
  useFileSyncDesiredPropertiesMutation,
  useSendDirectMethodMutation,
  useRefreshStatusDevicesMutation,
  useGetSystemStateMutation,
  useGetTwinInfoMutation
} = apiDevices;
