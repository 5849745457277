export default {
  navBar: {
    selectLocation: 'Select Location',
    manageBusinessAccount: ' Manage Business Account',
    portal: 'Haven Lighting',
    profileIcon: {
      manageYourAccount: 'Manage Your Account',
      logOut: 'Logout'
    },
    allMyLocations: 'All my locations',
    userLocationsTooltip: 'User locations',
    globalSearchTooltip: 'Global Search',
    serverAccessTooltip: 'Server Access',
    inboxTooltip: 'Inbox',
    profileTooltip: 'Profile',
    viewMyNotes: 'View My Notes'
  },
  common: {
    close: 'Close',
    download: 'Download',
    noData: 'No data',
    unassigned: 'Unassigned',
    edit: 'Edit',
    videoNotSupported: 'Your browser does not support the video.',
    name: 'Name',
    enabled: 'Enabled',
    lastUpdated: 'Last Updated',
    ssid: 'SSID',
    results: 'Results',
    noResultsFound: 'No results found',
    copiedText: 'Copied text!',
    errorCopyText: 'Error copying text!',
    copyText: 'Copy Text',
    previous: 'Previous',
    next: 'Next',
    select: 'Select',
    no: 'No',
    yes: 'Yes',
    ok: 'Ok',
    up: 'Up',
    view: 'View',
    down: 'Down',
    update: 'Update',
    cancel: 'Cancel',
    save: 'Save',
    add: 'Add',
    loading: 'Loading...',
    delete: 'Delete',
    send: 'Send',
    viewMore: 'View More',
    details: 'Details',
    and: ' and ',
    thisActionIsIrreversible: `This actions is irreversible`,
    somethingWentWrong: 'Something went wrong',
    assignLater: 'Assign later',
    missingInformation: 'Missing information',
    noSystemPermissions: 'No system permissions',
    typeToSearch: 'Search',
    globalSearch: 'Global Search',
    readMore: 'Read more',
    readLess: 'Read less',
    brightness: 'Brightness',
    fieldNoSpaces:
      'The field must contain at least one letter and cannot be just spaces.',
    noFileSelected: 'Click to upload file',
    payload: 'Payload',
    response: 'Response',
    notFound: 'Not found',
    reOrder: 'Reorder',
    openDetails: 'Open Details',
    enterAnAddress: 'Enter an address',
    enterAZipCode: 'Enter a zip code',
    enterAState: 'Enter a state',
    enterAWebsiteAddress: 'Enter a website address',
    latitude: 'Latitude',
    longitude: 'Longitude',
    enterALatitude: 'Enter a latitude',
    enterALongitude: 'Enter a longitude',
    accountingEmail: 'Accounting Email',
    enterAnAccountingEmail: 'Enter an accounting email',
    domainName: 'Domain Name',
    enterADomainName: 'Enter a domain name',
    specialInstructions: 'Special Instructions',
    enterSpecialInstructions: 'Enter special instructions',
    ownerEmail: 'Owner Email',
    primaryContactUserEmail: 'Primary Contact User Email',
    remove: 'Remove',
    reloadPage: 'Reload Page',
    removeFilters: 'Remove Filters',
    filters: 'Filters',
    severity: 'Severity',
    phoneNumber: 'Phone Number',
    enterAPhoneNumber: 'Enter a phone number',
    enterAName: 'Enter a name',
    active: 'ACTIVE',
    pending: 'PENDING'
  },
  globalSearch: {
    goToLocation: 'Go to location',
    goToUserDetails: 'Go to user details',
    goToOrganization: 'Go to organization',
    goToDevice: 'Go to device',
    goToDeviceLocation: 'Go to location devices',
    openInExternalTab: 'Open in external tab',
    assignedLocation: 'Assigned location',
    sendEmail: 'Send message',
    goToDefaultLocation: 'Go to default location'
  },
  formCommons: {
    fieldRequired: 'This field is required',
    invalidText: 'Invalid text'
  },
  login: {
    alreadyHaveAccount: 'Already have account?',
    noGetEmail: "Didn't get a email?",
    resendEmail: 'Resend email',
    agreeTermsLabel: 'By creating this account, you agree to ',
    successPostData: 'The account was created successfully.',
    redirectToLoginPage: 'You will be redirected to login page in',
    termsOfServiceLabel: 'Terms of Service',
    privacyPolicyLabel: 'Privacy Policy',
    callBack: '/callback',
    userRegistration: '/user-registration',
    createUser: '/create-user',
    loginWithPassword: '/login-with-password',
    resetPassword: '/reset-password',
    loginWith: 'Login with',
    passwordless: 'Passwordless',
    forgotPasswordAsk: 'Forgot your password?',
    nickname: 'Nickname',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    forgotPass: 'Forgot Password?',
    signIn: 'Sign in',
    signUp: 'Sign up',
    createAccount: 'Create Account',
    haveAnAccount: 'Already have an account?',
    logIn: 'Log in',
    changePasswordTitle: 'Change Password',
    changePasswordButton: 'Send me an email',
    noAccount: "Don't have an account?",
    createOne: 'Create one.',
    userNameIsRequired: 'Username is required',
    userNameMustBeValid: 'Username must be valid',
    passwordMustMatch: 'Passwords must match',
    emailIsRequired: 'Email is required',
    emailMustBeValid: 'Email must be a valid email format',
    passwordIsRequired: 'Password is required',
    passwordMinCharacters: 'Password must be at least 4 characters',
    passwordStrong: 'Must contain at least 4 characters',
    maxAllowedCharacters: 'This field must not be longer than 50 characters',
    emailValidation:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    forgotPassword: 'Forgot Password',
    forgotPasswordText:
      'Enter your email address associated with your account, and we will send you an email with the steps to follow to reset your password.',
    resetPasswordTitle: 'Reset Password',
    forgotSuccess:
      'An email has been sent to reset your password. Now you can close this tab.',
    resetPasswordSuccess: 'Password reset successfully'
  },
  serverAccess: {
    haven: 'MedicalCart',
    pageTitle: 'Server Access',
    menu: {
      serviceHealth: 'Services:Health',
      organizations: 'Organizations',
      versions: 'Firmware Versions',
      productsModels: 'Products/Models',
      users: 'Users',
      locations: 'Locations',
      devices: 'Devices',
      deviceAnnounce: 'Device Announce',
      globalDeviceAnnounce: 'Global Device Announce',
      firmwareSchedule: 'Firmware Schedule',
      reports: 'Reports'
    },
    folder: { successAddFolder: 'Successfully added Folder' },
    scheduleFirmwareUpdate: {
      deleteCompletedFirmwareUpdate: 'Delete Completed Firmware Update',
      scheduleFirmwareUpdate: 'Schedule Firmware Update',
      firmwareOTA: 'Firmware OTA',
      deleteFirmwareTitle: 'Do you want to delete this firmware schedule?',
      successDeleteFirmware: 'Firmware schedule deleted successfully',
      deleteFirmware: 'Delete firmware schedule',
      deleteSuccess: 'Firmware schedule deleted successfully'
    },
    devices: {
      systemState: 'System State',
      twinInfo: 'Twin Info',
      successRefreshStatus: 'Status refreshed successfully',
      moveLocation: 'Move Device to Location',
      uploadDb: 'Upload .db file',
      query: 'Query',
      viewDb: 'View .db file',
      clickToUpload: 'Click to upload and view db file',
      deleteCredentials: 'Delete Credentials',
      successDeleteCredentials: 'Credentials deleted successfully',
      fileListLabel: 'File List',
      setWifiCredentialsLabel: 'Set Wifi Credentials',
      sendDirectMethodLabel: 'Send Direct Method',
      rebootDevicesLabel: 'Reboot Devices',
      editMultipleDevices: 'Multiple Devices',
      addDeviceToLocationLabel: 'Add Device to Location',
      successPostCustomFile: 'Custom file sent successfully',
      deletedMethod: 'Method Deleted!',
      debugDeviceLabel: 'Debug',
      getDebugDeviceLabel: 'Get debug',
      successDebug: 'Debug Sent Successfully',
      successRebootDevice: 'Device(s) rebooted successfully',
      successRegenerateCredentials: 'Credentials Regenerated Successfully',
      addDevice: 'Add Device',
      editDevice: 'Edit Device',
      macAddress: 'Mac Address',
      successAddDevice: 'Successfully added device',
      successSendDeviceAnnounce: 'Sent device announce successfully',
      successDeletedDevice: 'Successfully deleted device',
      successDeleteMethod: 'Method Deleted Successfully',
      methodsInformation: 'Methods Information',
      methodsButtonLabel: 'Methods',
      scheduleMethods: 'Schedule Methods',
      records: 'Records',
      singletonMethodLabel: 'Singleton Method',
      staticMethodLabel: 'Static Method',
      staticLabel: 'Static',
      singletonLabel: 'Singleton',
      addMethodLabel: 'Add Method',
      getCredentials: 'Get Credentials',
      getDebugDevice: 'Get Debug Info',
      getMethodsTitle: 'Get Methods',
      renewCredentials: 'Renew',
      credentialsLabel: 'Credentials',
      getDeviceAnnounce: 'Device Announce',
      currentDevice: 'Current Device:',
      file: 'File',
      methodAcknowledgementLabel: 'Acknowledge',
      deleteDeviceTitle: 'Do you want to delete this device?',
      deleteDeviceFromLocationTitle:
        'Do you want to delete this device from location?',
      deleteMethodTitle: 'Do you want to delete this method?',
      deleteDeviceText: `This action is irreversible. Please note this device will have to be added as an Authorized device again`,
      deleteDeviceFromLocation:
        'This action would remove the device from the location',
      methodNameLabel: 'Method name',
      methodValueLabel: 'Method value',
      sendDebugLabel: 'Send debug',
      sendDeviceAnnounceLabel: 'Send Device Announce',
      dataLabel: 'Data',
      hardwareVersionLabel: 'Hardware Version',
      uniqueProductNameLabel: 'Unique Product Name',
      firmwareVersionLabel: 'Firmware Version',
      ackRequired: 'Acknowledge required',
      customFiles: 'Custom Files',
      toggleSyncLabel: 'Toggle Sync Status',
      directFOTA: 'Direct FOTA',
      refreshStatus: 'Refresh Status',
      version: 'Version',
      compatibleVersions: 'Compatible Versions',
      upgradeBehavior: 'Upgrade Behavior',
      affectedControllers:
        'The following controllers are about to be upgraded:',
      successSendDirectFOTA: 'Direct FOTA sent successfully',
      FileSyncByDevice: 'Files Sync',
      incrementAllTablesVersion: 'Increment All Tables Version',
      successIncrementAllTablesVersion:
        'All tables version incremented successfully'
    }
  },
  locations: {
    shareWithMe: 'Share with me',
    changeLatLongLocation: 'Change Lat Long Location',
    shareLocationSuccess: 'Successfully shared locations',
    shareLocationHint:
      'For example: example@example.com for email, #example for business account or @example.com.mx for domain',
    shareLocationsWithTargets: 'Share Locations With Targets',
    targets: 'Targets',
    locations: 'Locations',
    areYouSureToShare: 'Are you sure to share?',
    areYouSureToShareWithDomain: 'Are you sure to share with domain?',
    areYouSureToShareWithBusinessAccount:
      'Are you sure to share with business account?',
    confirmShareLoc:
      'This will share your location/organization with all the users belonging to that business account. ',
    confirmShareBusinessAccounts:
      'This will share your location with all the users belonging to that business account. ',
    confirmShareDomain:
      'This will share your location with all the users belonging to that domain. ',
    searchByNameOwnerAddress: 'Search by Name, Owner, Address',
    selectExistingLocation: 'Select Existing Location',
    moveToOrg: 'Move to Organization',
    systemRole: 'System Role',
    successTransferLocation: 'Successfully Transferer Location',
    email: 'Account Email',
    owner: 'Owner',
    apiKey: 'API Key',
    locationName: 'Location Name',
    enableBitfield: 'Enable Bitfield',
    shareLocationPlaceholder: 'example@example.com or #example...',
    shareLocation: 'Share Location',
    shareLocationLabel: 'Enter an email address or business account.',
    transferOwnership: 'Transfer Ownership',
    allLocations: 'All Locations',
    assignLocation: 'Assign Location',
    successAssignedLocation: 'Successfully Assigned Location',
    successAddedLocations: 'Successfully Added Location',
    successDeleteLocations: 'Successfully Deleted Location',
    assignLocationTitle: 'Add Location to Folder',
    assignOrganizationTitle: 'Add Location to Organization',
    locationsToAssign: 'Locations To Assign',
    locationsAssigned: 'Assigned Locations',
    addLocation: 'Add Location/Organization',
    successAddLocation: 'Successfully added Location',
    successEditLocation: 'Successfully Edit Location',
    nameLocation: 'Location Name',
    addLocationAsOrg: 'Add Location as a organization',
    orgText:
      'An Organization is used to manage and control one ore more locations.',
    title: 'Locations',
    online: 'Online',
    upToDate: 'Up to Date',
    addFolder: 'Add Folder',
    folder: 'Name Folder',
    shared: 'Shared',
    locationId: 'Location Id',
    locationAlias: 'Location Alias',
    successShareLoc: 'Successfully Share Location',
    addUserToLocation: {
      addUserTitle: 'Add user to location',
      deleteUser: 'Do you want to delete this user from this location?'
    },
    deleteLocation: 'Delete location',
    deleteLocationTitle: 'Do you want to delete this location permanently?',
    successDeleteLocation: 'Successfully deleted the location',
    goToLocation: 'Go to location',
    noLocationAssigned: 'No location assigned'
  },
  organizations: {
    organization: 'Organization',
    successShareOrg: 'Successfully Share Organization',
    successTransferOrg: 'Successfully Transferer Organization',
    organizationName: 'Organization Name',
    enterNameOrganization: 'Enter name of organization',
    shareOrg: 'Share Organization',
    successDeletedOrganization: 'Successfully Deleted Organization',
    successAddOrganization: 'Successfully added Organization',
    organizations: 'Organization',
    removeAll: 'Remove All',
    Change: 'Change',
    addOrganization: 'Add Organization',
    nameOrganization: 'Name Organization',
    deleteOrganization: 'Delete organization',
    deleteOrganizationTitle:
      'Do you want to delete this organization permanently?',
    successDeleteOrganization: 'Successfully deleted the organization',
    viewLocationsAssigned: 'View assigned locations'
  },
  scenes: {
    scenesTitle: 'Scenes',
    addSceneTitle: 'Add Scene',
    howToAddScene: 'How to add a scene?',
    blind: 'Blind',
    live: 'Live',
    blindDescription: 'Don’t send commands to devices',
    liveDescription: 'Send commands to devices',
    sceneNameLabel: '* Scene Name',
    sceneNamePlaceholder: 'Enter scene name',
    typeSceneLabel: '* Type of Scene',
    sceneCreatedSuccess: 'Scene created successfully',
    deleteSceneTitle: 'Do you want to delete this scene?',
    executeScene: 'Execute Scene',
    successDeleteScene: 'Scene deleted successfully',
    successExecuteScene: 'Scene executed successfully',
    deleteScene: 'Delete scene'
  },
  events: {
    eventsTitle: 'Events',
    eventType: 'Event Type',
    lightsZonesOff: 'Lights / Zones - OFF',
    lightsZonesOn: 'Lights / Zones - On',
    scenes: 'Scenes',
    addEventTitle: 'Add Event',
    eventNameLabel: '* Event Name',
    lightsZoneSelector: 'Lights / Zones',
    groupSelector: 'Group',
    when: 'When',
    time: 'Time',
    setEventTime: 'Set Event Time',
    setEventDate: 'Set Event Date',
    pleaseProvideEventTime: 'Please provide an Event Time',
    pleaseProvideEventDate: 'Please provide an Event Date',
    scheduleTime: 'Schedule Time',
    selectDays: 'Select days',
    eventAddedSuccessfully: 'Event added successfully',
    deleteEventTitle: 'Do you want to delete this event?',
    successDeleteEvent: 'Event deleted successfully',
    deleteEvent: 'Delete event',
    disableLightZones: 'Selecting a group will disable the lights and zones',
    disableGroup: 'Selecting lights/zones will disable the group',
    eventExecutedSuccessfully: 'Event executed successfully',
    executeThisEvent: 'Execute this event',
    eventStatusChangedSuccessfully: 'Event status changed successfully',
    enableEvent: 'Enable Event',
    disableEvent: 'Disable Event'
  },
  userProfile: {
    deleteAccount: 'Delete my account',
    deleteAccountConfirmation: 'Are you sure you want to delete your account?',
    deleteAccountTitle: 'Delete my account',
    editUser: 'Edit User',
    manageProfile: 'Manage Profile',
    successEdited: 'User edited successfully',
    successDelete: 'User deleted successfully',
    title: 'User Information',
    fillAllTheInformation:
      'Please fill out all the information to finish creating your account.',
    editAccount: 'Edit your Account',
    emailLabel: 'Email Address',
    updateInfoBtn: 'Update info',
    deleteUserLabel: 'Delete user',
    remindMeLaterBtn: 'Remind me later',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    fullNameLabel: 'Full name',
    nicknameLabel: 'Nickname',
    passwordLabel: 'Password',
    statusLabel: 'Status',
    mobileNumberLabel: 'Mobile number',
    mobileNumberLabelDisclaim: '(for account security verification)',
    mobileNumberError: 'Enter a valid phone number',
    successCreateProfile: 'The account was created successfully',
    editAccountButton: 'Edit Account',
    createAccount: 'Create Account',
    checkEmailForValidation: 'Check your email for validation link',
    successToggleStatus: 'Successfully toggled status',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    successPasswordChanged: 'Password changed successfully'
  },
  users: {
    viewLocations: 'View Locations',
    filterBySharedWithLabel: 'Filter by Shared With',
    filterByOwnerLabel: 'Filter by Owner',
    users: 'Users',
    active: 'Active',
    pending: 'Pending',
    successChangeRole: 'Change role successfully',
    addUser: 'Add user',
    successCreateUser: 'User created successfully',
    createNewUser: 'Create new user with role',
    deleteUsers: 'Delete users',
    deleteUsersTitle: 'Are you sure want to delete these users?',
    deleteUserTitle: 'Are you sure want to delete this user?',
    successDeleteUsers: 'Users deleted successfully',
    userDetails: 'User Details',
    systemRole: 'System Role',
    filterByTextLabel: 'Filter',
    successShareLocationWithYou: 'Location shared with you successfully',
    shareWithMe: 'Share with me'
  },
  versions: {
    versions: 'Versions',
    confirmDeleteVersion: 'Do you want to delete this version?',
    successDeleteVersion: 'Version deleted successfully',
    deleteVersion: 'Delete version',
    addVersion: 'Add Version',
    uploadedFiles: 'Uploaded Files',
    activate: 'Activate',
    deactivate: 'Deactivate',
    promoteToProduction: 'Promote to Production',
    versionName: 'Version Name',
    wifiModuleVersion: 'Wifi module version name',
    fileUrl: 'File Uploaded Url',
    customUrl: 'Custom URL',
    otaFile: 'Wifi module OTA file',
    successAddFirmwareVersion: 'Firmware version added successfully',
    successEditFirmwareVersion: 'Firmware version edited successfully',
    statusChanged: 'Status changed successfully',
    uploadFile: 'Upload File',
    upgradeType: 'Upgrade Type',
    controllerType: 'Controller Type',
    successDeleteUploadedFiles: 'Uploaded files deleted successfully',
    deleteFiles: 'Delete file(s)',
    confirmDeleteFiles: 'Do you want to delete these file(s)?',
    filterByTarget: 'Filter by Target'
  },
  manageRoles: {
    manageRoles: 'Manage Roles',
    addRole: 'Add New Role',
    addRoleTitle: 'Add Role',
    name: 'Name',
    addAdmin: 'Add Admin',
    accountEmail: 'Account Email',
    role: 'Role',
    usePredefineRole: 'Use a pre-defined role',
    successAddNewRole: 'Added new rol successfully'
  },
  applications: {
    transferLoc:
      'Transfer ownership of this location to another administrator.',
    occurs: 'Occurs',
    at: 'at',
    automaticUpdates: 'Automatic Updates',
    bitField: 'Bitfield',
    auditLog: 'Audit Log',
    enableAuditLog: 'Enable Audit Log',
    sunriseTime: 'Sunrise Time',
    sunsetTime: 'Sunset Time',
    localTime: 'Local Time',
    longitude: 'Longitude',
    latitude: 'Latitude',
    streetAddress: 'Street Address',
    currentOwner: 'Current Owner',
    consoleControls: 'Console Controls',
    showMoreDetails: 'Show more Details',
    pageTitle: 'Applications',
    menu: {
      sb600: 'SB 600'
    }
  },
  devices: {
    filterByKeyword: 'Filter by Keyword',
    filterByUser: 'Filter by User',
    status: 'Status',
    version: 'Version',
    model: 'Model',
    showDevices: 'Show Assigned/Unassigned devices',
    lastAnnounceInfo: 'Last Announce Info',
    addMethod: 'Add Method',
    dbDeleteTitle: 'Do you want to delete this database?',
    methodDeleteTitle: 'Do you want to delete this method?',
    productType: 'Product Type',
    methodType: 'Method Type',
    deviceId: 'Device Id',
    enterDeviceId: 'Enter device id',
    devices: 'Devices',
    addDeviceToLocationTitle: 'Add device(s) to location',
    addToLocation: 'Add to location',
    deleteDeviceSuccess: 'Device deleted successfully',
    singletonSentSuccess: 'Singleton method sent successfully',
    staticSentSuccess: 'Static method sent successfully',
    directMethodSentSuccess: 'Direct method sent successfully',
    deleteMethodsTitle: 'Are you sure want to delete these methods?',
    deleteMethods: 'Delete methods',
    successDeleteMethods: 'Methods deleted successfully',
    ssidLabel: 'SSID',
    ssidPlaceholder: 'Enter SSID',
    passwordLabel: 'Password',
    passwordPlaceholder: 'Enter Password',
    setWifiCredential: 'Set Wifi Credential',
    sendFileThroughEmailTitle: 'Send file to email',
    sendFiles: 'Send Files',
    emailToMe: 'Email to me',
    emailSentSuccess: 'Email sent successfully',
    deleteAssignedLocation: 'Delete assigned location',
    synced: 'Synced',
    notSynced: 'Not Synced',
    allSynced: 'All synced',
    na: 'No info',
    onlineMoreThan15: 'Online (more than 15 minutes)',
    onlineLessThan15: 'Online (less than 15 minutes)',
    offlineMoreThan15: 'Offline (more than 15 minutes)',
    offlineLessThan15: 'Offline (less than 15 minutes)'
  },
  myFiles: { myFiles: 'My Files' },
  businessAccount: {
    createUserAndAssign: 'Create User and Assign',
    deleteBusinessAccount: 'Delete Business Account',
    leave: 'Leave Business Account',
    phone: 'Phone',
    address: 'Address',
    owner: 'Owner',
    totalMembers: 'Total Members',
    viewMembers: 'View Members',
    addMember: 'Enter email to invite member',
    businessAccountName: 'Corporate Name',
    businessAccount: 'Business Account',
    businessAccountUsers: 'Business Account users',
    addUsersToBusinessAccount: 'Add Users To Business Account',
    usersToAssign: 'Users To Assign',
    assignedUsers: 'Assigned Users',
    successAddedUser: 'Successfully Added User',
    successBusiness: 'Added Business Account Successfully',
    successEditBusiness: 'Edited Business Account Successfully',
    successDeleteUser: 'Successfully Deleted User',
    deleteBusiness: 'Do you want to delete this Business Account?',
    leaveBusiness: 'Do you want to leave this Business Account?',
    removeFromBusiness: 'Remove from Business Account',
    removeFromBusinessTitle:
      'Do you want to remove this location(s) from this Business Account?',
    successDeleteAccount: 'Successfully Deleted Business Account',
    createBusinessAccount: 'Create Business Account',
    successLeavedAccount: 'Successfully Left Business Account',
    enterABusinessAccountPhone: 'Enter a business account phone',
    deleteUserTitle: 'Do you want to delete this user(s)?',
    removeUser: 'Remove user',
    businessAccountAddress: 'Address',
    enterAnAddress: 'Enter an address',
    businessAccountPhoneNumber: 'Business Account Phone',
    businessAccountZipCode: 'Zip Code',
    businessAccountState: 'State',
    businessAccountWebsiteAddress: 'Business Account Website Address',
    websiteAddress: 'Website Address',
    editBusinessAccount: 'Edit Business Account',
    primaryContactEmail: 'Primary Contact Email',
    userNotExist: 'User does not exist',
    createUser: 'Do you want to create this user?',
    anEmailWasSentToNewUser: 'An email was sent to the user'
  },
  commands: {
    setBrightnessSuccess: 'Set brightness successfully',
    commandSentSuccessfully: 'Command sent successfully'
  },
  trimLights: {
    title: 'Trim Lights',
    setColorSuccess: 'Set color successfully'
  },
  lightsZones: {
    title: 'Lights Zones',
    lightsZones: 'Lights / Zones'
  },
  groups: {
    title: 'Groups',
    deleteGroup: 'Do you want to delete this group?',
    successDeleteGroup: 'Successfully deleted group',
    confirmDeleteGroup: 'Delete Group'
  },
  colorSelector: {
    whiteTemperature: 'White Temperatures',
    color: 'Colors',
    effects: 'Effects',
    tapeMeasure: 'Tape Measure',
    patterns: 'Patterns',
    sparkles: 'Sparkles',
    addEffect: 'Add Effect',
    effectType: 'Effect Type'
  },
  IncrementVersions: {
    increment: 'Increment:',
    testMessage: 'Test Message:',
    sequenceVersion: 'Sequence Version',
    locationVersion: 'Location Version',
    eventVersion: 'Event Version',
    scenesVersion: 'Scenes Version',
    actionsVersion: 'Actions Version',
    incrementVersionSuccess: 'Increment version successfully',
    testMessageSuccess: 'Test message sent successfully'
  },
  effects: {
    changeVideoSuccess: 'Change video successfully',
    effectsStore: 'Effects Store',
    confirmRatingTitle: 'Do you want to rate this effect?',
    effectsTitle: 'Effects',
    successAddEffect: 'Effect added successfully',
    successExecuteEffect: 'Effect executed successfully',
    rateEffectSuccess: 'Effect rated successfully',
    addNewEffect: 'Add New Effect',
    enterNameOfEffect: 'Enter name of effect',
    name: 'Name',
    tags: 'Tags',
    enterTags: 'Enter tags',
    publishInStore: 'Publish in Store',
    videoFile: 'Video File',
    shareEffect: 'Share Effect',
    rate: 'Rate',
    shareEffectText:
      'Enter email you want to share the EFFECT with or select previously selected users.',
    reportEffect: 'Report Effect',
    reportEffectText: 'Enter the reason for reporting this effect.',
    deleteEffect: 'Delete effect',
    shareEffectSuccess: 'Effect shared successfully',
    reportEffectSuccess: 'Effect reported successfully',
    deleteEffectSuccess: 'Effect deleted successfully',
    willGiveRating: 'You will give this effect a rating of',
    stars: 'stars',
    ripple: {
      rippleConfiguration: 'Ripple Configuration',
      firstWave: 'Wave 1',
      secondWave: 'Wave 2',
      thirdWave: 'Wave 3',
      amplitude: 'Amplitude',
      waveLength: 'Wave Length',
      speed: 'Speed'
    },
    viewRates: 'View Rates',
    updateEffectSuccess: 'Effect updated successfully',
    successDeleteRate: 'Rate deleted successfully'
  },
  notes: {
    addNote: 'Add Note',
    createNote: 'Create Note',
    successNote: 'Note created successfully',
    successEditNote: 'Note edited successfully',
    myNotes: 'My Notes',
    deleteNote: 'Do you want to delete this note?',
    successDeleteNote: 'Note deleted successfully',
    deleteNoteTitle: 'Delete Note',
    viewNote: 'View Note',
    editNote: 'Edit Note',
    changeSeverityLevel: 'Change Severity Level'
  },
  dashboard: {
    followUp: 'Follow up',
    myActivity: 'My Activity',
    customerActivityFeed: 'Customer Activity Feed',
    newAlerts: 'New Alerts',
    flaggedLocations: 'Flagged Locations',
    watchList: 'Watch list',
    totalControllers: 'Total Controllers'
  },
  dynamicReports: {
    ok: 'Process is running well',
    serviceHealth: 'Service Health',
    openReportList: 'Open Report List',
    openFilters: 'Open Filters',
    exportCSV: 'Export CSV',
    modalReports: {
      title: 'Select a report',
      buttonText: 'Select',
      filter: 'Filter Reports'
    },
    dynamicReportsTitle: 'Dynamic Reports'
  }
};
