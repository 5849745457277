import { styled, Box, Button } from '@mui/material';
import { colors } from '../../../constants/colors';
import { ContainerProps } from './basicModalProps';

export const ModalContainerStyled = styled(Box)<ContainerProps>(
  ({ theme, width, height }) => ({
    position: 'absolute' as 'absolute',
    padding: 15,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width,
    height,
    backgroundColor: colors.white,
    borderRadius: 5,
    boxShadow: '24',
    '@media (max-width: 768px)': {
      width: 'fit-content',
      height: 'fit-content'
    },
    '@media (max-width: 480px)': {
      width: 'fit-content',
      height: 'fit-content'
    }
  })
);

export const StyledButtons = styled(Button)({
  marginRight: 10
});

export const ButtonsContainerCenter = styled(Box)(({ theme }) => ({
  height: '10%',
  alignItems: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10
  },
  '@media (max-width: 480px)': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20
  }
}));
