import React, { useEffect, useState } from 'react';
import { ArrowBackIos, Save, Send } from '@mui/icons-material';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { RippleColorAndConfigSelectorItem } from './colorBrightnessAndBarSelection/colorBrightnessAndBarSelection';
import Strings from '../../../i18n';
import { Header } from '../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../components/layout/main/mainStyles';
import { ButtonIconStyled } from '../../locations/allLocationsList/allLocationListStyles';
import { usePostRippleCommandMutation } from '../../../services/effects/effectsService';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { AddRippleEffectConfiguration } from './addRippleEffectConfiguration/addRippleEffectConfiguration';
import { RippleEffectDemo } from './rippleEffectDemo/rippleEffectDemo';

interface RippleConfigurationProps {
  dataTypeAndId: {
    id: number;
    type: string;
  };
}
const StyledTitleConfigItem = styled.h3`
  font-size: 1rem;
  margin: 0;
  margin-top: 10px;
  text-align: center;
`;

const imgRipple = require('../../../assets/effects/rippleDemo.jpg');

export const RippleConfiguration = ({
  dataTypeAndId
}: RippleConfigurationProps) => {
  const [postRipple, { isSuccess, data }] = usePostRippleCommandMutation();
  const { locationId } = useParams();
  const [openedSideBar, setOpenSideBar] = useState(false);
  const [payload, setPayload] = useState({
    id: dataTypeAndId.id,
    type: dataTypeAndId.type,
    duration: 0,
    brightness: 0,
    configuration: {
      firstWave: {
        colorId: 0,
        brightness: 0,
        amplitude: 0.5,
        waveLength: 100,
        speed: 0
      },
      secondWave: {
        colorId: 0,
        brightness: 0,
        amplitude: 0.5,
        waveLength: 100,
        speed: 0
      },
      thirdWave: {
        colorId: 0,
        brightness: 0,
        amplitude: 0.5,
        waveLength: 100,
        speed: 0
      }
    }
  });

  const navigate = useNavigate();

  const backTo = () => {
    navigate(`locations/${locationId}/devices/${dataTypeAndId.type}`);
  };

  const sendCommand = () => {
    postRipple(payload);
  };

  const openSideBarToAdd = () => {
    setOpenSideBar(true);
  };

  const getRoute = () => {
    switch (dataTypeAndId.type) {
      case 'Group':
        return 'groups';
      default:
        return '';
    }
  };
  const redirectTo = () => {
    navigate(`/locations/${locationId}/devices/${getRoute()}`);
  };
  useEffect(() => {
    if (isSuccess && data) {
      toast.success(Strings.commands.commandSentSuccessfully);
      redirectTo();
    }
  }, [isSuccess]);
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between align-items-center">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => backTo()}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.effects.ripple.rippleConfiguration}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <ButtonIconStyled size="small" onClick={() => sendCommand()}>
                <Send />
              </ButtonIconStyled>
              <ButtonIconStyled size="small" onClick={() => openSideBarToAdd()}>
                <Save />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <>
        <div className="row justify-content-center mt-2">
          <div className="col-8">
            <img src={imgRipple} alt="" width="100%" />
            {/* <RippleEffectDemo
              configuration={{
                firstWave: payload.configuration.firstWave,
                secondWave: payload.configuration.secondWave,
                thirdWave: payload.configuration.thirdWave
              }}
            /> */}
          </div>
        </div>

        <StyledTitleConfigItem>
          {Strings.effects.ripple.firstWave}
        </StyledTitleConfigItem>
        <RippleColorAndConfigSelectorItem
          item={payload.configuration.firstWave}
          setConfiguration={(item: any) =>
            setPayload({
              ...payload,
              configuration: {
                ...payload.configuration,
                firstWave: item
              }
            })
          }
        />
        <StyledTitleConfigItem>
          {Strings.effects.ripple.secondWave}
        </StyledTitleConfigItem>
        <RippleColorAndConfigSelectorItem
          item={payload.configuration.secondWave}
          setConfiguration={(item: any) =>
            setPayload({
              ...payload,
              configuration: {
                ...payload.configuration,
                secondWave: item
              }
            })
          }
        />
        <StyledTitleConfigItem>
          {Strings.effects.ripple.thirdWave}
        </StyledTitleConfigItem>
        <RippleColorAndConfigSelectorItem
          item={payload.configuration.thirdWave}
          setConfiguration={(item: any) =>
            setPayload({
              ...payload,
              configuration: {
                ...payload.configuration,
                thirdWave: item
              }
            })
          }
        />
      </>
      <RightSideBar
        open={openedSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
        }}
      >
        <AddRippleEffectConfiguration
          data={payload}
          locationId={locationId ? +locationId : 0}
          closeSideBar={() => setOpenSideBar(false)}
          redirectTo={() => redirectTo()}
        />
      </RightSideBar>
    </>
  );
};
