import React, { useEffect, useState } from 'react';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AddHome, PersonAdd } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import Strings from '../../i18n';
import { Header } from '../../components/header/header';
import { UsersByLocationTemplate } from '../../templates/locations/usersByLocation/usersByLocationTemplate';
import { ButtonIconStyled } from '../users/usersViewStyles';
import { viewRightSideBarEnum } from '../../constants/sidebarEnums';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import { IUserInfo } from '../../types/Auth/authInterface';
import { selectCurrentUser } from '../../store/slices/login/login';
import { useShareLocationWithTargetsMutation } from '../../services/locations/locationsService';
import { locationRoleEnum } from '../../constants/commonEnums';

export const UserByLocationView = () => {
  const user = useSelector(selectCurrentUser);
  const { locationId } = useParams();
  const navigate = useNavigate();
  const [
    shareLocationWithTargets,
    { isSuccess: isSuccessShareLocationWithTargets }
  ] = useShareLocationWithTargetsMutation();
  const [openSideBar, setOpenSideBar] = useState(false);
  const [reloadTableHandler, setReloadTableHandler] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUserInfo[]>([]);
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.editUserPermissions
  );
  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };

  const shareWithMe = () => {
    if (locationId && user) {
      shareLocationWithTargets({
        locationIds: [+locationId],
        targets: [{ value: user?.email ?? '', type: 1 }],
        locationRoleId: locationRoleEnum.admin,
        shareWithDomain: false,
        shareWithBusinessAccount: false
      });
    }
  };

  useEffect(() => {
    if (isSuccessShareLocationWithTargets) {
      toast.success(Strings.users.successShareLocationWithYou);
      setReloadTableHandler(true);
    }
  }, [isSuccessShareLocationWithTargets]);

  return (
    <div>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left" />
            <div className="col-4 text-center">
              <StyledTitleHeader>{Strings.users.users}</StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <ButtonIconStyled
                size="small"
                onClick={() => {
                  openRightSideBarForMethod(
                    viewRightSideBarEnum.addUserToLocation
                  );
                }}
              >
                <PersonAddAltIcon />
              </ButtonIconStyled>
              <Tooltip title={Strings.users.shareWithMe}>
                <ButtonIconStyled size="small" onClick={() => shareWithMe()}>
                  <AddHome />
                </ButtonIconStyled>
              </Tooltip>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <UsersByLocationTemplate
        sideBarOpenFor={sideBarOpenFor}
        openRightSideBarForMethod={openRightSideBarForMethod}
        openedSideBar={openSideBar}
        setOpenSideBar={setOpenSideBar}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        reloadTableHandler={reloadTableHandler}
        setReloadTableHandler={setReloadTableHandler}
      />
    </div>
  );
};
