import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import {
  ArrowBack,
  Assignment,
  FileDownload,
  Launch
} from '@mui/icons-material';
import { ReportsContainer, LinealBar } from './dynamicReportsStyles';
import { DynamicTable } from '../../templates/dynamicReports/dynamicTable/dynamicTable';
import { ModalFilter } from '../../templates/dynamicReports/modalFilter/modalFilter';
import { ModalReports } from '../../templates/dynamicReports/modalReports/modalReports';
import { TypesInput } from '../../constants/commonEnums';
import { InputValue } from '../../types/dynamicReports/dynamicReportsInterface';
import { useDynamicReportsConfig } from '../../constants/context/dynamicReportsProvider/useDynamicReportsProvider';
import {
  useGetApiCallMutation,
  useGetReportFiltersMutation,
  useGetReportListMutation
} from '../../services/dynamicReports/dynamicReportsService';
import { Header } from '../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader,
  TooltipStyled
} from '../../components/layout/main/mainStyles';
import { ButtonIconStyled } from '../devices/devicesStyles';
import Strings from '../../i18n';

export const DynamicReportsView = () => {
  const { addConfigDynamicReports, configDynamicReports } =
    useDynamicReportsConfig();
  const [showTable, setShowTable] = useState<boolean>(false);

  const [reportId, setReportId] = useState<number>(-1);
  const [pathToReport, setPathToReport] = useState<string>('');
  const [queryString, setQueryString] = useState<string>('');
  const [searchReport, setSearchReport] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<InputValue[]>([]);
  const emptyString = '';
  const emptyArray = 0;
  const invalidId = -1;
  const navigate = useNavigate();
  const [
    getApiCall,
    {
      data: tableData,
      isLoading: isLoadingApiCall,
      isSuccess: isSuccessApiCall
    }
  ] = useGetApiCallMutation();
  const [
    getReportList,
    {
      data: reportsList,
      isLoading: isLoadingReportsList,
      isSuccess: isSuccessReportsList
    }
  ] = useGetReportListMutation();
  const [
    getReportFilters,
    {
      data: reportFilters,
      isLoading: isLoadingReportsFilters,
      isSuccess: isSuccessReportFilters
    }
  ] = useGetReportFiltersMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  // @ts-ignore
  const queryStrings = Object.fromEntries([...searchParams]);

  const handleOnValueChange = (
    isRequired: boolean,
    queryStringParam: string,
    type: string,
    value: any
  ) => {
    const repeatQuery = inputValue.find(
      (query) => query.queryStringParam === queryStringParam
    );
    if (repeatQuery) {
      const aux = [...inputValue];
      const index = aux.findIndex(
        (repeatValue) =>
          repeatValue.queryStringParam === repeatQuery.queryStringParam
      );

      aux[index] = { queryStringParam, type, value, isRequired };
      setInputValue(aux);
    } else {
      setInputValue((prev) => [
        ...prev,
        { queryStringParam, type, value, isRequired }
      ]);
    }
  };

  const onSearch = () => {
    const queryStringOnUrl: any = { reportId: reportId.toString() };
    inputValue.forEach((value) => {
      if (!value.value || value.value === emptyString) {
        return;
      }
      queryStringOnUrl[value.queryStringParam as keyof { any: any }] =
        value.type === TypesInput.list ? value.value.id : value.value;
      setQueryString(
        (prev) =>
          `${prev}${value.queryStringParam}=${
            value.type === TypesInput.list ? value.value.id : value.value
          }&`
      );
    });
    setSearchReport(true);
    setSearchParams(queryStringOnUrl);
    addConfigDynamicReports({
      isOpenModalFilters: false
    });
  };

  useEffect(() => {
    addConfigDynamicReports({
      isReportHaveFilters: false
    });
    setShowTable(false);
    setInputValue([]);
    setQueryString('');
    if (reportsList) {
      const findReport = reportsList.find(
        (report: { name: string }) =>
          report.name === configDynamicReports.reportSelected
      );
      if (findReport) {
        setReportId(findReport.id);
        setPathToReport(`${findReport.path}?`);
        setQueryString(`reportId=${findReport.id}&`);
      }
    }
  }, [configDynamicReports.reportSelected]);

  useEffect(() => {
    setSearchReport(false);
  }, [queryString]);

  const onCloseModalFilter = () => {
    addConfigDynamicReports({
      isOpenModalFilters: false
    });
  };

  const onCloseModalReports = () => {
    addConfigDynamicReports({
      isOpenModalReports: false
    });
  };

  useEffect(() => {
    if (reportFilters && reportFilters.length > emptyArray) {
      addConfigDynamicReports({
        isReportHaveFilters: true,
        isOpenModalFilters: true
      });
    } else if (reportFilters && !reportFilters.length) {
      onSearch();
    }
    if (reportFilters && reportFilters.length === emptyArray) {
      addConfigDynamicReports({
        isReportHaveFilters: false,
        isOpenModalFilters: false
      });
      getApiCall({ path: pathToReport, queryString });
    }
  }, [reportFilters]);

  useEffect(() => {
    if (tableData) {
      addConfigDynamicReports({
        isReportExist: true,
        isOpenModalReports: false,
        dataTable: tableData?.rows,
        isOpenModalFilters: false
      });
      setSearchReport(false);
      setShowTable(true);
    }
  }, [tableData]);

  useEffect(() => {
    setShowTable(false);
    if (
      queryStrings &&
      Object.keys(queryStrings).length !== emptyArray &&
      Object.getPrototypeOf(queryStrings) === Object.prototype
    ) {
      addConfigDynamicReports({
        isSelected: true,
        isOpenModalReports: false
      });
    } else {
      addConfigDynamicReports({
        isSelected: true,
        isOpenModalReports: true
      });
    }

    return () => {
      addConfigDynamicReports({
        isSelected: false,
        isReportExist: false,
        reportSelected: '',
        isOpenModalReports: false,
        isOpenModalFilters: false,
        dataTable: [],
        isReportHaveFilters: false,
        filterText: ''
      });
    };
  }, []);
  useEffect(() => {
    if (reportsList && Object.keys(queryStrings).length !== emptyArray) {
      const reportInQuery = searchParams.get('reportId');
      const findReport = reportsList.find(
        (report) => report.id.toString() === reportInQuery
      );

      if (findReport) {
        setPathToReport(`${findReport.path}?`);
        addConfigDynamicReports({
          isSelected: true,
          isOpenModalReports: false,
          reportSelected: findReport.name
        });
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const query in queryStrings) {
        if (Object.prototype.hasOwnProperty.call(queryStrings, query)) {
          setQueryString((prev) => `${prev}${query}=${queryStrings[query]}&`);
        }
      }

      setSearchReport(true);
    }
  }, [isSuccessReportsList]);

  useEffect(() => {
    if (isLoadingApiCall) {
      setQueryString(`reportId=${reportId}&`);
    }
  }, [isLoadingApiCall]);

  useEffect(() => {
    getReportList();
  }, []);

  useEffect(() => {
    if (configDynamicReports.reportSelected) {
      getReportFilters(
        reportsList?.find(
          (report) => report.name === configDynamicReports.reportSelected
        )?.id || 0
      );
    }
  }, [configDynamicReports.reportSelected]);

  useEffect(() => {
    if (pathToReport && queryString && searchReport) {
      addConfigDynamicReports({
        isOpenModalFilters: false,
        isOpenModalReports: false
      });
      getApiCall({ path: pathToReport, queryString });
    }
  }, [pathToReport, queryString]);

  const openReportList = () => {
    addConfigDynamicReports({
      isOpenModalReports: true
    });
  };

  const openFilters = () => {
    addConfigDynamicReports({
      isOpenModalFilters: true
    });
  };

  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled
                size="small"
                onClick={() => navigate('/server-access')}
              >
                <ArrowBack />
              </ButtonIconStyled>
            </div>
            <div className="col-3 col-sm-4 text-center">
              <StyledTitleHeader align="center">
                {configDynamicReports.reportSelected ??
                  Strings.dynamicReports.dynamicReportsTitle}
              </StyledTitleHeader>
            </div>
            <div className="col-8 col-sm-4 text-right">
              <Tooltip
                title={Strings.dynamicReports.openReportList}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled size="small" onClick={() => openReportList()}>
                  <Assignment />
                </ButtonIconStyled>
              </Tooltip>
              <Tooltip
                title={Strings.dynamicReports.openFilters}
                placement="top"
                arrow
                componentsProps={TooltipStyled}
              >
                <ButtonIconStyled size="small" onClick={() => openFilters()}>
                  <Launch />
                </ButtonIconStyled>
              </Tooltip>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      {isLoadingReportsList || isLoadingApiCall || isLoadingReportsFilters ? (
        <LinealBar sx={{ height: '5px' }} />
      ) : null}
      <ReportsContainer>
        {showTable && (
          <DynamicTable columns={tableData?.columns} rows={tableData?.rows} />
        )}
      </ReportsContainer>
      <ModalFilter
        inputsValues={inputValue}
        open={configDynamicReports.isOpenModalFilters || false}
        handleClose={onCloseModalFilter}
        inputsFilter={reportFilters}
        onValueChange={handleOnValueChange}
        onSearch={onSearch}
      />
      <ModalReports
        open={configDynamicReports.isOpenModalReports}
        handleClose={onCloseModalReports}
        reports={reportsList}
      />
    </>
  );
};
