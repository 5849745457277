import React from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import {
  StyledButtonName,
  StyledCreateBusinessAccount
} from './businessAccountTemplateStyles';
import { BusinessAccountTemplateProps } from './businessAccountTemplateProps';
import Strings from '../../i18n';
import { BusinessAccount } from '../../types/businessAccount/businessAccount';
import { ContainerTable } from '../locations/locationsList/locationListStyles';

export const BusinessAccountTemplate = ({
  data,
  reloadTable,
  openAddBusiness,
  selectedBusinessAccount,
  setSelectedBusinessAccount
}: BusinessAccountTemplateProps) => {
  const getTag = (item: BusinessAccount) => {
    return (
      <Grid container>
        <Tooltip title={Strings.common.copyText} placement="top" arrow>
          <IconButton
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(item.tag);
              toast.success(Strings.common.copiedText);
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <StyledButtonName needsInformation>{item.tag}</StyledButtonName>
      </Grid>
    );
  };

  return (
    <ContainerTable component="main">
      <DataTable
        selection={selectedBusinessAccount}
        selectionMode="checkbox"
        onSelectionChange={(e) => {
          setSelectedBusinessAccount(e.value);
        }}
        value={data}
        size="small"
        scrollHeight="flex"
        scrollable
        paginator={data && data.length >= 50}
        selectionPageOnly
        rows={50}
        rowsPerPageOptions={[5, 10, 25, data ? data.length : 50]}
        tableStyle={{ fontSize: '14px' }}
        emptyMessage={
          <StyledCreateBusinessAccount
            variant="contained"
            color="info"
            onClick={openAddBusiness}
          >
            {Strings.businessAccount.createBusinessAccount}
          </StyledCreateBusinessAccount>
        }
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        <Column field="name" header="Name" sortable />
        <Column field="tag" header="Tag" sortable body={getTag} />
        <Column field="address" header="Address" sortable />
        <Column field="role" header="Role" sortable />
        <Column field="owner" header="Owner" sortable />
      </DataTable>{' '}
    </ContainerTable>
  );
};
