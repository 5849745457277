/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import {
  AddCircleOutline,
  ArrowBackIos,
  North,
  South
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import {
  useDeleteMultipleDeviceMethodsMutation,
  useGetDeviceMethodsMutation,
  useUpdateMethodsOrderMutation
} from '../../../services/device/deviceService';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import { Header } from '../../../components/header/header';
import { ButtonIconStyled } from '../../../views/devices/devicesStyles';
import { methodTypeEnum } from '../../../constants/typeMethods';
import Strings from '../../../i18n';
import {
  DeviceMethod,
  IMethodsInfoByDeviceId
} from '../../../types/Device/DeviceInterfaces';
import { selectSystemDevicesReadWrite } from '../../../store/slices/systemPermissions/systemPermissions';
import {
  StyledBoxTable,
  StyledHeaderContainer,
  StyledTitleHeader,
  styledCheckboxHeader,
  styledColumnHeaderCheckbox
} from '../../../components/layout/main/mainStyles';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { MultipleScheduleMethodsDetails } from './multipleScheduleMethodsDetails/multipleScheduleMethodsDetails';
import { DeleteButtonWithoutText } from '../../../components/commons/DeleteButtonWithoutText/deleteButtonWithoutText';
import { AddDeviceMethodFromTemplate } from './addDeviceMethodFromTemplate/addDeviceMethodFromTemplate';

export const DeviceMethodsTemplate = () => {
  const { deviceId } = useParams();
  const [openSideBar, setOpenSideBar] = useState(false);
  const [minMaxOrderSingleton, setMinMaxOrderSingleton] = useState<{
    min: number;
    max: number;
  }>({ min: 0, max: 0 });
  const [minMaxOrderStatic, setMinMaxOrderStatic] = useState<{
    min: number;
    max: number;
  }>({ min: 0, max: 0 });
  const [sortedMethods, setSortedMethods] = useState<IMethodsInfoByDeviceId[]>(
    []
  );
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.empty
  );
  const [selectedDeviceMethods, setSelectedDeviceMethods] = useState<
    IMethodsInfoByDeviceId[]
  >([]);
  const permissionReadWrite = useSelector(selectSystemDevicesReadWrite);

  const navigate = useNavigate();

  const [getDeviceMethod, { isLoading, isSuccess, data }] =
    useGetDeviceMethodsMutation();

  const [deleteDeviceMethods, { isSuccess: isSuccessDeleteDeviceMethods }] =
    useDeleteMultipleDeviceMethodsMutation();

  const [updateOrder, { isSuccess: isSuccessOrder }] =
    useUpdateMethodsOrderMutation();

  const deleteItem = (element: DeviceMethod) => {
    if (element) {
      if (element?.methodType === methodTypeEnum.static) {
        deleteDeviceMethods({ singletonIds: [], staticIds: [element.id] });
      } else {
        deleteDeviceMethods({ singletonIds: [element.id], staticIds: [] });
      }
    }
  };
  const loadTable = () => {
    getDeviceMethod(deviceId ?? '');
    setOpenSideBar(false);
    setSelectedDeviceMethods([]);
  };

  useEffect(() => {
    if (isSuccessDeleteDeviceMethods) {
      toast.success(Strings.serverAccess.devices.deletedMethod);
      loadTable();
      setSelectedDeviceMethods([]);
    }
  }, [isSuccessDeleteDeviceMethods]);

  useEffect(() => {
    if (deviceId) {
      loadTable();
    } else {
      navigate(-1);
    }
  }, []);

  const filterAndMinMaxMethodType = () => {
    if (data) {
      const singletonMethods = data
        .filter((item) => item.methodType === 'Singleton')
        .sort((a, b) => a.methodIndex - b.methodIndex);

      const staticMethods = data
        .filter((item) => item.methodType === 'Static')
        .sort((a, b) => a.methodIndex - b.methodIndex);

      setSortedMethods([...singletonMethods, ...staticMethods]);

      const minSingletonMethodIndex = Math.min(
        ...singletonMethods.map((item) => item.methodIndex)
      );
      const maxSingletonMethodIndex = Math.max(
        ...singletonMethods.map((item) => item.methodIndex)
      );
      setMinMaxOrderSingleton({
        min: minSingletonMethodIndex,
        max: maxSingletonMethodIndex
      });
      const minStaticMethodIndex = Math.min(
        ...staticMethods.map((item) => item.methodIndex)
      );
      const maxStaticMethodIndex = Math.max(
        ...staticMethods.map((item) => item.methodIndex)
      );
      setMinMaxOrderStatic({
        min: minStaticMethodIndex,
        max: maxStaticMethodIndex
      });
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      filterAndMinMaxMethodType();
    }
  }, [isSuccess]);

  useEffect(() => {
    loadTable();
  }, []);

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenSideBar(true);
  };

  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedDeviceMethods.length ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={
          (selectedDeviceMethods && selectedDeviceMethods.length > 0) ?? false
        }
        onChange={() => {
          setSelectedDeviceMethods([]);
        }}
      />
    );
  };
  useEffect(() => {
    if (selectedDeviceMethods.length === 1) {
      openRightSideBarForMethod(
        viewRightSideBarEnum.multipleScheduleMethodsDetails
      );
    } else if (selectedDeviceMethods.length > 1) {
      openRightSideBarForMethod(
        viewRightSideBarEnum.multipleScheduleMethodsDetails
      );
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedDeviceMethods]);

  const handleClose = () => {
    setOpenSideBar(false);
    setSelectedDeviceMethods([]);
  };

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.multipleScheduleMethodsDetails:
        return (
          <MultipleScheduleMethodsDetails
            setOpenSideBar={setOpenSideBar}
            openRightSideBarForMethod={openRightSideBarForMethod}
            selectedMethods={selectedDeviceMethods}
            reloadTable={() => loadTable()}
          />
        );
      case viewRightSideBarEnum.addDeviceMethodFromTemplate:
        return (
          <AddDeviceMethodFromTemplate
            closeBar={handleClose}
            deviceId={deviceId ?? ''}
            reloadTable={() => loadTable()}
          />
        );
      default:
        return <p />;
    }
  };

  const getDisableUpButton = (rowData: IMethodsInfoByDeviceId) => {
    if (rowData.methodType === methodTypeEnum.singleton) {
      return rowData.methodIndex === minMaxOrderSingleton.min;
    }
    return rowData.methodIndex === minMaxOrderStatic.min;
  };
  const getDisableDownButton = (rowData: IMethodsInfoByDeviceId) => {
    if (rowData.methodType === methodTypeEnum.singleton) {
      return rowData.methodIndex === minMaxOrderSingleton.max;
    }
    return rowData.methodIndex === minMaxOrderStatic.max;
  };

  const sendNewOrder = (
    newSort: IMethodsInfoByDeviceId[],
    methodType: string
  ) => {
    updateOrder({ data: newSort, type: methodType });
  };

  useEffect(() => {
    if (isSuccessOrder) {
      loadTable();
    }
  }, [isSuccessOrder]);

  const moveMethod = (
    sortedData: IMethodsInfoByDeviceId[],
    id: number,
    direction: string
  ) => {
    const itemToMove = sortedData.find((item) => item.id === id);

    if (!itemToMove) return sortedData;

    const { methodType } = itemToMove;

    const methods = sortedData
      .filter((item) => item.methodType === methodType)
      .sort((a, b) => a.methodIndex - b.methodIndex);

    const index = methods.findIndex((item) => item.id === id);
    const targetIndex = direction === Strings.common.up ? index - 1 : index + 1;

    if (
      direction === Strings.common.up ? index > 0 : index < methods.length - 1
    ) {
      const newMethods = methods.map((item, idx) => {
        if (idx === index) {
          return { ...item, methodIndex: methods[targetIndex].methodIndex };
        }
        if (idx === targetIndex) {
          return { ...item, methodIndex: methods[index].methodIndex };
        }
        return item;
      });

      newMethods.sort((a, b) => a.methodIndex - b.methodIndex);
      const sendValues: any = [];
      setSortedMethods(
        sortedData.map((item) => {
          if (item.methodType === methodType) {
            const updatedItem = newMethods.find(
              (method) => method.id === item.id
            );
            sendValues.push(updatedItem);
            return updatedItem ? updatedItem : item;
          }
          return item;
        })
      );
      filterAndMinMaxMethodType();
      sendNewOrder(sendValues, methodType);
      return [];
    }
    return [];
  };

  const getActions = (rowData: IMethodsInfoByDeviceId) => {
    return (
      <Grid container spacing={2}>
        <Grid item justifyContent="center">
          <Tooltip title={Strings.devices.deleteMethods}>
            <DeleteButtonWithoutText
              onDelete={() => deleteItem(rowData)}
              titleConfirmation={`${Strings.devices.methodDeleteTitle} ${deviceId}`}
              id={deviceId ?? ''}
              textConfirmation={Strings.common.thisActionIsIrreversible}
            />
          </Tooltip>
          <Tooltip title={Strings.common.reOrder}>
            <ButtonIconStyled
              size="small"
              onClick={() =>
                moveMethod(sortedMethods, rowData.id, Strings.common.up)
              }
              disabled={getDisableUpButton(rowData)}
            >
              <North />
            </ButtonIconStyled>
          </Tooltip>
          <Tooltip title={Strings.common.reOrder}>
            <ButtonIconStyled
              size="small"
              onClick={() =>
                moveMethod(sortedMethods, rowData.id, Strings.common.down)
              }
              disabled={getDisableDownButton(rowData)}
            >
              <South />
            </ButtonIconStyled>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.serverAccess.devices.getMethodsTitle}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <Tooltip title={Strings.devices.addMethod}>
                <ButtonIconStyled
                  size="small"
                  onClick={() => {
                    openRightSideBarForMethod(
                      viewRightSideBarEnum.addDeviceMethodFromTemplate
                    );
                  }}
                >
                  <AddCircleOutline />
                </ButtonIconStyled>
              </Tooltip>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <Typography
        variant="subtitle1"
        color="initial"
        textAlign="center"
        fontSize={12}
      >
        {Strings.serverAccess.devices.currentDevice} {deviceId}
      </Typography>
      <StyledBoxTable>
        {isLoading && <LoadingRowsComponent />}
        {isSuccess && data && sortedMethods && (
          <DataTable
            selectionMode="checkbox"
            selection={selectedDeviceMethods}
            onSelectionChange={(e) => setSelectedDeviceMethods(e.value)}
            value={sortedMethods}
            size="small"
            scrollable
            scrollHeight="flex"
            paginator={data && data.length >= 20}
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[5, 10, 20, data ? data.length : 50]}
            tableStyle={{ fontSize: '14px' }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={styledColumnHeaderCheckbox}
              header={getHeaderCheckbox}
              frozen
            />
            <Column
              header="Method Type"
              field="methodType"
              style={{ minWidth: '150px' }}
            />
            <Column field="methodValue" header="Method String" />
            <Column
              field=""
              header="Actions"
              body={getActions}
              style={{ minWidth: '140px' }}
            />
          </DataTable>
        )}
      </StyledBoxTable>
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
          setSelectedDeviceMethods([]);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
