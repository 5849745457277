/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentUser,
  setCredentials,
  setTokenAndRefreshToken
} from '../../../store/slices/login/login';
import { PrivateRouteProps } from './privateRoute.props';
import { useGetLoggedUserSystemRolePermissionsMutation } from '../../../services/systemPermissions/systemPermissionsService';
import { useGetUserInfoMutation } from '../../../services/users/usersService';
import { useGetUserLastLocationsMutation } from '../../../services/locations/locationsService';
import { setUserLastLocations } from '../../../store/slices/locations/location';

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const user = useSelector(selectCurrentUser);

  const [getInfo, { data: userInfo, isSuccess: isSuccessGetInfo }] =
    useGetUserInfoMutation();

  const [getRoleByToken, { data: roleInfo, isSuccess: isSuccessGetRole }] =
    useGetLoggedUserSystemRolePermissionsMutation();

  const dispatch = useDispatch();

  const [
    getUserLastLocation,
    { isSuccess: isSuccessLastLocation, data: dataLastLocation }
  ] = useGetUserLastLocationsMutation();

  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('authToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (token) {
        dispatch(
          setTokenAndRefreshToken({ token, refreshToken: refreshToken || '' })
        );
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (user.userId) {
      getUserLastLocation({ userId: user.userId, numLocations: 8 });
    }
  }, [user.userId]);

  useEffect(() => {
    if (isSuccessLastLocation && dataLastLocation) {
      dispatch(
        setUserLastLocations(
          dataLastLocation.map((e) => {
            return { locationId: e.id, locationName: e.name };
          })
        )
      );
    }
  }, [isSuccessLastLocation]);

  useEffect(() => {
    if (!user.email) {
      const currentUrl = window.location.href;
      if (!currentUrl.includes('callback')) {
        localStorage.setItem('redirectUrlAfterTokenRefresh', currentUrl);
      }
      if (localStorage.getItem('authToken')) {
        getRoleByToken('');
        getInfo();
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccessGetInfo && isSuccessGetRole) {
      dispatch(
        setCredentials({
          user: '',
          email: userInfo?.email ?? '',
          token: localStorage.getItem('authToken') || '',
          picture: '',
          roleInfo: roleInfo ?? {},
          userInfo: {
            firstName: userInfo?.firstName ?? '',
            lastName: userInfo?.lastName ?? '',
            lastLogin: userInfo?.lastLogin ?? '',
            phoneNumber: String(userInfo?.phoneNumber)
          },
          refreshToken: localStorage.getItem('refreshToken') || '',
          authWithApi: true,
          userId: userInfo?.userId ?? 0
        })
      );
    }
  }, [isSuccessGetInfo, isSuccessGetRole]);

  return user && user.user !== null ? <>{children}</> : <div />;
};
