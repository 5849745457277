import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Stack,
  styled
} from '@mui/material';

export const ReportsContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  margin: `0 auto`,
  backgroundColor: 'white',
  padding: '10px'
}));

export const ColumnGrid = styled(Grid)({
  alignContent: 'center'
});

export const RowGrid = styled(Grid)(({ theme }) => ({
  margin: `10px auto`
}));

export const ItemContainer = styled(Box)(({ theme }) => ({
  margin: `10px`
}));

export const SearchButton = styled(Button)(({ theme }) => ({
  width: '120px'
}));

export const LinealBar = styled(LinearProgress)(({ theme }) => ({
  height: '15px'
}));
