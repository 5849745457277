import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos, Cached } from '@mui/icons-material';
import moment from 'moment';
import { Grid, Tooltip, Typography } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useGetRecordsSyncStatusMutation,
  useToggleSyncStatusRecordDbMutation
} from '../../../../services/device/deviceService';
import { Header } from '../../../../components/header/header';
import Strings from '../../../../i18n';
import { ButtonIconStyled } from '../../../../views/devices/devicesStyles';
import { LoadingRowsComponent } from '../../../../components/commons/LoadingRows/LoadingRows';
import {
  StyledBoxTable,
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../../components/layout/main/mainStyles';
import { RightSideBar } from '../../../../components/layout/rightSideBar/rightSideBar';
import { IRecordsSyncStatus } from '../../../../types/Device/DeviceInterfaces';
import { StyledButtonAction } from '../recordsDeviceStyle';

export const RecordsSyncStatusByDevice = () => {
  const { deviceId } = useParams();
  const [showSideBar, setShowSideBar] = React.useState(false);
  const navigate = useNavigate();

  const [
    getRecordsSync,
    { isSuccess, data: dataRecordsSync, isLoading: isLoadingRecordsSync }
  ] = useGetRecordsSyncStatusMutation();

  const [toggleSyncStatus, { isSuccess: isSuccessToggle, isLoading }] =
    useToggleSyncStatusRecordDbMutation();

  const getDataTable = () => {
    if (deviceId) {
      getRecordsSync(deviceId);
    }
  };

  useEffect(() => {
    if (deviceId) {
      getDataTable();
    }
  }, []);

  useEffect(() => {
    if (isSuccessToggle) {
      getDataTable();
    }
  }, [isSuccessToggle]);

  const getSyncStatusRow = (rowData: IRecordsSyncStatus) => {
    return rowData.syncStatus ? Strings.common.yes : Strings.common.no;
  };

  const getSyncEnabledRow = (rowData: IRecordsSyncStatus) => {
    return (
      <Tooltip title={Strings.serverAccess.devices.toggleSyncLabel}>
        <StyledButtonAction
          disabled={isLoading}
          onClick={() => {
            toggleSyncStatus({
              deviceId: deviceId ?? '',
              dbName: rowData.dbName,
              toggleText: rowData.syncEnabled ? 'Disable' : 'Enable'
            });
          }}
        >
          {rowData.syncEnabled ? Strings.common.yes : Strings.common.no}
        </StyledButtonAction>
      </Tooltip>
    );
  };

  const getDBNameBtnRedirect = (rowData: IRecordsSyncStatus) => {
    return (
      <StyledButtonAction
        onClick={() => {
          navigate(
            `/server-access/devices/recordsDevice/${deviceId}/dbName?db=${rowData.dbName}`
          );
        }}
      >
        {rowData.dbName}
      </StyledButtonAction>
    );
  };
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-3 col-sm-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.serverAccess.devices.records}
              </StyledTitleHeader>
            </div>
            <div className="col-8 col-sm-4 text-right">
              <ButtonIconStyled size="small" onClick={() => getDataTable()}>
                <Cached />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <Typography
        variant="subtitle1"
        color="initial"
        textAlign="center"
        fontSize={12}
      >
        {Strings.serverAccess.devices.currentDevice} {deviceId}
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        marginTop={1}
        alignItems="center"
      >
        <Grid item xs={12}>
          <StyledBoxTable>
            {isLoadingRecordsSync && <LoadingRowsComponent />}
            {isSuccess && dataRecordsSync && (
              <DataTable
                value={dataRecordsSync}
                size="small"
                scrollHeight="flex"
                scrollable
                paginator={dataRecordsSync && dataRecordsSync.length >= 50}
                selectionPageOnly
                rows={50}
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  dataRecordsSync ? dataRecordsSync.length : 50
                ]}
                tableStyle={{ fontSize: '14px' }}
              >
                <Column
                  field="dbName"
                  header="Database name"
                  body={getDBNameBtnRedirect}
                />
                <Column
                  field="lastRecordReceived"
                  header="Last Record Received"
                  body={(rowData: IRecordsSyncStatus) => {
                    return rowData.lastRecordReceived
                      ? moment(rowData.lastRecordReceived).format(
                          'YYYY/MM/DD, h:mmA'
                        )
                      : '';
                  }}
                />
                <Column field="deviceStatus" header="Device Status" />
                <Column field="serverStatus" header="Server Status" />
                <Column
                  field="syncStatus"
                  header="Sync Status"
                  body={getSyncStatusRow}
                />
                <Column
                  field="syncEnabled"
                  header="Sync Enabled"
                  body={getSyncEnabledRow}
                />
              </DataTable>
            )}
          </StyledBoxTable>
        </Grid>
      </Grid>
      <RightSideBar
        open={showSideBar}
        onMenuClose={() => {
          setShowSideBar(false);
        }}
      >
        <div />
      </RightSideBar>
    </>
  );
};
