import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { LocalMall, SmartScreen, Summarize } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { itemsServerAccessMenu } from './ItemsServerAccess';
import { ButtonWithRedirect } from '../../../components/commons/ButtonWithRedirect/ButtonWithRedirect';
import { colors } from '../../../constants/colors';
import { selectCurrentUser } from '../../../store/slices/login/login';

export const ServerAccessMenuComponent = () => {
  const contextUser = useSelector(selectCurrentUser);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10} md={8}>
          <Box
            sx={{ flexGrow: 1, border: `1px solid ${colors.lightGray}` }}
            marginTop={3}
          >
            <Grid container spacing={2} marginTop={2}>
              {itemsServerAccessMenu.map((element) =>
                contextUser.roleInfo.permissionsByModule?.find(
                  (module) => module.systemModuleName === element.key
                ) ? (
                  <Grid
                    item
                    xs={6}
                    md={4}
                    key={element.text}
                    textAlign="center"
                  >
                    <ButtonWithRedirect
                      icon={element.icon}
                      text={element.text}
                      url={element.url}
                    />
                  </Grid>
                ) : null
              )}
              <Grid item xs={6} md={4} key="store" textAlign="center">
                <ButtonWithRedirect
                  icon={<LocalMall />}
                  text="Effects Store"
                  url="/effects/store"
                />
              </Grid>
              <Grid item xs={6} md={4} key="reports" textAlign="center">
                <ButtonWithRedirect
                  icon={<Summarize />}
                  text="Reports"
                  url="/reports"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
