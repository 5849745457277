/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { UserSideBar } from '../../usersTemplate/userSidebar/userSidebar';
import { ManageRolesUser } from '../../usersTemplate/manageRolesUser/manageRolesUser';
import { CreateRoleUser } from '../../usersTemplate/createRoleUser/createRoleUser';
import { UsersTemplateProps } from '../../usersTemplate/usersTemplateProps';
import { StatusUser } from '../../usersTemplate/userTemplateStyles';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { DeleteButton } from '../../../components/commons/DeleteButton/deleteButton';
import Strings from '../../../i18n';
import { StyledBoxTable } from '../../../components/layout/main/mainStyles';
import { UsersByLocation } from '../../../types/users';
import { StyledButtonName } from '../../businessAccount/businessAccountTemplateStyles';
import {
  useDeleteUserFromLocationMutation,
  useGetLocationsUsersByLocationIdMutation
} from '../../../services/device/deviceService';
import { AvatarUserComponent } from '../../../components/commons/AvatarUser/avatarUser';
import { ShareLocationsWithTargets } from '../shareLocationsWithTargets/shareLocationsWithTargets';

export const UsersByLocationTemplate = ({
  sideBarOpenFor,
  openRightSideBarForMethod,
  openedSideBar,
  setOpenSideBar,
  reloadTableHandler,
  setReloadTableHandler
}: UsersTemplateProps) => {
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const [getUsers, { data }] = useGetLocationsUsersByLocationIdMutation();
  const [deleteUser, { isSuccess: isSuccessDeleteUser }] =
    useDeleteUserFromLocationMutation();
  const loadTable = () => {
    if (locationId) {
      getUsers(+locationId);
    }
  };
  useEffect(() => {
    loadTable();
  }, []);
  const deleteItem = (id: number) => {
    deleteUser(id);
  };
  useEffect(() => {
    if (isSuccessDeleteUser) {
      toast.success('user deleted successfully');
      loadTable();
    }
  }, [isSuccessDeleteUser]);
  useEffect(() => {
    if (reloadTableHandler) {
      loadTable();
      setReloadTableHandler(false);
    }
  }, [reloadTableHandler]);

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.editUserPermissions:
        return <UserSideBar setOpenSideBar={setOpenSideBar} />;
      case viewRightSideBarEnum.manageUserRoles:
        return (
          <ManageRolesUser
            openRightSideBarForMethod={openRightSideBarForMethod}
            setOpenSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.createUserRole:
        return (
          <CreateRoleUser
            openRightSideBarForMethod={openRightSideBarForMethod}
            setOpenSideBar={setOpenSideBar}
          />
        );
      case viewRightSideBarEnum.addUserToLocation:
        return (
          <ShareLocationsWithTargets
            reloadTable={() =>
              locationId ? getUsers(+locationId) : getUsers({})
            }
            selectedLocations={[locationId ? +locationId : 0]}
            setOpenRightSideBar={setOpenSideBar}
          />
        );

      default:
        return <UserSideBar setOpenSideBar={setOpenSideBar} />;
    }
  };

  const deleteButton = (item: UsersByLocation) => {
    return (
      <DeleteButton
        onDelete={() => deleteItem(item.userLocationId)}
        id={item.userLocationId.toString()}
        titleConfirmation={Strings.locations.addUserToLocation.deleteUser}
        textConfirmation={Strings.common.thisActionIsIrreversible}
      />
    );
  };

  const getFullName = (item: UsersByLocation) => {
    return (
      <StyledButtonName needsInformation={false}>
        <AvatarUserComponent fullName={item.name} size={24} /> {item.name}
      </StyledButtonName>
    );
  };

  const getStatus = (item: UsersByLocation) => {
    return (
      <StatusUser isActive={item.status === 1}>
        {item.status === 1 ? Strings.users.active : Strings.users.pending}
      </StatusUser>
    );
  };

  return (
    <>
      <StyledBoxTable>
        <DataTable
          scrollHeight="flex"
          value={data}
          size="small"
          scrollable
          paginator={data && data.length >= 50}
          selectionPageOnly
          rows={50}
          rowsPerPageOptions={[5, 10, 25, data ? data.length : 50]}
          tableStyle={{ fontSize: '14px' }}
        >
          <Column field="name" header="Name" sortable body={getFullName} />
          <Column field="email" header="Email" sortable />
          <Column field="status" header="Status" sortable body={getStatus} />
          <Column
            field="actions"
            header="Actions"
            sortable
            body={deleteButton}
          />
        </DataTable>
      </StyledBoxTable>
      <RightSideBar
        open={openedSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
