import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';
import { IFirmwareFile } from '../../../types/firmwareVersions/firmwareVersionsInterface';
import { useGetUpgradeTypesMutation } from '../../../services/firmwareVersions/firmwareVersionsService';
import { useGetAllProductNamesMutation } from '../../../services/device/deviceService';
import { ContainerTable } from '../../locations/locationsList/locationListStyles';

interface FirmwareVersionFilesListProps {
  files: IFirmwareFile[];
  selectedFiles: IFirmwareFile[];
  setSelectedFiles: (selectedFiles: IFirmwareFile[]) => void;
}
export const FirmwareVersionFilesList = ({
  files,
  selectedFiles,
  setSelectedFiles
}: FirmwareVersionFilesListProps) => {
  const [
    getUpgradeTypes,
    { isSuccess: successGetUpgradeTypes, data: dataUpgrade }
  ] = useGetUpgradeTypesMutation();

  const [
    getControllerTypes,
    { isSuccess: successGetControllerTypes, data: dataControllerTypes }
  ] = useGetAllProductNamesMutation();

  useEffect(() => {
    getUpgradeTypes();
    getControllerTypes('');
  }, []);

  const getUpgradeTypeByArray = (rowData: IFirmwareFile) => {
    if (successGetUpgradeTypes && dataUpgrade) {
      const upgradeType = dataUpgrade.data.find(
        (item) => item.id === rowData.firmwareUpgradeTypeId
      );
      return upgradeType ? upgradeType.name : 'No information';
    }
    return 'No information';
  };
  const getControllerTypeByArray = (rowData: IFirmwareFile) => {
    if (successGetControllerTypes && dataControllerTypes) {
      const controllerType = dataControllerTypes.find(
        (item) => item.id === rowData.controllerTypeId
      );
      return controllerType ? controllerType.name : 'No information';
    }
    return 'No information';
  };

  const downloadCustomUrlIfExist = (rowData: IFirmwareFile) => {
    return rowData.customUrl || rowData.fileUrl ? (
      <IconButton
        aria-label="save"
        href={
          rowData.customUrl ? rowData.customUrl ?? '' : rowData.fileUrl ?? ''
        }
        target="_blank"
        size="small"
      >
        <SaveAlt />
      </IconButton>
    ) : (
      <span className="font-italic fw-lighter">No information</span>
    );
  };
  return (
    <ContainerTable component="main">
      <DataTable
        scrollHeight="flex"
        selection={selectedFiles}
        selectionMode="checkbox"
        onSelectionChange={(e) => {
          setSelectedFiles(e.value);
        }}
        value={files}
        size="small"
        scrollable
        paginator={files && files.length >= 50}
        selectionPageOnly
        rows={50}
        rowsPerPageOptions={[5, 10, 25, files ? files.length : 50]}
        tableStyle={{ fontSize: '14px' }}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        <Column
          field="createdAt"
          header="Created At"
          sortable
          body={(rowData: IFirmwareFile) =>
            moment(rowData.createdAt).format('DD/MM/YYYY HH:mm A')
          }
        />
        <Column
          field="controllerType.typeName"
          header="Controller Type"
          sortable
          body={getControllerTypeByArray}
        />
        <Column
          field="firmwareUpgradeType.type"
          header="Upgrade Type"
          sortable
          body={getUpgradeTypeByArray}
        />
        <Column
          className="text-center"
          field="customUrl"
          header="Bin Url"
          sortable
          body={downloadCustomUrlIfExist}
        />
      </DataTable>
    </ContainerTable>
  );
};
