import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Box } from '@mui/material';
import { IFilesSyncByDevice } from '../../../types/Device/DeviceInterfaces';
import { ContainerTable } from '../../locations/locationsList/locationListStyles';

interface IFilesSyncByDeviceTable {
  data: IFilesSyncByDevice[];
}

const renderUrl = (data: IFilesSyncByDevice) => {
  return (
    <a href={data.url} target="_blank" rel="noreferrer">
      {data.url}
    </a>
  );
};

export const FilesSyncByDeviceTable = ({ data }: IFilesSyncByDeviceTable) => {
  return (
    <ContainerTable component="main">
      <DataTable
        value={data}
        size="small"
        scrollable
        scrollHeight="flex"
        paginator={data && data.length >= 50}
        selectionPageOnly
        rows={10}
        rowsPerPageOptions={[5, 10, 25, data ? data.length : 50]}
        tableStyle={{ fontSize: '14px' }}
      >
        <Column field="fileName" header="File Name" sortable />
        <Column field="url" header="URL" sortable body={renderUrl} />
        <Column field="crc" header="CRC" sortable />
      </DataTable>
    </ContainerTable>
  );
};
