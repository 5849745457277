import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useGetProcessMonitorLogMutation } from '../../services/dynamicReports/dynamicReportsService';
import { StatusList } from '../../templates/dynamicReports/statusList/statusList.component';
import { Header } from '../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../components/layout/main/mainStyles';
import { ButtonIconStyled } from '../devices/devicesStyles';
import Strings from '../../i18n';

export const ProcessMonitorLogView = () => {
  const navigate = useNavigate();
  const [getProcessMonitorLog, { data, isLoading, isError }] =
    useGetProcessMonitorLogMutation();

  useEffect(() => {
    getProcessMonitorLog();
  }, []);

  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-1 col-sm-4 text-left">
              <ButtonIconStyled
                size="small"
                onClick={() => navigate('/server-access')}
              >
                <ArrowBack />
              </ButtonIconStyled>
            </div>
            <div className="col-3 col-sm-4 text-center">
              <StyledTitleHeader align="center">
                {Strings.dynamicReports.serviceHealth}
              </StyledTitleHeader>
            </div>
            <div className="col-8 col-sm-4 text-right" />
          </div>
        </StyledHeaderContainer>
      </Header>
      <StatusList data={data || []} />
    </>
  );
};
