import React, { useEffect, useState } from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';
import { Search, AddCircleOutline, ArrowBackIos } from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StyledBoxTable } from '../../../components/layout/main/mainStyles';
import {
  ButtonIconStyled,
  StatusProcess,
  StyledButtonName,
  TitleStyled,
  TooltipStyled,
  TriangleOrg
} from './organizationsLocStyles';
import {
  useGetLocationsInOrganizationMutation,
  useUserSelectedLocationMutation
} from '../../../services/device/deviceService';

import { useGetOrganizationDetailsMutation } from '../../../services/organizations/organizationsService';
import {
  LocationByUser,
  LocationInOrganization
} from '../../../types/location/location';
import { selectSystemLocationsReadWrite } from '../../../store/slices/systemPermissions/systemPermissions';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { Header } from '../../../components/header/header';
import Strings from '../../../i18n';
import { MarginComponent } from '../../../components/marginComponent/marginComponent';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { AddLocationBar } from '../../locations/addLocationBar/addLocationBar';
import { AssignLocation } from '../../locations/assignLocation/assignLocation';
import { TransferLocationBar } from '../../locations/transferLocationBar/transferLocationBar';
import { ShareLocationBar } from '../../locations/shareLocationBar/shareLocationBar';
import { LocationDetailsBar } from '../../locations/locationDetailsBar/locationDetailsBar';
import {
  selectedNameOrganization,
  setNameOrganization
} from '../../../store/slices/organization/organization';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import { LogoOrg } from '../../locations/locationsList/locationListStyles';

const triangleOrg = require('../../../assets/triangleOrg.png');
const logoOrg = require('../../../assets/flag.png');

export const OrganizationLocList = () => {
  const dispatch = useDispatch();
  const [openRightSideBar, setOpenRightSideBar] = useState(false);
  const { organizationId } = useParams();
  const [location, setLocation] = useState<LocationByUser>({
    locationId: 0,
    locationName: '',
    isOwner: false,
    locationRoleId: 0,
    organizationName: ''
  });

  const [locationsAndOrganizations, setLocationsAndOrganizations] =
    useState<any>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const [sideBarOpenFor, setSideBarOpenFor] = useState(
    viewRightSideBarEnum.addLocation
  );
  const permissionReadWrite = useSelector(selectSystemLocationsReadWrite);
  const nameOrganization = useSelector(selectedNameOrganization);
  const navigate = useNavigate();
  const contextUser = useSelector(selectCurrentUser);
  const getRowId = (row: LocationInOrganization) => `${row.locationId}loc`;
  const [selectLocationPost] = useUserSelectedLocationMutation();
  const [getLocationsInOrganization, { data, isSuccess, isLoading }] =
    useGetLocationsInOrganizationMutation();
  const [
    getOrganizationDetails,
    { data: dataDetails, isLoading: isLoadingDetails }
  ] = useGetOrganizationDetailsMutation();

  const openRightSideBarForMethod = (method: number) => {
    setSideBarOpenFor(method);
    setOpenRightSideBar(true);
  };
  const reloadTable = (id?: number) => {
    if (organizationId) {
      getLocationsInOrganization(
        id ? id.toString() : organizationId.toString()
      );
    }
  };

  useEffect(() => {
    if (organizationId) {
      getOrganizationDetails(+organizationId);
      reloadTable();
    }
  }, []);

  useEffect(() => {
    if (isSuccess && data && organizationId) {
      setLocationsAndOrganizations([...data.locations, ...data.organizations]);
      getOrganizationDetails(+organizationId);
      setOpenRightSideBar(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!openRightSideBar) {
      setSelectionModel([]);
    }
  }, [openRightSideBar]);

  useEffect(() => {
    if (selectionModel.length) {
      openRightSideBarForMethod(viewRightSideBarEnum.locationDetails);
    }
  }, [selectionModel]);

  const selectLocation = (params: LocationInOrganization) => {
    if (permissionReadWrite) {
      if (params.isOrganization) {
        navigate(`/Organizations/${params.id}`);
        dispatch(setNameOrganization(params.name));
        reloadTable(params.id);
      } else {
        selectLocationPost({
          locationId: params.id,
          userId: contextUser.userId || 0
        });

        navigate(`/locations/${params.id}/devices`);
      }
    }
  };

  const getName = (item: LocationInOrganization) => {
    return (
      <>
        {item.isOrganization && (
          <LogoOrg src={logoOrg} alt="org" style={{ marginLeft: '10px' }} />
        )}
        <StyledButtonName onClick={() => selectLocation(item)}>
          {item.name}
        </StyledButtonName>
      </>
    );
  };

  const getIconColum = (item: any) => {
    return item.isOrganization ? (
      <TriangleOrg src={triangleOrg} alt="triangleOrg" />
    ) : (
      <StatusProcess />
    );
  };

  useEffect(() => {
    reloadTable();
  }, [organizationId]);

  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.addLocation:
        return (
          <AddLocationBar
            organizationId={organizationId ? +organizationId : undefined}
            reloadTable={() =>
              getLocationsInOrganization(
                organizationId ? organizationId.toString() : ''
              )
            }
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.assignLocation:
        return (
          <AssignLocation
            reloadTable={() =>
              getLocationsInOrganization(
                organizationId ? organizationId.toString() : ''
              )
            }
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.transferLocation:
        return (
          <TransferLocationBar
            reloadTable={() =>
              getLocationsInOrganization(
                organizationId ? organizationId.toString() : ''
              )
            }
            selectedLocations={[location.locationId]}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.shareLocation:
        return (
          <ShareLocationBar
            reloadTable={() =>
              getLocationsInOrganization(
                organizationId ? organizationId.toString() : ''
              )
            }
            selectedLocations={[location.locationId]}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      case viewRightSideBarEnum.locationDetails:
        return (
          <LocationDetailsBar
            openRightSideBarForMethod={openRightSideBarForMethod}
            reloadTable={() =>
              getLocationsInOrganization(
                organizationId ? organizationId.toString() : ''
              )
            }
            locationOrg={location}
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
      default:
        return (
          <AddLocationBar
            reloadTable={() =>
              getLocationsInOrganization(
                organizationId ? organizationId.toString() : ''
              )
            }
            setOpenRightSideBar={setOpenRightSideBar}
          />
        );
    }
  };
  const openDetailsAndSet = (locationOrg: any) => {
    if (locationOrg.data.isOrganization) {
      setLocation({
        organizationName: locationOrg.data.name,
        organizationId: locationOrg.data.id,
        ...locationOrg.data
      });
      openRightSideBarForMethod(viewRightSideBarEnum.locationDetails);
    } else {
      setLocation({
        locationName: locationOrg.data.name,
        locationId: locationOrg.data.id,
        isLocation: true,
        ...locationOrg.data
      });
      openRightSideBarForMethod(viewRightSideBarEnum.locationDetails);
    }
  };
  return (
    <Box>
      <Header>
        <Search />
        <MarginComponent ml={30} mr={30}>
          <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
            <ArrowBackIos />
          </ButtonIconStyled>
        </MarginComponent>
        {!isLoadingDetails && dataDetails && (
          <TitleStyled align="center">{dataDetails.name}</TitleStyled>
        )}

        <Tooltip
          title={Strings.locations.addLocation}
          placement="top"
          arrow
          componentsProps={TooltipStyled}
        >
          <ButtonIconStyled
            size="small"
            onClick={() => {
              openRightSideBarForMethod(viewRightSideBarEnum.addLocation);
            }}
          >
            <AddCircleOutline />
          </ButtonIconStyled>
        </Tooltip>
      </Header>
      <StyledBoxTable>
        {isLoading && <LoadingRowsComponent />}
        {!isLoading && (
          <DataTable
            value={locationsAndOrganizations}
            size="small"
            scrollHeight="flex"
            scrollable
            paginator={
              locationsAndOrganizations &&
              locationsAndOrganizations?.length >= 50
            }
            selectionPageOnly
            rows={50}
            rowsPerPageOptions={[
              5,
              10,
              25,
              locationsAndOrganizations ? locationsAndOrganizations.length : 50
            ]}
            tableStyle={{ fontSize: '14px' }}
            selection={[]}
            selectionMode="checkbox"
            onRowSelect={openDetailsAndSet}
          >
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column
              field=""
              header=""
              body={getIconColum}
              style={{ width: '35px' }}
              frozen
            />
            <Column field="name" header="Name" sortable body={getName} />
          </DataTable>
        )}
      </StyledBoxTable>
      <RightSideBar
        open={openRightSideBar}
        onMenuClose={() => {
          setOpenRightSideBar(false);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </Box>
  );
};
