/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';
import { InputText } from 'primereact/inputtext';
import { ArrowBackIos, Save } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { toast } from 'react-toastify';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { useAddSceneMutation } from '../../../../services/scenes/sceneService';
import { IAddScene } from '../../../../types/Scenes/scenesTypes';
import { Header } from '../../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader,
  styledColumnHeaderCheckboxWithLabel
} from '../../../../components/layout/main/mainStyles';
import { ButtonIconStyled } from '../../../../views/devices/devicesStyles';
import Strings from '../../../../i18n';
import { typeSceneEnum } from '../../../../constants/sceneEnums';
import {
  ContainerTable,
  ItemGridAddScene,
  StyledTitleLabelAddScene
} from './addSceneStyle';
import { useGetLightsZonesByLocationMutation } from '../../../../services/lightsZones/lightsZonesService';
import { SwitchOnOff } from '../../../../components/commons/SwitchOnOff/switchOnOff';
import { BrightnessSelectorBtn } from '../../../../components/commons/BrightnessSelector/brightnessSelector';
import { ColorSelector } from '../../../../components/commons/ColorSelector/ColorSelector';
import { LightCapabilitiesEnum } from '../../../../constants/commonEnums';
import { ILightZone } from '../../../../types/Lights/lightZoneInterfaces';
import {
  useSendColorCommandMutation,
  useSendBrightnessCommandMutation,
  useSendOnOffCommandMutation
} from '../../../../services/commandsService/commandsService';

export const AddSceneTemplate = () => {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const [ignoredLights, setIgnoredLights] = useState<any[]>([]);
  const [sceneName, setSceneName] = useState<string>('');
  const [lightZonesList, setLightZonesList] = useState<ILightZone[]>([]);
  const optionsTypeScene = [typeSceneEnum.static, typeSceneEnum.playground];
  const [selectedTypeScene, setselectedTypeScene] = useState(
    typeSceneEnum.static
  );

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');
  const [sendCommandsToControllers, setSendCommandsToControllers] =
    useState<boolean>(type === Strings.scenes.live);

  const [postScene, { isSuccess }] = useAddSceneMutation();

  const [
    getLightsZonesByLocation,
    { isSuccess: isSuccessLightZones, data: dataLightsZones }
  ] = useGetLightsZonesByLocationMutation();

  const [setColor, { isSuccess: isSuccessSetColor }] =
    useSendColorCommandMutation();

  const [setBrightness, { isSuccess: isSuccessSetBrightness }] =
    useSendBrightnessCommandMutation();

  const [setSwitch, { isSuccess: isSuccessSetSwitch }] =
    useSendOnOffCommandMutation();

  const postNewScene = () => {
    const payload: IAddScene = {
      locationId: locationId ? +locationId : 0,
      name: sceneName,
      sceneType: selectedTypeScene,
      lightZoneActions: lightZonesList
        .filter((lz) => !ignoredLights.some((il) => il.id === lz.id))
        .map((lz) => ({
          id: lz.id,
          colorId: lz.colorId,
          lightBrightnessId: lz.lightBrightnessId,
          patternSpeed: lz.patternSpeed,
          lightingStatusId: lz.lightingStatusId,
          isZone: lz.isZone
        }))
    };
    postScene(payload);
  };

  const postOnFake = () => {
    const payload = {
      name: 'OffEvent 2',
      dateType: 1,
      weekDays: 127,
      timeType: 1,
      eventTime: '00:00',
      hour: 0,
      minute: 0,
      offsetMinutes: 0,
      sceneId: 0,
      startMonth: 1,
      startDay: 1,
      endMonth: 12,
      endDay: 31,
      holidayStartIndex: 0,
      holidayStartOffset: 0,
      holidayEndIndex: 0,
      holidayEndOffset: 0,
      locationId: 15147,
      daysOfWeek: 1111111,
      expiring: false,
      eventType: 1,
      lightingStatusId: 1,
      lightIds: [314]
    };
  };

  const setColorCommand = (info: {
    id: number;
    colorId: number;
    type: string;
  }) => {
    setLightZonesList((prevList) =>
      prevList.map((lz) =>
        lz.id === info.id ? { ...lz, colorId: info.colorId } : lz
      )
    );
    if (sendCommandsToControllers) {
      setColor(info);
    }
  };

  const setSwitchCommand = (info: {
    id: number;
    method: string;
    type: string;
  }) => {
    setLightZonesList((prevList) =>
      prevList.map((lz) =>
        lz.id === info.id
          ? { ...lz, lightingStatusId: info.method === 'On' ? 2 : 1 }
          : lz
      )
    );
    if (sendCommandsToControllers) {
      setSwitch(info);
    }
  };

  const setBrightnessCommand = (info: {
    brightness: number;
    id: number;
    type: string;
  }) => {
    setLightZonesList((prevList) =>
      prevList.map((lz) =>
        lz.id === info.id ? { ...lz, lightBrightnessId: info.brightness } : lz
      )
    );
    if (sendCommandsToControllers) {
      setBrightness(info);
    }
  };

  const getLastUpdated = (item: ILightZone) => {
    return moment(item.lastUpdated).format('YYYY MM DD - HH:mm:ss');
  };

  const getCommands = (item: ILightZone) => {
    return (
      <div className="d-flex align-items-center my-1">
        <SwitchOnOff
          lightingStatusId={item.lightingStatusId}
          onSwitch={(value) =>
            setSwitchCommand({
              id: item.id,
              method: value ? 'On' : 'Off',
              type: item.isZone ? 'Zone' : 'Light'
            })
          }
        />
        <BrightnessSelectorBtn
          lightBrightnessId={item.lightBrightnessId}
          onChange={(brightnessId) =>
            setBrightnessCommand({
              brightness: brightnessId,
              id: item.id,
              type: item.isZone ? 'Zone' : 'Light'
            })
          }
        />
        <ColorSelector
          status={{
            colorId: item.colorId,
            patternSpeed: item.patternSpeed,
            sparkleDuration: item.sparkleDuration
          }}
          capabilities={[{ name: LightCapabilitiesEnum.EXTENDED_SOLID_COLORS }]}
          onColorChange={(colorId) => {
            setColorCommand({
              id: item.id,
              colorId,
              type: item.isZone ? 'Zone' : 'Light'
            });
          }}
        />
      </div>
    );
  };

  const getHeaderCheckbox = () => {
    return (
      <div className="d-flex align-items-center">
        <Checkbox
          className="p-checkbox"
          style={{
            visibility: 'visible',
            margin: '0 auto'
          }}
          checked={ignoredLights.length === lightZonesList.length}
          onChange={(e) =>
            setIgnoredLights(ignoredLights.length ? [] : lightZonesList)
          }
          inputId="ignoredLights"
        />
        <label
          htmlFor="ignoredLights"
          style={{ visibility: 'visible', marginLeft: '5px', marginBottom: 0 }}
        >
          Ignored
        </label>
      </div>
    );
  };

  useEffect(() => {
    if (locationId) {
      getLightsZonesByLocation(+locationId);
    }
  }, [locationId, getLightsZonesByLocation]);

  useEffect(() => {
    if (isSuccessLightZones && dataLightsZones) {
      setLightZonesList(dataLightsZones);
    }
  }, [isSuccessLightZones, dataLightsZones]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.scenes.sceneCreatedSuccess);
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessSetColor || isSuccessSetBrightness || isSuccessSetSwitch) {
      toast.success(Strings.commands.commandSentSuccessfully);
    }
  }, [isSuccessSetColor, isSuccessSetBrightness, isSuccessSetSwitch]);

  return (
    <div>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.scenes.addSceneTitle}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <ButtonIconStyled
                size="small"
                onClick={() => {
                  postNewScene();
                }}
                disabled={
                  !sceneName || ignoredLights.length === lightZonesList.length
                }
              >
                <Save />
              </ButtonIconStyled>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <Grid container>
        <ItemGridAddScene item xs={12}>
          <StyledTitleLabelAddScene>
            {Strings.scenes.sceneNameLabel}
          </StyledTitleLabelAddScene>
          <InputText
            className="w-100"
            value={sceneName}
            onChange={(e) => setSceneName(e.target.value)}
            placeholder={Strings.scenes.sceneNamePlaceholder}
          />
        </ItemGridAddScene>
        <ItemGridAddScene item xs={12}>
          <StyledTitleLabelAddScene>
            {Strings.scenes.typeSceneLabel}
          </StyledTitleLabelAddScene>
          <SelectButton
            value={selectedTypeScene}
            onChange={(e) => setselectedTypeScene(e.value)}
            options={optionsTypeScene}
          />
        </ItemGridAddScene>
        <ItemGridAddScene item xs={12}>
          <StyledTitleLabelAddScene className="text-center">
            {Strings.lightsZones.lightsZones}
          </StyledTitleLabelAddScene>
          {lightZonesList && (
            <ContainerTable component="main">
              <DataTable
                selection={ignoredLights}
                selectionMode="checkbox"
                onSelectionChange={(e) => setIgnoredLights(e.value)}
                className="mt-3"
                rowHover
                scrollHeight="flex"
                value={lightZonesList}
                size="small"
                scrollable
                paginator={lightZonesList.length >= 50}
                selectionPageOnly
                tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
              >
                <Column
                  selectionMode="multiple"
                  header={getHeaderCheckbox}
                  headerStyle={styledColumnHeaderCheckboxWithLabel}
                />
                <Column field="name" header="Name" sortable />
                <Column
                  field="lastUpdated"
                  header="Last Updated"
                  body={getLastUpdated}
                  sortable
                />
                <Column
                  field=""
                  header="Commands"
                  sortable
                  body={getCommands}
                />
              </DataTable>
            </ContainerTable>
          )}
        </ItemGridAddScene>
      </Grid>
    </div>
  );
};
