import { Stack, styled, Grid, Divider } from '@mui/material';

interface topColor {
  backgroundColor: 'green' | 'red';
}

export const ListContainer = styled(Stack)(({ theme }) => ({
  padding: '10px'
}));

export const GridItem = styled(Grid)({
  margin: '25px',
  border: '1px solid #e0e0e0'
});

export const TopGrid = styled(Grid)<topColor>(({ theme, backgroundColor }) => ({
  padding: '10px',
  backgroundColor,
  borderRadius: '5px 5px 0 0',
  color: 'white',
  fontSize: '1rem'
}));

export const MiddleGrid = styled(Grid)(({ theme }) => ({
  padding: '10px',
  backgroundColor: 'white'
}));

export const BottomGrid = styled(Grid)(({ theme }) => ({
  padding: '10px',
  backgroundColor: 'white',
  marginTop: '-10px'
}));

export const FullWidthStack = styled(Stack)({
  width: '100%',
  alignItems: 'center'
});

export const CustomDivider = styled(Divider)({
  margin: '10px 0 10px 0'
});
