import { Box, TextField } from '@mui/material';
import styled from 'styled-components';

export const TableContainer = styled(Box)(({ theme }) => ({
  padding: '10px'
}));

export const NoDataContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px'
}));

export const HeaderContainer = styled(Box)({
  display: 'flex'
});

export const TitleTableContainer = styled(Box)({
  width: '100%'
});

export const IconContainer = styled(Box)(({ theme }) => ({
  '@media (max-width: 320px)': {
    display: 'none'
  }
}));

export const ValueTableContainer = styled(Box)(({ theme }) => ({
  '@media (max-width: 320px)': {
    width: ' 100%',
    overflowWrap: 'break-word'
  }
}));

export const FilterTableInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginBottom: '10px',
  boxShadow: '2px 2px 2px 1px rgb(64 60 67 / 16%)'
}));
