import React, { useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { toast } from 'react-toastify';
import {
  useDeleteRatingEffectMutation,
  useGetEffectRatingsMutation
} from '../../../../services/effects/effectsStoreService';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { IEffectItemStore } from '../../../../types/effects/effectsStoreInterface';
import Strings from '../../../../i18n';
import { DeleteButtonWithoutText } from '../../../../components/commons/DeleteButtonWithoutText/deleteButtonWithoutText';
import { IEffectRate } from '../../../../types/effects/effectsInterface';
import { StyledRatingSmall } from '../effectStoreItem/effectStoreItemStyles';
import { ContainerTable } from '../../../locations/locationsList/locationListStyles';

interface ViewRatesProps {
  effect: IEffectItemStore;
  closeSideBar: () => void;
}
export const ViewRatesSideBar = ({ effect, closeSideBar }: ViewRatesProps) => {
  const [getRates, { isSuccess, data: response }] =
    useGetEffectRatingsMutation();

  const [deleteRateEffect, { isSuccess: successDeleteRateEffect }] =
    useDeleteRatingEffectMutation();

  useEffect(() => {
    if (effect) getRates(effect.id);
  }, []);

  const deleteRate = (id: number) => {
    deleteRateEffect({ effectId: effect.id, userId: id });
  };

  const getActions = (rowData: IEffectRate) => {
    return (
      <DeleteButtonWithoutText
        onDelete={() => deleteRate(rowData.userId)}
        id={rowData.effectId}
        textConfirmation="Are you sure you want to delete this rate?"
        titleConfirmation="Delete Rate"
      />
    );
  };
  const getStars = (rowData: IEffectRate) => {
    return <StyledRatingSmall value={rowData.rating} cancel={false} readOnly />;
  };

  useEffect(() => {
    if (successDeleteRateEffect) {
      toast.success(Strings.effects.successDeleteRate);
      getRates(effect.id);
    }
  }, [successDeleteRateEffect]);

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>{effect?.name}</StyledTitleSideBar>

        <ContainerTable component="main">
          {isSuccess && response && response.data.length > 0 && (
            <DataTable
              value={response.data}
              size="small"
              scrollable
              scrollHeight="flex"
              paginator={response.data && response.data.length >= 50}
              selectionPageOnly
              rows={10}
              rowsPerPageOptions={[
                5,
                10,
                25,
                response.data ? response.data.length : 50
              ]}
              tableStyle={{ fontSize: '14px' }}
            >
              <Column field="userName" header="User Name" sortable />
              <Column field="rating" header="Rate" sortable body={getStars} />
              <Column field="" header="Action" body={getActions} />
            </DataTable>
          )}{' '}
        </ContainerTable>
      </StyledContainerItems>
      <StyledContainerFooterSideBar>
        <button
          type="button"
          className="btn-cancel"
          onClick={() => closeSideBar()}
        >
          {Strings.common.cancel}
        </button>
      </StyledContainerFooterSideBar>
    </>
  );
};
