import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Typography, Grid, Paper, Box, Skeleton } from '@mui/material';
import moment from 'moment';
import { Chart } from 'primereact/chart';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../../store/slices/login/login';
import { MyActivityComponent } from '../../components/dashboard/myActivity/myActivity';
import { CustomerActivityFeedComponent } from '../../components/dashboard/customerActivityFeed/customerActivityFeed';
import { FlaggedLocationsComponent } from '../../components/dashboard/flaggedLocations/flaggedLocations';
import { useGetDealerDeviceStateCountMutation } from '../../services/device/deviceService';
import { useGetDeviceAndLocationStatusCountMutation } from '../../services/dashboardService/dashboardService';
import { colors } from '../../constants/colors';
import {
  PieChartOrder,
  PieChartStatus
} from '../../constants/enums/dashboardEnums';
import {
  StyledButtonName,
  StyledLocationName
} from '../../templates/locations/locationsList/locationListStyles';

export const DashboardView = () => {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [deviceData, setDeviceData] = useState({});
  const [locationData, setLocationData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalLocation, setShowModalLocation] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalDataLocation, setModalDataLocation] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const user = useSelector(selectCurrentUser);
  const [totalDevices, setTotalDevices] = useState(0);
  const [
    getDealerDeviceStateCount,
    { data: dealerDeviceStateCount, isSuccess: isSuccessDealerDeviceStateCount }
  ] = useGetDealerDeviceStateCountMutation();

  const [
    getDeviceAndLocationStatusCount,
    {
      data: deviceAndLocationStatusCount,
      isSuccess: isSuccessDeviceAndLocationStatusCount
    }
  ] = useGetDeviceAndLocationStatusCountMutation();

  useEffect(() => {
    getDealerDeviceStateCount();
    getDeviceAndLocationStatusCount();
  }, []);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    if (isSuccessDealerDeviceStateCount && dealerDeviceStateCount) {
      const data = {
        labels: dealerDeviceStateCount.map((item) =>
          moment(item.date).format('MMM DD')
        ),
        datasets: [
          {
            label: 'Devices Online',
            data: dealerDeviceStateCount.map(
              (item) => item.devicesOnlineCount ?? 0
            ),
            fill: false,
            borderColor: '#4CAF50',
            backgroundColor: '#4CAF50',
            tension: 0.4,
            pointRadius: 5,
            pointHoverRadius: 8,
            pointBorderColor: '#fff',
            pointHoverBorderColor: '#fff'
          },
          {
            label: 'Devices Offline',
            data: dealerDeviceStateCount.map(
              (item) => item.devicesOfflineCount
            ),
            fill: false,
            borderColor: '#F44336',
            backgroundColor: '#F44336',
            tension: 0.4,
            pointRadius: 5,
            pointHoverRadius: 8,
            pointBorderColor: '#fff',
            pointHoverBorderColor: '#fff'
          }
        ]
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: textColor
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              color: surfaceBorder
            }
          },
          y: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              color: surfaceBorder
            },
            beginAtZero: true
          }
        }
      };

      setChartData(data);
      setChartOptions(options);
      const lastEntry =
        dealerDeviceStateCount[dealerDeviceStateCount.length - 1];
      setTotalDevices(
        (lastEntry?.devicesOnlineCount ?? 0) +
          (lastEntry?.devicesOfflineCount ?? 0)
      );
    }
  }, [isSuccessDealerDeviceStateCount, dealerDeviceStateCount]);

  useEffect(() => {
    if (isSuccessDeviceAndLocationStatusCount && deviceAndLocationStatusCount) {
      const data = {
        labels: [
          'Online > 15 Minutes',
          'Online < 15 Minutes',
          'Offline > 15 Minutes',
          'Offline < 15 Minutes'
        ],

        datasets: [
          {
            data: [
              deviceAndLocationStatusCount.devices.onlineMoreThan15,
              deviceAndLocationStatusCount.devices.onlineLessThan15,
              deviceAndLocationStatusCount.devices.offlineMoreThan15,
              deviceAndLocationStatusCount.devices.offlineLessThan15
            ],
            backgroundColor: [
              colors.green,
              colors.yellow,
              colors.red,
              colors.orange
            ]
          }
        ]
      };

      setDeviceData(data);
      const locationDataTemp = {
        labels: [
          'Online > 15 Minutes',
          'Online < 15 Minutes',
          'Offline > 15 Minutes',
          'Offline < 15 Minutes'
        ],
        datasets: [
          {
            data: [
              deviceAndLocationStatusCount.locations.onlineMoreThan15,
              deviceAndLocationStatusCount.locations.onlineLessThan15,
              deviceAndLocationStatusCount.locations.offlineMoreThan15,
              deviceAndLocationStatusCount.locations.offlineLessThan15
            ],
            backgroundColor: [
              colors.green,
              colors.yellow,
              colors.red,
              colors.orange
            ]
          }
        ]
      };

      setLocationData(locationDataTemp);
    }
  }, [isSuccessDeviceAndLocationStatusCount, deviceAndLocationStatusCount]);

  const handleChartClickDevices = (
    event: React.MouseEvent<HTMLCanvasElement>,
    elements: any
  ) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index;
      let filteredDevices: any[] = [];
      let title = '';

      switch (dataIndex) {
        case PieChartOrder.onlineMoreThan15:
          filteredDevices =
            deviceAndLocationStatusCount?.devicesDetails?.filter(
              (d) => d.connectionStatus === PieChartStatus.onlineMoreThan15
            ) ?? [];
          title = 'Online Devices';
          break;
        case PieChartOrder.onlineLessThan15:
          filteredDevices =
            deviceAndLocationStatusCount?.devicesDetails?.filter(
              (d) => d.connectionStatus === PieChartStatus.onlineLessThan15
            ) ?? [];
          title = 'Devices Online < 15 Minutes';
          break;
        case PieChartOrder.offlineMoreThan15:
          filteredDevices =
            deviceAndLocationStatusCount?.devicesDetails?.filter(
              (d) => d.connectionStatus === PieChartStatus.offlineMoreThan15
            ) ?? [];
          title = 'Devices Offline > 15 Minutes';
          break;
        case PieChartOrder.offlineLessThan15:
          filteredDevices =
            deviceAndLocationStatusCount?.devicesDetails?.filter(
              (d) => d.connectionStatus === PieChartStatus.offlineLessThan15
            ) ?? [];
          title = 'Devices Offline < 15 Minutes';
          break;
        default:
          filteredDevices = [];
          title = 'Unknown Status';
          break;
      }

      setModalData(filteredDevices as never[]);
      setModalTitle(title);
      setShowModal(true);
    }
  };

  const handleChartClickLocations = (
    event: React.MouseEvent<HTMLCanvasElement>,
    elements: any
  ) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index;
      let filteredLocations: any[] = [];
      let title = '';

      switch (dataIndex) {
        case PieChartOrder.onlineMoreThan15:
          filteredLocations =
            deviceAndLocationStatusCount?.locationsDetails?.filter(
              (l) => l.connectionStatus === PieChartStatus.onlineMoreThan15
            ) ?? [];
          title = 'Online Locations';
          break;
        case PieChartOrder.onlineLessThan15:
          filteredLocations =
            deviceAndLocationStatusCount?.locationsDetails?.filter(
              (l) => l.connectionStatus === PieChartStatus.onlineLessThan15
            ) ?? [];
          title = 'Locations Online < 15 Minutes';
          break;
        case PieChartOrder.offlineMoreThan15:
          filteredLocations =
            deviceAndLocationStatusCount?.locationsDetails?.filter(
              (l) => l.connectionStatus === PieChartStatus.offlineMoreThan15
            ) ?? [];
          title = 'Locations Offline > 15 Minutes';
          break;
        case PieChartOrder.offlineLessThan15:
          filteredLocations =
            deviceAndLocationStatusCount?.locationsDetails?.filter(
              (l) => l.connectionStatus === PieChartStatus.offlineLessThan15
            ) ?? [];
          title = 'Locations Offline < 15 Minutes';
          break;
        default:
          filteredLocations = [];
          title = 'Unknown Status';
          break;
      }

      setModalDataLocation(filteredLocations as never[]);
      setModalTitle(title);
      setShowModalLocation(true);
    }
  };

  const memoizedDeviceData = useMemo(() => deviceData, [deviceData]);
  const memoizedLocationData = useMemo(() => locationData, [locationData]);

  const memoizedDoughnutOptions = useMemo(
    () => ({
      cutout: '70%',
      plugins: {
        legend: {
          display: false
        }
      },
      onClick: handleChartClickDevices
    }),
    [deviceAndLocationStatusCount?.devicesDetails]
  );

  const memoizedDoughnutOptionsLocation = useMemo(
    () => ({
      cutout: '70%',
      plugins: {
        legend: {
          display: false
        }
      },
      onClick: handleChartClickLocations
    }),
    [deviceAndLocationStatusCount?.locationsDetails]
  );

  const MemoizedChart = useCallback(React.memo(Chart), []);

  const deviceBtn = (item: { deviceId: string }) => {
    return (
      <StyledLocationName>
        <StyledButtonName
          onClick={() =>
            navigate(`/server-access/devices?search=${item.deviceId}`)
          }
        >
          {item.deviceId}
        </StyledButtonName>
      </StyledLocationName>
    );
  };

  const locationBtn = (item: {
    locationId: string;
    name: string;
    locationName: string;
  }) => {
    return (
      <StyledLocationName>
        <StyledButtonName
          onClick={() => navigate(`/locations/${item.locationId}/devices`)}
        >
          {item.name ?? item.locationName}
        </StyledButtonName>
      </StyledLocationName>
    );
  };

  const userBtn = (item: { ownerId: string; ownerName: string }) => {
    return (
      <StyledLocationName>
        <StyledButtonName
          onClick={() => navigate(`/users/?searchUser=${item.ownerId}`)}
        >
          {item.ownerName}
        </StyledButtonName>
      </StyledLocationName>
    );
  };
  const organizationBtn = (item: {
    organizationName: string;
    organizationId: string;
  }) => {
    return item.organizationName && item.organizationId ? (
      <StyledLocationName>
        <StyledButtonName
          onClick={() => navigate(`/Organizations/${item.organizationId}`)}
        >
          {item.organizationName}
        </StyledButtonName>
      </StyledLocationName>
    ) : (
      '-'
    );
  };
  return (
    <Box sx={{ height: '100%', p: 3, overflow: 'auto' }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        {user ? `Welcome, ${user.userInfo?.firstName}!` : 'Welcome!'}
      </Typography>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Current Devices: {totalDevices}
            </Typography>
            <Chart
              type="line"
              data={chartData}
              options={chartOptions}
              style={{ height: '300px', width: '100%' }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-around'
            }}
          >
            <Box>
              {isSuccessDeviceAndLocationStatusCount &&
              deviceAndLocationStatusCount?.devicesDetails ? (
                <>
                  <MemoizedChart
                    type="doughnut"
                    data={memoizedDeviceData}
                    options={memoizedDoughnutOptions}
                    style={{ width: '150px' }}
                  />

                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {(deviceAndLocationStatusCount?.devices.onlineMoreThan15 ??
                      0) +
                      (deviceAndLocationStatusCount?.devices.onlineLessThan15 ??
                        0) +
                      (deviceAndLocationStatusCount?.devices
                        .offlineMoreThan15 ?? 0) +
                      (deviceAndLocationStatusCount?.devices
                        .offlineLessThan15 ?? 0)}
                  </Typography>
                  <Typography variant="subtitle1">Devices</Typography>
                </>
              ) : (
                <Skeleton variant="rectangular" width={150} height={150} />
              )}
            </Box>
            <Box>
              {isSuccessDeviceAndLocationStatusCount &&
              deviceAndLocationStatusCount?.locationsDetails ? (
                <>
                  <MemoizedChart
                    type="doughnut"
                    data={memoizedLocationData}
                    options={memoizedDoughnutOptionsLocation}
                    style={{ width: '150px' }}
                  />
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {(deviceAndLocationStatusCount?.locations
                      .onlineMoreThan15 ?? 0) +
                      (deviceAndLocationStatusCount?.locations
                        .onlineLessThan15 ?? 0) +
                      (deviceAndLocationStatusCount?.locations
                        .offlineMoreThan15 ?? 0) +
                      (deviceAndLocationStatusCount?.locations
                        .offlineLessThan15 ?? 0)}
                  </Typography>
                  <Typography variant="subtitle1">Locations</Typography>
                </>
              ) : (
                <Skeleton variant="rectangular" width={150} height={150} />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid container marginTop={2} spacing={2}>
        <Grid item xs={12} md={4}>
          <MyActivityComponent />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomerActivityFeedComponent />
        </Grid>
        <Grid item xs={12} md={4}>
          <FlaggedLocationsComponent />
        </Grid>
      </Grid>
      <Dialog
        header={modalTitle}
        visible={showModal}
        style={{ width: '65vw' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        onHide={() => setShowModal(false)}
      >
        <DataTable
          value={modalData}
          paginator={modalData.length > 10}
          rows={10}
        >
          <Column field="deviceId" header="Device ID" body={deviceBtn} />
          <Column field="firmwareVersion" header="Firmware Version" />
          <Column field="locationName" header="Location" body={locationBtn} />
        </DataTable>
      </Dialog>
      <Dialog
        header={modalTitle}
        visible={showModalLocation}
        modal
        style={{ width: '65vw' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        onHide={() => setShowModalLocation(false)}
      >
        <DataTable
          value={modalDataLocation}
          paginator={modalDataLocation.length > 10}
          rows={10}
        >
          <Column field="name" header="Location" body={locationBtn} />
          <Column field="address" header="Address" />
          <Column field="ownerName" header="Owner Name" body={userBtn} />
          <Column
            field="organizationName"
            header="Organization"
            body={organizationBtn}
          />
        </DataTable>
      </Dialog>
    </Box>
  );
};
