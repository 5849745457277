import React from 'react';
import { Modal, Typography, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  ModalContainerStyled,
  ButtonsContainerCenter,
  StyledButtons
} from './basicModalStyle';
import { BasicModalProps } from './basicModalProps';
import Strings from '../../../i18n';

export const BasicModal: React.FunctionComponent<BasicModalProps> = ({
  open,
  handleClose,
  children,
  isLoading,
  onClick,
  title,
  height,
  width,
  disabledButton,
  buttonText,
  buttonIcon
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainerStyled height={height} width={width}>
        <Typography variant="h5">{title}</Typography>
        <Divider />
        {children}
        <ButtonsContainerCenter>
          <StyledButtons
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={handleClose}
          >
            {Strings.common.close}
          </StyledButtons>
          <LoadingButton
            disabled={disabledButton}
            loading={isLoading}
            loadingPosition="start"
            startIcon={buttonIcon}
            variant="contained"
            onClick={onClick}
          >
            {buttonText}
          </LoadingButton>
        </ButtonsContainerCenter>
      </ModalContainerStyled>
    </Modal>
  );
};
