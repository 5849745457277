import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable, DataTableSelectEvent } from 'primereact/datatable';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { toast } from 'react-toastify';
import { TrimLightsListProps } from './trimLightsProps';
import { useGetTrimLightsByLocationMutation } from '../../../../services/trimLights/trimLightsService';
import { ITrimLight } from '../../../../types/Lights/trimLightInterfaces';
import { SwitchOnOff } from '../../../../components/commons/SwitchOnOff/switchOnOff';
import { BrightnessSelectorBtn } from '../../../../components/commons/BrightnessSelector/brightnessSelector';
import { ColorSelector } from '../../../../components/commons/ColorSelector/ColorSelector';
import { LightCapabilitiesEnum } from '../../../../constants/commonEnums';
import Strings from '../../../../i18n';
import {
  useSendColorCommandMutation,
  useSendBrightnessCommandMutation,
  useSendOnOffCommandMutation
} from '../../../../services/commandsService/commandsService';
import {
  ICommandBrightness,
  ICommandSetColor,
  ICommandOnOff
} from '../../../../types/commandsInterface';
import { ContainerTable } from '../../scenes/addScene/addSceneStyle';

export const TrimLightsList = ({
  selectionModel,
  setSelectionModel,
  searchValue
}: TrimLightsListProps) => {
  const [rowIndex, setRowIndex] = useState(-1);

  const { locationId } = useParams();

  const onRowSelect = (event: DataTableSelectEvent) => {};

  const [setColor, { isSuccess: isSuccessSetColor }] =
    useSendColorCommandMutation();

  const [setBrightness, { isSuccess: isSuccessSetBrightness }] =
    useSendBrightnessCommandMutation();

  const [setSwitch, { isSuccess: isSuccessSetSwitch }] =
    useSendOnOffCommandMutation();

  const [getTrimLightsByLocation, { isSuccess, data }] =
    useGetTrimLightsByLocationMutation();
  useEffect(() => {
    if (locationId) {
      getTrimLightsByLocation(+locationId);
    }
  }, []);

  const filteredData = data?.filter((item: ITrimLight) => {
    const searchValueLowerCase = searchValue.toLowerCase();
    let includesSearchValue = false;
    includesSearchValue = item.name
      .toLowerCase()
      .includes(searchValueLowerCase);

    return includesSearchValue;
  });

  const getCheckbox = (item: any, options: any) => {
    return (
      <Checkbox
        onClick={() => {
          if (selectionModel.length) {
            setSelectionModel([]);
          } else {
            setSelectionModel([item]);
          }
        }}
        checked={selectionModel.includes(item)}
        style={{
          visibility: rowIndex === options.rowIndex ? 'visible' : 'hidden'
        }}
      />
    );
  };

  const getLastUpdated = (item: ITrimLight) => {
    return moment(item.lastUpdated).format('YYYY MM DD - HH:mm:ss');
  };

  const sendBrightnessCommand = (payload: ICommandBrightness) => {
    setBrightness(payload);
  };

  const sendColorCommand = (payload: ICommandSetColor) => {
    setColor(payload);
  };

  const sendSwitchCommand = (payload: ICommandOnOff) => {
    setSwitch(payload);
  };

  const getCommands = (item: ITrimLight) => {
    return (
      <div className="d-flex align-items-center my-1">
        <SwitchOnOff
          lightingStatusId={item.lightingStatusId ?? 0}
          onSwitch={(value) =>
            sendSwitchCommand({
              id: item.id,
              method: value ? 'On' : 'Off',
              type: 'Light'
            })
          }
        />
        <BrightnessSelectorBtn
          lightBrightnessId={item.lightBrightnessId}
          onChange={(brightnessId) =>
            sendBrightnessCommand({
              brightness: brightnessId,
              id: item.id,
              type: 'Light'
            })
          }
        />
        <ColorSelector
          status={{
            colorId: item.colorId,
            patternSpeed: item.patternSpeed,
            sparkleDuration: item.sparkleDuration
          }}
          capabilities={[{ name: LightCapabilitiesEnum.EXTENDED_SOLID_COLORS }]}
          onColorChange={(colorId) => {
            sendColorCommand({
              id: item.id,
              colorId,
              type: 'Light'
            });
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (isSuccessSetColor) {
      toast.success(Strings.trimLights.setColorSuccess);
    }
  }, [isSuccessSetColor]);

  useEffect(() => {
    if (isSuccessSetBrightness) {
      toast.success(Strings.commands.setBrightnessSuccess);
    }
  }, [isSuccessSetBrightness]);

  useEffect(() => {
    if (isSuccessSetSwitch) {
      toast.success(Strings.commands.commandSentSuccessfully);
    }
  }, [isSuccessSetSwitch]);

  return (
    <ContainerTable component="main">
      <DataTable
        onRowMouseEnter={(e) => {
          setRowIndex(e.index);
        }}
        onRowMouseLeave={() => {
          setRowIndex(-1);
        }}
        rowHover
        value={filteredData}
        scrollHeight="flex"
        size="small"
        scrollable
        paginator={filteredData && filteredData.length >= 50}
        selectionPageOnly
        rows={50}
        rowsPerPageOptions={[
          5,
          10,
          25,
          filteredData ? filteredData.length : 50
        ]}
        tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
        onRowSelect={onRowSelect}
      >
        <Column body={getCheckbox} headerStyle={{ width: '3rem' }} />
        <Column field="name" header="Name" sortable />
        <Column
          field="lastUpdated"
          header="Last Updated"
          body={getLastUpdated}
          sortable
        />
        <Column field="" header="Commands" sortable body={getCommands} />
      </DataTable>
    </ContainerTable>
  );
};
