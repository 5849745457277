import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import { INoteItemList } from '../../types/notes/notesInterface';
import { severityEnum } from '../../constants/commonEnums';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiNotes = createApi({
  reducerPath: 'apiLocations',
  baseQuery: customFetch,
  endpoints: (builder) => ({
    postNote: builder.mutation<
      {},
      | {
          deviceId: number | string;
          text: string;
          type: string;
          severityLevel: severityEnum;
        }
      | {
          locationId: number;
          text: string;
          type: string;
          severityLevel: severityEnum;
        }
      | {
          userId: number;
          text: string;
          type: string;
          severityLevel: severityEnum;
        }
    >({
      query: (data) => ({
        url: `/api/Note/${data.type}`,
        method: 'POST',
        body: { ...data, text: data.text }
      })
    }),
    getNotes: builder.mutation<INoteItemList[], void>({
      query: () => ({
        url: `/api/Note`,
        method: 'GET'
      })
    }),
    editNote: builder.mutation<
      {},
      { id: number; text: string; type: string; severityLevel: severityEnum }
    >({
      query: (data) => ({
        url: `/api/Note/${data.type}/${data.id}`,
        method: 'PUT',
        body: {
          text: data.text,
          severityLevel: data.severityLevel
        }
      })
    }),
    deleteNote: builder.mutation<{}, { id: number; type: string }>({
      query: ({ id, type }) => ({
        url: `/api/Note/${type}/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  usePostNoteMutation,
  useGetNotesMutation,
  useEditNoteMutation,
  useDeleteNoteMutation
} = apiNotes;
