import { styled, Box, Grid } from '@mui/material';
import { colors } from '../../../constants/colors';

export const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute' as 'absolute',
  padding: '10px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '40%',
  backgroundColor: colors.white,
  borderRadius: 5,
  boxShadow: '24'
}));

export const StringContainer = styled(Grid)({
  overflow: 'hidden',
  wordBreak: 'break-all'
});
