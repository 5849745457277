import React, { useEffect, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { AddOrganizationBar } from '../addOrganizationBar/addOrganizationBar';
import { OrganizationTemplateProps } from './organizationsTemplateProps';
import Strings from '../../../i18n';
import { DeleteButton } from '../../../components/commons/DeleteButton/deleteButton';
import {
  styledCheckboxHeader,
  styledColumnHeaderCheckbox
} from '../../../components/layout/main/mainStyles';
import {
  useDeleteOrganizationMutation,
  useGetOrganizationsMutation
} from '../../../services/device/deviceService';
import { AssignLocationToOrg } from '../assignLocationToOrg/assignLocationToOrg';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { selectSystemOrganizationsReadWrite } from '../../../store/slices/systemPermissions/systemPermissions';
import { Organization } from '../../../types/organizations/organizations';
import { OrganizationDetails } from '../organizationDetails/organizationDetails';
import { MultipleOrganizationsDetails } from '../multipleOrganizationsDetails/multipleOrganizationsDetails';
import { ContainerTable } from '../../locations/locationsList/locationListStyles';

export const OrganizationsTemplate = ({
  sideBarOpenFor,
  openRightSideBarForMethod,
  openSideBar,
  setOpenSideBar,
  selectedOrganizations,
  setSelectedOrganizations
}: OrganizationTemplateProps) => {
  const [getOrganization, { isSuccess: isSuccessGetOrganizations, data }] =
    useGetOrganizationsMutation();
  const [organizations, setOrganizations] = useState<Organization[] | null>([]);
  const [newOrgId, setNewOrgId] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [idOrganization, setIdOrganization] = useState(-1);
  const permissionReadWrite = useSelector(selectSystemOrganizationsReadWrite);

  const [deleteOrganization, { isSuccess }] = useDeleteOrganizationMutation();
  useEffect(() => {
    getOrganization('');
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.organizations.successDeletedOrganization);
      getOrganization('');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessGetOrganizations && data) {
      if (newOrgId) {
        const index = data.findIndex((org) => org.organizationId === newOrgId);
        if (index !== -1) {
          const newData = [...data];
          const [orgToMove] = newData.splice(index, 1);
          newData.unshift(orgToMove);

          setOrganizations(newData);
          setNewOrgId(null);
        } else {
          setOrganizations(data);
        }
      } else {
        setOrganizations(data);
      }
    }
  }, [isSuccessGetOrganizations]);

  const reloadTable = () => {
    setOrganizations(null);
    getOrganization('');
    setSelectedOrganizations([]);
    setOpenSideBar(false);
  };
  const switchComponentToShowRightSideBar = () => {
    switch (sideBarOpenFor) {
      case viewRightSideBarEnum.addOrganization:
        return (
          <AddOrganizationBar
            reloadTable={() => reloadTable()}
            setNewOrgId={setNewOrgId}
          />
        );
      case viewRightSideBarEnum.assignLocationToOrg:
        return (
          <AssignLocationToOrg
            reloadTable={() => reloadTable()}
            idOrganization={selectedOrganizations[0]?.organizationId}
          />
        );
      case viewRightSideBarEnum.organizationDetails:
        return (
          <OrganizationDetails
            reloadTable={() => reloadTable()}
            idOrganization={selectedOrganizations[0]?.organizationId}
            openRightSideBarForMethod={openRightSideBarForMethod}
          />
        );
      case viewRightSideBarEnum.multipleOrganizationsDetails:
        return (
          <MultipleOrganizationsDetails
            reloadTable={() => reloadTable()}
            idOrganizations={selectedOrganizations.map(
              (org) => org.organizationId
            )}
          />
        );
      default:
        return (
          <AddOrganizationBar
            reloadTable={() => reloadTable()}
            setNewOrgId={setNewOrgId}
          />
        );
    }
  };

  const filteredData = organizations?.filter((item) => {
    const searchValueLowerCase = searchValue.toLowerCase();

    let includesSearchValue = false;

    includesSearchValue = item.organizationName
      .toLowerCase()
      .includes(searchValueLowerCase);

    return includesSearchValue;
  });

  useEffect(() => {
    if (selectedOrganizations.length === 1) {
      openRightSideBarForMethod(viewRightSideBarEnum.organizationDetails);
    } else if (selectedOrganizations.length > 1) {
      openRightSideBarForMethod(
        viewRightSideBarEnum.multipleOrganizationsDetails
      );
    } else {
      openRightSideBarForMethod(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedOrganizations]);
  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedOrganizations.length ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={
          (filteredData &&
            selectedOrganizations &&
            selectedOrganizations.length > 0) ??
          false
        }
        onChange={() => {
          setSelectedOrganizations([]);
        }}
      />
    );
  };
  const getName = (item: { organizationName: string }) => {
    return <p className="my-2">{item.organizationName}</p>;
  };
  return (
    <ContainerTable component="main">
      <DataTable
        selection={selectedOrganizations}
        selectionMode="checkbox"
        onSelectionChange={(e) => setSelectedOrganizations(e.value)}
        className="mt-3"
        rowHover
        value={filteredData}
        size="small"
        scrollable
        scrollHeight="flex"
        paginator={filteredData && filteredData.length >= 50}
        selectionPageOnly
        rows={50}
        rowsPerPageOptions={[
          5,
          10,
          20,
          50,
          filteredData ? filteredData.length : 50
        ]}
        tableStyle={{ padding: 0, margin: 0, fontSize: '14px' }}
      >
        <Column
          selectionMode="multiple"
          headerStyle={styledColumnHeaderCheckbox}
          header={getHeaderCheckbox}
          frozen
        />
        <Column
          field="organizationName"
          header="Name"
          sortable
          body={getName}
        />
        <Column field="owner" header="Owner" sortable />
        <Column field="locationCount" header="Location Count" sortable />
        <Column field="userCount" header="Total Users" sortable />
        <Column field="status" header="Status" sortable />
      </DataTable>
      <RightSideBar
        open={openSideBar}
        onMenuClose={() => {
          setOpenSideBar(false);
        }}
      >
        {switchComponentToShowRightSideBar()}
      </RightSideBar>
    </ContainerTable>
  );
};
