import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EFFECTS_TYPES } from '../../constants/effectsEnums';
import { RippleConfiguration } from './rippleConfiguration/rippleConfiguration';

export const EffectsTemplate = () => {
  const navigate = useNavigate();
  const { effect, locationId } = useParams();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const type = searchParams.get('type');

  useEffect(() => {
    if (!effect || !id || !type) {
      navigate(`/locations/${locationId}/devices`);
    }
  }, []);

  const switchEffectConfiguration = () => {
    if (effect && id && type) {
      switch (effect.toUpperCase()) {
        case EFFECTS_TYPES.RIPPLE:
          return (
            <RippleConfiguration
              dataTypeAndId={{
                id: +id,
                type
              }}
            />
          );
        case 'effect2':
          return <h1>Effect2</h1>;
        default:
          return <h1>Effect3</h1>;
      }
    } else {
      return 'No effect selected';
    }
  };
  return <div>{switchEffectConfiguration()}</div>;
};
