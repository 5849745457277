import { styled, Box, Grid } from '@mui/material';
import { colors } from '../../../constants/colors';

export const PopUpContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  padding: '10px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  height: 'fit-content',
  maxWidth: '70%',
  maxHeight: '60%',
  overflowY: 'scroll',
  backgroundColor: colors.white,
  borderRadius: 5,
  boxShadow: '24',
  '@media (max-width: 768px)': {
    width: 'fit-content',
    height: 'fit-content'
  }
}));

export const CustomGrid = styled(Grid)({
  alignItems: 'end'
});
