import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { Box } from '@mui/system';
import { ArrowBackIos, People } from '@mui/icons-material';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Header } from '../../../components/header/header';
import {
  styledCheckboxHeader,
  styledColumnHeaderCheckbox
} from '../../../components/layout/main/mainStyles';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import {
  ButtonIconStyled,
  TitleStyled
} from '../usersInBusinessAccount/usersInBusinessAccountStyles';
import { useGetLocationsByBusinessAccountMutation } from '../../../services/businessAccount/businessAccountService';
import { colors } from '../../../constants/colors';
import { ILocationInBusinessAccount } from '../../../types/businessAccount/businessAccount';
import { selectCurrentUser } from '../../../store/slices/login/login';
import { LocationDetailsBusinessAccount } from './locationDetailsBusinessAccount/locationDetailsBusinessAccountSidebar';
import {
  ContainerTable,
  StyledLocationName
} from '../../locations/locationsList/locationListStyles';

export const BusinessAccountLocationList = () => {
  const navigate = useNavigate();
  const [openSideBar, setOpenSideBar] = React.useState(false);
  const [openSideFarFor, setOpenSidebarFor] = React.useState(
    viewRightSideBarEnum.empty
  );
  const { businessAccountId } = useParams();
  const contextUser = useSelector(selectCurrentUser);
  const [selectedLocations, setSelectedLocations] = React.useState<
    ILocationInBusinessAccount[]
  >([]);

  const [getLocationsByBusinessAccount, { data, isSuccess }] =
    useGetLocationsByBusinessAccountMutation();

  useEffect(() => {
    if (businessAccountId) {
      getLocationsByBusinessAccount(+businessAccountId);
    }
  }, []);
  const redirectToViewMembers = () => {
    navigate(`/business-account/${businessAccountId}/users`);
  };
  const reloadTable = () => {
    if (businessAccountId) {
      getLocationsByBusinessAccount(+businessAccountId);
      setOpenSideBar(false);
      setSelectedLocations([]);
    }
  };
  const getOwner = (item: ILocationInBusinessAccount) => {
    return item.isOwner
      ? `${contextUser.userInfo?.firstName} ${contextUser.userInfo?.lastName}`
      : item.owner;
  };
  const redirectToLocation = (locationId: number) => {
    navigate(`/locations/${locationId}/devices`);
  };
  const getName = (item: ILocationInBusinessAccount) => {
    return (
      <StyledLocationName
        onClick={() => {
          redirectToLocation(item.id);
        }}
        style={{ cursor: 'pointer' }}
      >
        {item.name}
      </StyledLocationName>
    );
  };

  const closeSideBar = () => {
    setOpenSideBar(false);
  };
  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedLocations.length ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={(selectedLocations && selectedLocations.length > 0) ?? false}
        onChange={() => {
          setSelectedLocations([]);
        }}
      />
    );
  };

  const switchComponentsToShowRightSideBar = () => {
    switch (openSideFarFor) {
      case viewRightSideBarEnum.locationDetailsBusinessAccount:
        return (
          <LocationDetailsBusinessAccount
            selectedBusinessAccountLocationIds={selectedLocations.map(
              (location) => location.businessAccountLocationId
            )}
            selectedIds={selectedLocations.map((location) => location.id)}
            closeSideBar={() => closeSideBar()}
            reloadTable={() => reloadTable()}
          />
        );
      default:
        return '';
    }
  };

  useEffect(() => {
    if (selectedLocations.length >= 1) {
      setOpenSidebarFor(viewRightSideBarEnum.locationDetailsBusinessAccount);
      setOpenSideBar(true);
    } else {
      setOpenSidebarFor(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedLocations]);

  return (
    <>
      <Header>
        <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
          <ArrowBackIos />
        </ButtonIconStyled>
        <TitleStyled
          variant="subtitle1"
          color={colors.strongGray}
          className="lh-small"
        >
          {data && data.name} <br />
          <span className="tag">{data && data.tag}</span>
        </TitleStyled>
        <Tooltip
          target=".view-members"
          position="left"
          style={{ fontSize: '10px' }}
        />
        <ButtonIconStyled
          className="view-members"
          data-pr-tooltip="View Members"
          size="small"
          onClick={() => {
            redirectToViewMembers();
          }}
        >
          <People />
        </ButtonIconStyled>
      </Header>
      <ContainerTable component="main">
        {data && (
          <DataTable
            selection={selectedLocations}
            selectionMode="checkbox"
            onSelectionChange={(e) => {
              setSelectedLocations(e.value);
            }}
            value={data.locations}
            size="small"
            scrollable
            scrollHeight="flex"
            paginator={data && data.locations.length >= 50}
            selectionPageOnly
            rows={10}
            rowsPerPageOptions={[5, 10, 25, data ? data.locations.length : 50]}
            tableStyle={{ fontSize: '14px' }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={styledColumnHeaderCheckbox}
              header={getHeaderCheckbox}
              frozen
            />
            <Column field="name" header="Name" sortable body={getName} />
            <Column field="owner" header="Owner" sortable body={getOwner} />
            <Column field="address" header="Address" sortable />
          </DataTable>
        )}
      </ContainerTable>
      <RightSideBar open={openSideBar} onMenuClose={() => closeSideBar()}>
        {switchComponentsToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
