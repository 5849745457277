import React, { useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PersonAdd } from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';
import { useGetBusinessAccountUsersMutation } from '../../../services/device/deviceService';
import { Header } from '../../../components/header/header';
import { ButtonIconStyled, TitleStyled } from './usersInBusinessAccountStyles';
import { colors } from '../../../constants/colors';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { AddUserToBusinessAccountBar } from '../addUserToBusinessAccountBar/addUserToBusinessAccountBar';
import { BusinessAccountUser } from '../../../types/businessAccount/businessAccount';
import { selectNameBusinessAccount } from '../../../store/slices/businessAccount/businessAccount';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import {
  styledCheckboxHeader,
  styledColumnHeaderCheckbox
} from '../../../components/layout/main/mainStyles';
import { UsersInBusinessDetails } from './usersInBusinessDetails/usersInBusinessDetails';
import { CreateUserBusinessAccountBar } from '../CreateUserBusinessAccountBar/createUserBusinessAccountBar';
import Strings from '../../../i18n';
import { ContainerTable } from '../../locations/locationsList/locationListStyles';

export const UsersInBusinessAccount = () => {
  const [openSideBar, setOpenSideBar] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<
    BusinessAccountUser[]
  >([]);
  const [openSideFarFor, setOpenSidebarFor] = React.useState(
    viewRightSideBarEnum.empty
  );
  const businessAccountName = useSelector(selectNameBusinessAccount);
  const [getUsers, { data }] = useGetBusinessAccountUsersMutation();
  const { businessAccountId } = useParams();
  const navigate = useNavigate();

  const closeSideBar = () => {
    setOpenSideBar(false);
  };
  const reloadTable = () => {
    if (businessAccountId) {
      getUsers(businessAccountId);
      setOpenSideBar(false);
      setSelectedUsers([]);
    }
  };
  useEffect(() => {
    reloadTable();
  }, []);

  const switchComponentsToShowRightSideBar = () => {
    switch (openSideFarFor) {
      case viewRightSideBarEnum.addUserToBusinessAccount:
        return (
          <AddUserToBusinessAccountBar
            reloadTable={() => reloadTable()}
            closeSideBar={() => closeSideBar()}
            id={parseInt(businessAccountId || '', 10)}
            setOpenSideBarFor={setOpenSidebarFor}
            openSideBar={() => setOpenSideBar(true)}
          />
        );
      case viewRightSideBarEnum.usersInBusinessDetails:
        return (
          <UsersInBusinessDetails
            closeSideBar={() => {
              setOpenSideBar(false);
              setSelectedUsers([]);
            }}
            reloadTable={() => reloadTable()}
            ids={selectedUsers.map((user) => user.businessAccountUserId)}
          />
        );
      case viewRightSideBarEnum.createUserBusinessAccount:
        return (
          <CreateUserBusinessAccountBar
            reloadTable={() => reloadTable()}
            closeSideBar={() => closeSideBar()}
            id={parseInt(businessAccountId || '', 10)}
          />
        );
      default:
        return null;
    }
  };

  const getDate = (item: BusinessAccountUser) => {
    return moment(item.lastUpdated).format('DD/MM/YYYY HH:mm:ss');
  };
  const getHeaderCheckbox = () => {
    return (
      <Checkbox
        className="p-checkbox"
        style={{
          visibility: selectedUsers.length ? 'visible' : 'hidden',
          ...styledCheckboxHeader
        }}
        checked={(selectedUsers && selectedUsers.length > 0) ?? false}
        onChange={() => {
          setSelectedUsers([]);
        }}
      />
    );
  };
  const getStatus = (item: BusinessAccountUser) => {
    return item.status === Strings.common.active ? (
      <span className="text-success">{Strings.common.active}</span>
    ) : (
      <span className="text-warning">{Strings.common.pending}</span>
    );
  };
  useEffect(() => {
    if (selectedUsers.length >= 1) {
      setOpenSidebarFor(viewRightSideBarEnum.usersInBusinessDetails);
      setOpenSideBar(true);
    } else {
      setOpenSidebarFor(viewRightSideBarEnum.empty);
      setOpenSideBar(false);
    }
  }, [selectedUsers]);
  return (
    <>
      <Header>
        <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
          <ArrowBackIosIcon />
        </ButtonIconStyled>
        <TitleStyled variant="subtitle1" color={colors.strongGray}>
          {businessAccountName}
        </TitleStyled>
        <Tooltip
          target=".add-user"
          position="left"
          style={{ fontSize: '10px' }}
        />
        <ButtonIconStyled
          className="add-user"
          data-pr-tooltip="Add User"
          size="small"
          onClick={() => {
            setOpenSideBar(true);
            setOpenSidebarFor(viewRightSideBarEnum.addUserToBusinessAccount);
          }}
        >
          <AddCircleOutlineIcon />
        </ButtonIconStyled>
        <Tooltip
          target=".create"
          position="left"
          style={{ fontSize: '10px' }}
        />
        <ButtonIconStyled
          data-pr-tooltip="Create User"
          className="create"
          size="small"
          onClick={() => {
            setOpenSideBar(true);
            setOpenSidebarFor(viewRightSideBarEnum.createUserBusinessAccount);
          }}
        >
          <PersonAdd />
        </ButtonIconStyled>
      </Header>
      <ContainerTable component="main">
        <DataTable
          selection={selectedUsers}
          selectionMode="checkbox"
          onSelectionChange={(e) => {
            setSelectedUsers(e.value);
          }}
          value={data}
          size="small"
          scrollHeight="flex"
          scrollable
          paginator={data && data.length >= 50}
          selectionPageOnly
          rows={10}
          rowsPerPageOptions={[5, 10, 25, data ? data.length : 50]}
          tableStyle={{ fontSize: '14px' }}
        >
          <Column
            selectionMode="multiple"
            headerStyle={styledColumnHeaderCheckbox}
            header={getHeaderCheckbox}
            frozen
          />
          <Column field="userEmail" header="Email" sortable />
          <Column
            field="lastUpdated"
            header="Last Updated"
            sortable
            body={getDate}
          />
          <Column field="status" header="Status" sortable body={getStatus} />
        </DataTable>
      </ContainerTable>
      <RightSideBar open={openSideBar} onMenuClose={() => closeSideBar()}>
        {switchComponentsToShowRightSideBar()}
      </RightSideBar>
    </>
  );
};
