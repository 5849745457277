import React, { useState, useEffect } from 'react';
import { Grid, RadioGroup, Radio, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDynamicReportsConfig } from '../../../constants/context/dynamicReportsProvider/useDynamicReportsProvider';
import { ModalReportsProps } from './modalReportsProps';
import {
  FilterReportInputs,
  ModalContainer,
  ReportsContainer,
  FormControlLabelStyled,
  CategoryReports
} from './modalReportStyle';
import {
  filterItems,
  sortByCategory
} from '../../../utils/dynamicReportsUtils';
import { BasicModal } from '../basicModal/basicModal';
import {
  ReportItem,
  SortReports
} from '../../../types/dynamicReports/dynamicReportsInterface';
import Strings from '../../../i18n';

export const ModalReports: React.FunctionComponent<ModalReportsProps> = ({
  open,
  handleClose,
  reports
}) => {
  const { addConfigDynamicReports } = useDynamicReportsConfig();
  const [filterText, setFilterText] = useState<string>('');
  const [selectedReport, setSelectedReport] = useState<string>('');
  const [sortReports, setSortReports] = useState<SortReports[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReport((event.target as HTMLInputElement).value);
  };

  const handleChangeFilterText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterText(event.target.value);
  };

  const filteredItems = filterItems(sortReports, filterText);

  const changeReport = () => {
    addConfigDynamicReports({
      reportSelected: selectedReport,
      isOpenModalReports: false
    });
  };

  const handleKeypress = (e: any) => {
    if (e.key === 'Enter' && selectedReport.length) {
      changeReport();
    }
  };

  useEffect(() => {
    if (reports) {
      setSortReports(sortByCategory(reports));
    }
  }, [reports]);

  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      title={Strings.dynamicReports.modalReports.title}
      width="70%"
      height="60%"
      buttonText={Strings.dynamicReports.modalReports.buttonText}
      buttonIcon={<AddIcon />}
      onClick={changeReport}
    >
      <ModalContainer onKeyPress={handleKeypress}>
        <FilterReportInputs
          autoFocus
          onChange={handleChangeFilterText}
          size="small"
          label={Strings.dynamicReports.modalReports.filter}
          value={filterText}
        />

        <ReportsContainer>
          <RadioGroup value={selectedReport} onChange={handleChange}>
            {filteredItems &&
              filteredItems.map((item: SortReports) => (
                <Box key={item.category}>
                  <CategoryReports>{item.category}</CategoryReports>
                  <Grid container>
                    {item.reports.map((report: ReportItem) => (
                      <Grid item md={3} sm={4} xs={6} key={report.id}>
                        <FormControlLabelStyled
                          value={report.name}
                          control={<Radio />}
                          label={report.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
          </RadioGroup>
        </ReportsContainer>
      </ModalContainer>
    </BasicModal>
  );
};
