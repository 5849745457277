import { ComponentName } from '../constants/commonEnums';
import {
  ReportItem,
  SortReports
} from '../types/dynamicReports/dynamicReportsInterface';

export function SplitMacs(props: any) {
  const splitedMessage = `${props.split(';').join(', ')}.`;

  return splitedMessage;
}

export const utcDateToLocalDateTime = (date: string) => {
  const newDate = new Date(date.concat(' UTC'));
  return newDate.toLocaleString();
};
export const rangeDate = (componentName: string) => {
  const date = new Date();
  if (componentName === ComponentName.startDate) {
    return new Date(date.setDate(date.getDate() - 3));
  }
  return date;
};

export const sortByCategory = (reports: ReportItem[]) => {
  let auxReports: SortReports[] = [];
  reports.forEach((report) => {
    const repeatCategory = auxReports.find(
      (auxReport) => auxReport.category === report.category
    );
    if (!repeatCategory) {
      auxReports = [
        ...auxReports,
        {
          category: report.category,
          reports: reports.filter(
            (filterReport: ReportItem) =>
              filterReport.category === report.category
          )
        }
      ];
    }
  });
  return auxReports;
};

export const filterItems = (filterArray: SortReports[], filterText: string) => {
  let auxArray: ReportItem[] = [];
  filterArray.forEach((item) => {
    item.reports.forEach((report) => {
      auxArray = [...auxArray, report];
    });
  });

  auxArray = auxArray?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  return sortByCategory(auxArray);
};

export const capitalizeFirstLetter = (string: string) =>
  string[0].toLowerCase() + string.slice(1);

export const formatDate = (date: string) => {
  const newDate = new Date(date);
  return newDate.toLocaleString();
};

export const createPath = (id: number, path: string) => {
  const newPath = path.replace('{parameter}', id.toString());
  return newPath;
};

export const secondsToSecondsAndMinutes = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};
