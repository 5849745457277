import { Box, IconButton, Typography } from '@mui/material';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const MainContainer = muiStyled(Box)({
  padding: 0,
  margin: 0,
  display: 'flex',
  height: 'calc(100vh - 8px)',
  backgroundColor: colors.white,
  width: '100% !important',
  overflow: 'hidden'
});

export const AppContent = muiStyled(Box)<{ open: boolean }>(
  ({ theme, open }) => ({
    marginTop: '60px',
    flexGrow: 1,
    overflow: 'hidden',
    p: 3,
    [theme.breakpoints.up('md')]: {
      overflowX: 'inherit',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: `-${theme.spacing(35)}`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
      })
    }
  })
);

export const StyledButtonWithLabel = styled.button<{
  $color?: string;
}>`
  background: transparent;
  line-height: 1;
  background: transparent;
  transition: 0.2s all;
  border: none;
  font-size: 0.7rem;
  color: ${(props) => props.color || colors.strongGray};

  &:hover {
    background: ${colors.lightGray};
    border-radius: 5px;
  }
`;
export const ButtonIconHeaderStyled = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
  }

  &.selected {
    background-color: ${colors.lightGray};
    border-radius: 6px;
  }
`;

export const ErrorText = styled.p`
  font-size: 10px;
  color: ${colors.red};
`;
export const StyledBoxTable = styled(Box)`
  width: 100%;
  height: 90%;
  max-height: 90%;
  max-width: 100vw !important;
  overflow-x: hidden !important;

  .MuiDataGrid-overlayWrapper {
    height: 80px !important;
  }
`;

export const StyledHeaderContainer = styled.div`
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 10px;
`;

export const StyledTitleHeader = styled(Typography)`
  && {
    text-align: center;
    font-weight: bold;
    color: ${colors.brown};
    font-size: 1.4rem;
    line-height: 1;
  }
`;

export const StyledBackButtonNavigator = styled(IconButton)`
  && {
    border: 0;
    outline: 0;
    text-decoration: none;
  }

  &.selected {
    background-color: ${colors.lightGray};
    border-radius: 6px;
  }
`;
export const StyledCheckboxForTable = styled(Checkbox)`
  .p-checkbox-box {
    border-radius: 3px;
  }
  @media (max-width: 768px) {
    visibility: visible !important;
  }
`;
export const styledColumnHeaderCheckbox: {} = {
  width: '3rem',
  visibility: 'hidden',
  backgroundColor: colors.white
};
export const styledColumnHeaderCheckboxWithLabel: {} = {
  width: '3rem',
  visibility: 'hidden',
  backgroundColor: colors.white,
  margin: '0 auto',
  textAlign: 'center'
};

export const styledCheckboxHeader: {} = {
  backgroundColor: 'white',
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  marginLeft: '-5px'
};

export const StyledButtonActionUnderlined = styled.button`
  background-color: transparent;
  border: none;
  color: #487ff6;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  text-decoration: underline;
`;

export const TooltipStyled = {
  tooltip: {
    sx: {
      bgcolor: colors.tooltipBG,
      color: colors.tooltipText
    }
  }
};

export const ContainerStyled = styled.div`
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 10px;
`;

export const styledColumnItem: {} = {
  width: '25%'
};

export const StyledColumnSize = styled(Column)<{ minWidth: string }>`
  min-width: ${(props) => props.minWidth};
`;
