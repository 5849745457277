export const colors = {
  orange: '#ff5500',
  red: '#FF0D10',
  yellow: '#ffbf00',
  lightGray: '#ECECEC',
  mediumLightGray: '#cdcccc',
  strongGray: '#808080',
  mediumGray: '#A7ACB3',
  black: '#000',
  fb_blue: '#4267B2',
  white: '#FFFFFF',
  blue: '#2E6CF6',
  havenBlue: '#042654',
  lightBlue: '#c4e0f5',
  green: '#39CC64',
  darkGreen: '#026402',
  blueTheme: '#002952',
  tooltipBG: '#D9D9D9',
  tooltipText: '#858790',
  navBarBackground: '#F6F6F8',
  darkGray: '#565657',
  principalTextColorTheme: '#FF0D10',
  brown: '#595959',
  onlineMoreThan15: '#00751d',
  onlineLessThan15: '#6bc625',
  offlineMoreThan15: '#a40000',
  offlineLessThan15: '#cf7a11'
};
