import { Grid, Modal, IconButton, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ModalLongStringProps } from './modalLongStringProps';
import { ModalContainer, StringContainer } from './modalLongStringStyles';

export const ModalLongString: React.FunctionComponent<ModalLongStringProps> = ({
  handleClose,
  open,
  longString
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainer>
        <Grid item container xs={12} justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
        <StringContainer>
          <Typography>{longString}</Typography>
        </StringContainer>
      </ModalContainer>
    </Modal>
  );
};
