export const configAuth0 = {
  audience: process.env.REACT_APP_AUDIENCE || '',
  domain: process.env.REACT_APP_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH0CLIENT_ID || '',
  clientSecret: process.env.REACT_APP_CLIENT_SECRET || '',
  auth0Url: process.env.REACT_APP_AUTH0_URL || '',
  bdd: process.env.REACT_APP_BDD || '',
  grantType: 'http://auth0.com/oauth/grant-type/passwordless/otp',
  scope: 'email profile openid offline_access'
};

export const config = {
  baseUrlDealers:
    process.env.REACT_APP_API_URL || 'https://dev-api.havenlighting.com/',
  baseUrlResidential:
    process.env.REACT_APP_API_URL || 'https://dev-api.havenlighting.com/',
  baseUrlBlackBox: 'https://ase-cardinal-api-blackbox-dev.azurewebsites.net'
};

export const googleApiKey = 'AIzaSyCW0wvM-DRN7xxmRPHKjvlIU8r0HOG-MxM';

export const configPHue = {
  authorizePHue: `https://api.meethue.com/v2/oauth2/authorize?client_id=TBGGn0fk6pWjkNtFaBRDsnFf8ePgt8BG&response_type=code`
};
