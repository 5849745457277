import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { ActionType, ActionVerb } from '../../../constants/commonEnums';
import { ActionServiceProps } from './actionServiceProps';
import {
  useGetActionProcessMonitorMutation,
  usePostActionProcessMonitorMutation
} from '../../../services/dynamicReports/dynamicReportsService';

export const ActionService: React.FunctionComponent<ActionServiceProps> = ({
  actions
}) => {
  const [executeAction, setExecuteAction] = useState<boolean>(false);
  const { actionText, actionUrl, actionType, actionVerb } = actions;
  const [getActionProcessMonitor, { isLoading: isLoadingGetAction }] =
    useGetActionProcessMonitorMutation();
  const [postActionProcessMonitor, { isLoading: isLoadingPostAction }] =
    usePostActionProcessMonitorMutation();
  usePostActionProcessMonitorMutation();

  useEffect(() => {
    if (isLoadingGetAction || isLoadingPostAction) {
      setExecuteAction(false);
    }
  }, [isLoadingGetAction, isLoadingPostAction]);

  if (actionType === ActionType.Button) {
    return (
      <LoadingButton
        variant="contained"
        loading={isLoadingGetAction || isLoadingPostAction}
        onClick={() => setExecuteAction(true)}
      >
        {actionText}
      </LoadingButton>
    );
  }
  return (
    <a href={actionUrl} target="_blank" rel="noopener noreferrer">
      {actionText}
    </a>
  );
};
