import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import customFetchBase from '../expiredToken/expiredToken';
import {
  HealthServices,
  ReportItem
} from '../../types/dynamicReports/dynamicReportsInterface';

const customFetch = customFetchBase(config.baseUrlDealers);

export const apiDynamicReports = createApi({
  baseQuery: customFetch,
  endpoints: (builder) => ({
    getProcessMonitorLog: builder.mutation<HealthServices[], void>({
      query: () => ({
        url: `/api/Reports/ProcessMonitorLog`,
        method: 'GET'
      })
    }),
    getActionProcessMonitor: builder.mutation<
      any,
      { actionUrl: string; actionVerb: string }
    >({
      query: ({ actionUrl, actionVerb }) => ({
        url: actionUrl,
        method: actionVerb
      })
    }),
    postActionProcessMonitor: builder.mutation<
      any,
      { actionUrl: string; actionVerb: string }
    >({
      query: ({ actionUrl, actionVerb }) => ({
        url: actionUrl,
        method: 'POST'
      })
    }),
    getReportList: builder.mutation<ReportItem[], void>({
      query: () => ({
        url: `/api/Reports/ReportList`,
        method: 'GET'
      })
    }),
    getReportFilters: builder.mutation<any, number>({
      query: (reportId) => ({
        url: `/api/Reports/${reportId}/ReportFilters`,
        method: 'GET'
      })
    }),
    getApiCall: builder.mutation<any, { path: string; queryString: string }>({
      query: ({ path, queryString }) => ({
        url: `${path}${queryString}`,
        method: 'GET'
      })
    }),
    getSelectOptions: builder.mutation<any, string>({
      query: (path) => ({
        url: path,
        method: 'GET'
      })
    })
  })
});

export const {
  useGetProcessMonitorLogMutation,
  useGetActionProcessMonitorMutation,
  usePostActionProcessMonitorMutation,
  useGetReportListMutation,
  useGetReportFiltersMutation,
  useGetApiCallMutation,
  useGetSelectOptionsMutation
} = apiDynamicReports;
