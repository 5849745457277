import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Start, Person, CorporateFare, Launch } from '@mui/icons-material';
import { ISSIDResultItem } from '../../../../types/Dashboard/globalSearchInterfaces';
import {
  StyledButtonItemWithIcon,
  StyledItemResult,
  styledTooltip
} from '../globalSearchStyles';
import { StyledButtonActionUnderlined } from '../../../layout/main/mainStyles';
import Strings from '../../../../i18n';
import { AvatarUserComponent } from '../../AvatarUser/avatarUser';

interface SsidResultItemProps {
  data: ISSIDResultItem;
  clearSearchAndClose: () => void;
  showOptions: boolean;
}
export const SsidResultItemComponent = ({
  data,
  clearSearchAndClose,
  showOptions
}: SsidResultItemProps) => {
  const navigate = useNavigate();
  const redirectToLocation = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/locations/${data.location.locationId}/devices`);
    clearSearchAndClose();
  };

  const redirectToLocationNewTab = (event: React.MouseEvent) => {
    event.stopPropagation();
    window.open(`/locations/${data.location.locationId}/devices`, '_blank');
  };
  const goToUserDetails = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/users?searchUser=${data.location.ownerId}`);
    clearSearchAndClose();
  };
  return (
    <StyledItemResult onClick={redirectToLocation} showOptions={showOptions}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          <h4>
            {Strings.common.ssid}: {data.ssidName}
          </h4>
          <p>Location: {data.location.locationName}</p>
          <p className="d-flex">
            Owner:{' '}
            <AvatarUserComponent fullName={data.location.ownerName} size={20} />{' '}
            {data.location.ownerName}
          </p>
        </Grid>
        <Grid
          item
          className="showOnHover"
          xs={12}
          md="auto"
          justifyContent="flex-end"
        >
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.goToLocation}
            tooltipOptions={{ ...styledTooltip }}
            onClick={redirectToLocation}
          >
            <Start />
          </StyledButtonItemWithIcon>
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.goToUserDetails}
            tooltipOptions={{ ...styledTooltip }}
            onClick={goToUserDetails}
          >
            <Person />
          </StyledButtonItemWithIcon>
          <StyledButtonItemWithIcon
            type="button"
            tooltip={Strings.globalSearch.openInExternalTab}
            tooltipOptions={{ ...styledTooltip }}
            onClick={redirectToLocationNewTab}
          >
            <Launch />
          </StyledButtonItemWithIcon>
        </Grid>
      </Grid>
    </StyledItemResult>
  );
};
