/* eslint-disable jsx-a11y/label-has-associated-control */
import { ArrowBackIos, Cancel, Save } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Tooltip } from '@mui/material';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { toast } from 'react-toastify';
import { Header } from '../../../../components/header/header';
import {
  StyledHeaderContainer,
  StyledTitleHeader
} from '../../../../components/layout/main/mainStyles';
import Strings from '../../../../i18n';
import { ButtonIconStyled } from '../../../locations/allLocationsList/allLocationListStyles';
import {
  ItemGridAddEvent,
  StyledTitleLabelAddEvent,
  WhenButtonSelector
} from './addEventStyles';
import { LightZoneSelector } from '../../../../components/lightsZones/lightZoneSelector';
import { ILightZone } from '../../../../types/Lights/lightZoneInterfaces';
import { eventTypes } from '../../../../constants/eventEnums';
import { LightsZonesOff } from './offEvent/offEvent';
import {
  IAddEvent,
  IDateType,
  ITimeType
} from '../../../../types/events/eventsInterface';
import { EventUtils, eventDefaultSettings } from '../../../../utils/eventUtils';
import { PickEventTime } from '../../../../components/events/pickEventTime/pickEventTime';
import { PickEventDate } from '../../../../components/events/pickEventDate/pickEventDate';
import { PickEventDays } from '../../../../components/events/pickEventDays/pickEventDays';
import {
  usePostEventColorMutation,
  usePostEventOffMutation,
  usePostEventSceneMutation
} from '../../../../services/events/eventService';
import { SceneEvent } from './sceneEvent/sceneEvent';
import { OnColorEvent } from './onColorEvent/onColorEvent';
import './addEventCssStyles.css';
import { CommandsTypesItemEnum } from '../../../../constants/commonEnums';
import { SingleGroupSelector } from '../../../../components/groups/singleGroupSelector';
import { IGroup } from '../../../../types/Groups/groupsInterfaces';

export const AddEventTemplate = () => {
  const [postOffEvent, { isSuccess: isSuccessPostOff }] =
    usePostEventOffMutation();

  const [postSceneEvent, { isSuccess: isSuccessPostScene }] =
    usePostEventSceneMutation();

  const [postColorEvent, { isSuccess: isSuccessPostColor }] =
    usePostEventColorMutation();

  const { locationId, eventType } = useParams();
  const initialEventState = eventDefaultSettings;
  const [event, setEvent] = useState<IAddEvent>(initialEventState);
  const [eventName, setEventName] = useState(eventDefaultSettings.name);
  const [submitted, setSubmitted] = useState(false);
  const [selectedScene, setSelectedScene] = useState<any>(null);
  const [selectedColorAndBrightness, setSelectedColorAndBrightness] = useState<{
    brightness: number;
    colorId: number;
  }>({ brightness: 5, colorId: 1 });
  const [showEventTimePicker, setShowEventTimePicker] = useState(false);
  const [showEventDatePicker, setShowEventDatePicker] = useState(false);
  const [showEventDaysPicker, setShowEventDaysPicker] = useState(false);
  const [expiring, setExpiring] = useState(event.expiring);

  const navigate = useNavigate();
  const [selectedLightZones, setSelectedLightZones] = useState<ILightZone[]>(
    []
  );
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const resetEventState = () => {
    setEvent(initialEventState);
    setEventName(initialEventState.name);
    setSelectedLightZones([]);
    setSelectedScene(null);
    setShowEventTimePicker(false);
    setShowEventDatePicker(false);
    setShowEventDaysPicker(false);
    setSubmitted(false);
  };
  const handleExpiringChange = (e: any) => {
    setExpiring(e.checked);
    event.expiring = e.checked;
  };

  useEffect(() => {
    if (isSuccessPostOff || isSuccessPostScene || isSuccessPostColor) {
      navigate(`/locations/${locationId}/devices/schedules`);
      toast.success(Strings.events.eventAddedSuccessfully);
    }
  }, [isSuccessPostOff, isSuccessPostScene, isSuccessPostColor]);

  const renderEventTypeSwitch = () => {
    if (!eventType) {
      return <p />;
    }
    switch (+eventType) {
      case eventTypes.OFF_EVENT:
        return <LightsZonesOff />;
      case eventTypes.COLOR_EVENT:
        return (
          <OnColorEvent
            emitSelectionColor={(selection) => {
              setSelectedColorAndBrightness(selection);
            }}
            status={{
              colorId: selectedColorAndBrightness.colorId,
              brightness: selectedColorAndBrightness.brightness
            }}
          />
        );
      case eventTypes.SCENE_EVENT:
        return (
          <SceneEvent emitSelectedScene={(scene) => setSelectedScene(scene)} />
        );
      default:
        return <p />;
    }
  };

  const setTimeEvent = (data?: ITimeType) => {
    const updatedTime = data
      ? EventUtils.getEventTime({
          timeType: data.timeType,
          hour: data.hour,
          minute: data.minute,
          offsetMinutes: data.offsetMinutes
        })
      : EventUtils.getEventTime({
          timeType: event.timeType,
          hour: event.hour,
          minute: event.minute,
          offsetMinutes: event.offsetMinutes
        });
    setSelectedTime(updatedTime);

    setEvent((prevEvent) => ({
      ...prevEvent,
      timeType: data?.timeType ?? prevEvent.timeType,
      hour: data?.hour ?? prevEvent.hour,
      minute: data?.minute ?? prevEvent.minute,
      offsetMinutes: data?.offsetMinutes ?? prevEvent.offsetMinutes
    }));
  };

  const setDateEvent = (data?: IDateType) => {
    const updatedDate = data
      ? EventUtils.getEventDate({
          dateType: data.dateType,
          startMonth: data.startMonth,
          startDay: data.startDay,
          endMonth: data.endMonth,
          endDay: data.endDay,
          holidayStartIndex: data.holidayStartIndex,
          holidayStartOffset: data.holidayStartOffset,
          holidayEndIndex: data.holidayEndIndex,
          holidayEndOffset: data.holidayEndOffset
        })
      : EventUtils.getEventDate(event);

    setSelectedDate(updatedDate);

    setEvent((prevEvent) => ({
      ...prevEvent,
      dateType: data?.dateType ?? prevEvent.dateType,
      startMonth: data?.startMonth ?? prevEvent.startMonth,
      startDay: data?.startDay ?? prevEvent.startDay,
      endMonth: data?.endMonth ?? prevEvent.endMonth,
      endDay: data?.endDay ?? prevEvent.endDay,
      holidayStartIndex: data?.holidayStartIndex ?? prevEvent.holidayStartIndex,
      holidayStartOffset:
        data?.holidayStartOffset ?? prevEvent.holidayStartOffset,
      holidayEndIndex: data?.holidayEndIndex ?? prevEvent.holidayEndIndex,
      holidayEndOffset: data?.holidayEndOffset ?? prevEvent.holidayEndOffset
    }));
  };

  const setDaysEvent = (data: { weekDays: number; daysOfWeek: number }) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      weekDays: data.weekDays,
      daysOfWeek: data.daysOfWeek.toString()
    }));
  };

  const getDisplayStringForSelectedDays = () => {
    return EventUtils.binaryToWeekdays(
      EventUtils.weekDaysBinToDaysOfWeekNumber(event.weekDays ?? 0)
    );
  };

  const setSelectedTargets = () => {
    if (selectedLightZones.length) {
      event.targets = selectedLightZones.map((lightZone) => ({
        id: lightZone.id,
        type: lightZone.isZone
          ? CommandsTypesItemEnum.Zone
          : CommandsTypesItemEnum.Light
      }));
    } else if (selectedGroup) {
      event.targets = [
        {
          id: selectedGroup.groupId,
          type: CommandsTypesItemEnum.Group
        }
      ];
    }
  };

  const switchToSendEventByType = () => {
    if (!eventType) {
      return <p />;
    }
    switch (+eventType) {
      case eventTypes.OFF_EVENT:
        setSelectedTargets();
        postOffEvent(event);
        return '';
      case eventTypes.COLOR_EVENT:
        setSelectedTargets();
        event.lightColorId = selectedColorAndBrightness.colorId;
        event.lightBrightnessId = selectedColorAndBrightness.brightness;
        postColorEvent(event);
        return '';
      case eventTypes.SCENE_EVENT:
        event.sceneId = selectedScene.id;
        postSceneEvent(event);
        return <p />;
      default:
        return <p />;
    }
  };

  const addEvent = () => {
    setSubmitted(true);
    if (eventName && selectedTime && selectedDate && eventType) {
      switchToSendEventByType();
    }
  };
  useEffect(() => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      name: eventName
    }));
  }, [eventName]);
  useEffect(() => {
    if (locationId && eventType) {
      resetEventState();
      setEvent((prevEvent) => ({
        ...prevEvent,
        locationId: +locationId,
        eventType: +eventType
      }));
      setDateEvent();
      setTimeEvent();
    }
  }, []);
  return (
    <>
      <Header>
        <StyledHeaderContainer>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
                <ArrowBackIos />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <StyledTitleHeader>
                {Strings.events.addEventTitle}
              </StyledTitleHeader>
            </div>
            <div className="col-4 text-right">
              <Tooltip title={Strings.common.save}>
                <ButtonIconStyled
                  size="small"
                  onClick={() => addEvent()}
                  disabled={false}
                >
                  <Save />
                </ButtonIconStyled>
              </Tooltip>
              <Tooltip title={Strings.common.cancel}>
                <ButtonIconStyled
                  size="small"
                  onClick={() =>
                    navigate(`/locations/${locationId}/devices/schedules`)
                  }
                  disabled={false}
                >
                  <Cancel />
                </ButtonIconStyled>
              </Tooltip>
            </div>
          </div>
        </StyledHeaderContainer>
      </Header>
      <Grid container>
        <ItemGridAddEvent item xs={12}>
          <StyledTitleLabelAddEvent>
            {Strings.events.eventNameLabel}
          </StyledTitleLabelAddEvent>
          <InputText
            className="w-100"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            placeholder={Strings.scenes.sceneNamePlaceholder}
          />
        </ItemGridAddEvent>
        <Divider className="mx-2" />
        <ItemGridAddEvent item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6}>
              {renderEventTypeSwitch()}
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTitleLabelAddEvent>
                * {Strings.events.when}
              </StyledTitleLabelAddEvent>
              <Grid container>
                <Grid xs={12} item>
                  <WhenButtonSelector
                    icon="pi pi-angle-right"
                    iconPos="right"
                    label={
                      selectedTime
                        ? `${Strings.events.time} ${selectedTime}`
                        : Strings.events.setEventTime
                    }
                    onClick={() => setShowEventTimePicker(true)}
                  />
                  {submitted && !selectedTime && (
                    <div className="text-danger">
                      <small>{Strings.events.pleaseProvideEventTime}</small>
                    </div>
                  )}
                </Grid>
                <Grid xs={12} item>
                  <WhenButtonSelector
                    icon="pi pi-angle-right"
                    iconPos="right"
                    label={
                      selectedDate
                        ? `${selectedDate}`
                        : Strings.events.setEventDate
                    }
                    onClick={() => setShowEventDatePicker(true)}
                  />
                  {submitted && !selectedDate && (
                    <div className="text-danger">
                      <small>{Strings.events.pleaseProvideEventDate}</small>
                    </div>
                  )}
                </Grid>
                <Grid xs={12} item>
                  <WhenButtonSelector
                    icon="pi pi-angle-right"
                    iconPos="right"
                    label={getDisplayStringForSelectedDays()}
                    onClick={() => setShowEventDaysPicker(true)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="btn-expiring d-flex justify-content-between">
                    <label htmlFor="binary" className="ms-2 mb-0">
                      EXPIRING EVENT
                    </label>
                    <Checkbox
                      inputId="binary"
                      checked={expiring}
                      onChange={handleExpiringChange}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ItemGridAddEvent>
        {eventType && +eventType < eventTypes.SCENE_EVENT && (
          <>
            <ItemGridAddEvent item xs={12} md={6}>
              <LightZoneSelector
                locationId={locationId ? +locationId : 0}
                selectedLightZones={selectedLightZones}
                setSelectedLightZones={setSelectedLightZones}
                disabled={!!selectedGroup}
              />
            </ItemGridAddEvent>
            <ItemGridAddEvent item xs={12} md={6}>
              <SingleGroupSelector
                locationId={locationId ? +locationId : 0}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                disabled={!!selectedLightZones.length}
              />
            </ItemGridAddEvent>
          </>
        )}
      </Grid>
      <PickEventTime
        showEventTimePicker={showEventTimePicker}
        setShowEventTimePicker={setShowEventTimePicker}
        timeType={{
          timeType: event.timeType,
          hour: event.hour,
          minute: event.minute,
          offsetMinutes: event.offsetMinutes
        }}
        onOk={(info) => setTimeEvent(info)}
      />
      <PickEventDate
        showEventDatePicker={showEventDatePicker}
        setShowEventDatePicker={setShowEventDatePicker}
        dateType={{
          dateType: event.dateType,
          holidayEndIndex: event.holidayEndIndex,
          holidayEndOffset: event.holidayEndOffset,
          endDay: event.endDay,
          endMonth: event.endMonth,
          holidayStartIndex: event.holidayStartIndex,
          holidayStartOffset: event.holidayStartOffset,
          startDay: event.startDay,
          startMonth: event.startMonth
        }}
        onOk={(info) => setDateEvent(info)}
      />
      <PickEventDays
        showEventDaysPicker={showEventDaysPicker}
        setShowEventDaysPicker={setShowEventDaysPicker}
        daysSelected={{
          weekDays: event.weekDays ?? 127,
          daysOfWeek: +event.daysOfWeek
        }}
        onOk={(info) => setDaysEvent(info)}
      />
    </>
  );
};
