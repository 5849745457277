import React from 'react';
import { IconButton, Modal } from '@mui/material';
// eslint-disable-next-line
import { JsonToTable } from 'react-json-to-table';
import CloseIcon from '@mui/icons-material/Close';
import { PopUpArraysProps } from './popUpArraysProps';
import { PopUpContainer, CustomGrid } from './popUpArraysStyles';

export const PopUpArrays: React.FC<PopUpArraysProps> = ({
  open,
  handleClose,
  array
}) => {
  const parseArray = JSON.parse(array);

  return (
    <Modal open={open} onClose={handleClose}>
      <PopUpContainer>
        <CustomGrid container direction="column">
          <IconButton onClick={handleClose}>
            <CloseIcon color="primary" />
          </IconButton>
          <JsonToTable json={parseArray} />
        </CustomGrid>
      </PopUpContainer>
    </Modal>
  );
};
