import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import JsonView from '@uiw/react-json-view';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGetDebugDeviceMutation } from '../../../services/device/deviceService';
import { Header } from '../../../components/header/header';
import { MarginComponent } from '../../../components/marginComponent/marginComponent';
import Strings from '../../../i18n';
import { ButtonIconStyled } from '../../../views/devices/devicesStyles';
import {
  selectIsRightSideBarOpen,
  setOpenRightSideBar
} from '../../../store/slices/app/app';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import {
  StyledBoxTable,
  StyledButtonWithLabel
} from '../../../components/layout/main/mainStyles';
import { RightSideBar } from '../../../components/layout/rightSideBar/rightSideBar';
import { DeviceDebugInfo } from '../../../types/Device/DeviceInterfaces';

export const GetDebugDeviceInfoTemplate = () => {
  const { deviceId } = useParams();
  const isOpenRightSideBar = useSelector(selectIsRightSideBarOpen) as boolean;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [jsonToShow, setJsonToShow] = useState({});
  const [
    getDebugDevice,
    {
      isLoading: isLoadingGetDebugDevice,
      isSuccess: isSuccessGetDebugDevice,
      data: dataDebugDevice
    }
  ] = useGetDebugDeviceMutation();

  useEffect(() => {
    if (deviceId) {
      getDebugDevice(deviceId);
    }
  }, []);

  const showJson = (element: {}) => {
    setJsonToShow(element);
    dispatch(setOpenRightSideBar(true));
  };

  const getActions = (item: DeviceDebugInfo) => {
    return [
      <StyledButtonWithLabel
        onClick={() => {
          showJson(item);
        }}
        type="button"
      >
        <VisibilityIcon />
        <Typography variant="inherit" color="initial" component="p">
          {Strings.common.viewMore}
        </Typography>
      </StyledButtonWithLabel>
    ];
  };

  const getName = (item: DeviceDebugInfo) => {
    return [
      <Typography variant="inherit" color="initial" component="p">
        {item.sourceInfo.uniqueProductName}
      </Typography>
    ];
  };

  const getFirmware = (item: DeviceDebugInfo) => {
    return [
      <Typography variant="inherit" color="initial" component="p">
        {item.sourceInfo.firmwareVersions}
      </Typography>
    ];
  };

  const getHardware = (item: DeviceDebugInfo) => {
    return [
      <Typography variant="inherit" color="initial" component="p">
        {item.sourceInfo.hardwareVersions}
      </Typography>
    ];
  };

  const dataTableSortFunction =
    (value: any[]) =>
    (event: any): any[] => {
      return [...value].sort((data1, data2) => {
        const value1 = new Date(data1[event.field]);
        const value2 = new Date(data2[event.field]);
        if (value1 == null && value2 == null) return 0;
        if (value2 == null) return -1;
        if (value1 == null) return 1;
        if (value1 < value2) return -1 * event.order;
        if (value1 > value2) return 1 * event.order;
        return 0;
      });
    };
  const myProductSort = dataTableSortFunction(dataDebugDevice as any[]);

  return (
    <>
      <Header>
        <MarginComponent ml={30} mr={30}>
          <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
            <ArrowBackIos />
          </ButtonIconStyled>
        </MarginComponent>
        <Grid
          container
          justifyContent="center"
          direction="column"
          marginBottom={1}
        >
          <Typography variant="h6" color="initial" textAlign="center">
            {Strings.serverAccess.devices.getDebugDevice}
          </Typography>
          <Typography
            variant="subtitle1"
            color="initial"
            textAlign="center"
            fontSize={12}
          >
            {Strings.serverAccess.devices.currentDevice} {deviceId}
          </Typography>
        </Grid>
      </Header>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <StyledBoxTable>
            {isLoadingGetDebugDevice && <LoadingRowsComponent />}
            {isSuccessGetDebugDevice && dataDebugDevice && (
              <DataTable
                value={dataDebugDevice}
                size="small"
                scrollHeight="flex"
                scrollable
                paginator={dataDebugDevice && dataDebugDevice.length >= 50}
                selectionPageOnly
                rows={50}
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  dataDebugDevice ? dataDebugDevice.length : 50
                ]}
                tableStyle={{ fontSize: '14px' }}
              >
                <Column
                  field="createdAt"
                  header="Created Add"
                  sortable
                  sortFunction={myProductSort}
                />
                <Column
                  field="uniqueProductName"
                  header="Product Name"
                  sortable
                  body={getName}
                />
                <Column
                  field="firmwareVersions"
                  header="Firmware Version"
                  sortable
                  body={getFirmware}
                />
                <Column
                  field="hardwareVersions"
                  header="Hardware Version"
                  sortable
                  body={getHardware}
                />
                <Column field="actions" header="Actions" body={getActions} />
              </DataTable>
            )}
          </StyledBoxTable>
        </Grid>
      </Grid>
      <RightSideBar
        open={isOpenRightSideBar}
        onMenuClose={() => {
          dispatch(setOpenRightSideBar(false));
        }}
      >
        <JsonView
          value={jsonToShow}
          displayDataTypes={false}
          displayObjectSize={false}
          indentWidth={2}
        />
      </RightSideBar>
    </>
  );
};
