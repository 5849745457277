import React, { useState } from 'react';
import { directionSort } from '../constants/commonEnums';

export const useSortableData = (items: any[] | undefined, config = null) => {
  const [sortConfig, setSortConfig] = useState<null | {
    key: string;
    direction: string;
  }>(config);

  const sortedItems = React.useMemo(() => {
    if (!items) {
      return null;
    }
    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === directionSort.ascending ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === directionSort.ascending ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string) => {
    let direction = directionSort.ascending;
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === directionSort.ascending
    ) {
      direction = directionSort.descending;
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
