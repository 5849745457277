import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputLabel } from '@mui/material';
import { BasicModal } from '../basicModal/basicModal';
import { FilterComponents } from '../filterComponents/filterComponents.component';
import { ModalFilterProps } from './modalFIlterProps';
import { ColumnGrid } from './modalFilterStyles';
import { useDynamicReportsConfig } from '../../../constants/context/dynamicReportsProvider/useDynamicReportsProvider';
import {
  InputValue,
  ReportFilters
} from '../../../types/dynamicReports/dynamicReportsInterface';
import Strings from '../../../i18n';

export const ModalFilter: React.FunctionComponent<ModalFilterProps> = ({
  open,
  handleClose,
  inputsFilter,
  onValueChange,
  onSearch,
  inputsValues
}) => {
  const { configDynamicReports } = useDynamicReportsConfig();
  const [disableSearch, setDisableSearch] = useState<boolean>(false);

  const validateInputs = (values: InputValue[]) => {
    values.forEach((value) => {
      if (value.isRequired) {
        if (value.value) {
          setDisableSearch(false);
        }
        if (!value.value) {
          setDisableSearch(true);
        }
      }
    });
  };

  useEffect(() => {
    validateInputs(inputsValues);
  }, [inputsValues]);

  useEffect(() => {
    setDisableSearch(false);
  }, [configDynamicReports.reportSelected]);

  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      title={Strings.dynamicReports.modalReports.title}
      width="50%"
      height="fit-content"
      buttonText={Strings.dynamicReports.modalReports.buttonText}
      onClick={onSearch}
      buttonIcon={<SearchIcon />}
      disabledButton={disableSearch}
    >
      <ColumnGrid container spacing={3}>
        {inputsFilter &&
          inputsFilter?.map((item: ReportFilters) => (
            <Grid item md={6} sm={12} xs={12} key={item.name}>
              <InputLabel htmlFor="input-with-icon-adornment">
                {item.name}
              </InputLabel>
              <FilterComponents
                queryString={item.queryString}
                inputsValues={inputsValues}
                typeComponent={item.type}
                componentName={item.name}
                componentPath={item.searchPath}
                onValueChanged={(value: InputValue) => {
                  onValueChange(
                    item.mandatory,
                    item.queryString,
                    item.type,
                    value
                  );
                }}
              />
            </Grid>
          ))}
      </ColumnGrid>
    </BasicModal>
  );
};
