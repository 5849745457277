import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { TabContext, TabPanel } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import {
  useAddDeviceMutation,
  useFileSyncDesiredPropertiesMutation,
  useGetAllProductNamesMutation,
  useGetDeviceInfoMutation,
  useGetSystemStateMutation
} from '../../../services/device/deviceService';
import {
  ButtonStyled,
  ButtonStyledCancel,
  ContainerTab,
  StyledTab,
  StyledTabList
} from './detailsDeviceServiceStyles';
import Strings from '../../../i18n';
import { AddDeviceBarProps } from './detailsDeviceProps';
import {
  DeviceByLocation,
  RegisteredDevice
} from '../../../types/Device/DeviceInterfaces';
import { DeviceMethodsSideBar } from '../deviceMethodsSideBar/deviceMethodsSideBar';
import {
  StyledContainerFooterSideBar,
  StyledContainerItems,
  StyledTitleSideBar
} from '../../../components/commons/SideBarComponents/sideBarComponentsStyles';
import { SideBarReadOnly } from '../../../components/commons/SideBarComponents/ReadOnlyItems/sideBarReadOnly';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';

export const DetailsDevice = ({
  reloadTable,
  device,
  openRightSideBarForMethod,
  openRightSideBar,
  setOpenRightSideBar,
  deviceLocationView
}: AddDeviceBarProps) => {
  const [macAddress, setMacAddress] = useState('');
  const [productType, setProductType] = useState('');
  const [value, setValue] = useState('1');
  const [addDevice, { isLoading, isSuccess }] = useAddDeviceMutation();
  const [getDetails, { data: deviceDetails }] = useGetDeviceInfoMutation();
  const [selectedDevice, setSelectedDevice] = useState<
    RegisteredDevice | DeviceByLocation
  >();
  const navigate = useNavigate();
  const [getProducts, { data }] = useGetAllProductNamesMutation();
  const handleChangeRole = (event: any) => {
    setProductType(event.target.value.toString());
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    getProducts('');
  }, []);

  useEffect(() => {
    if (device && openRightSideBar) {
      getDetails(device.deviceId);
      setSelectedDevice(device);
      setMacAddress(device.deviceId);
    }
  }, [openRightSideBar]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(Strings.serverAccess.devices.successAddDevice);
      setOpenRightSideBar(false);
      reloadTable();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!openRightSideBar) {
      setMacAddress('');
      setProductType('');
      setSelectedDevice(undefined);
    }
  }, [openRightSideBar]);

  const redirectToGetDeviceAnnounce = (id: string) => {
    setOpenRightSideBar(false);
    navigate(`/server-access/devices/getDeviceAnnounce/${id}`);
  };

  const redirectToGetDeviceDebug = (id: string) => {
    setOpenRightSideBar(false);
    navigate(`/server-access/devices/getDebugInfo/${id}`);
  };

  const redirectToGetDeviceMethods = (id: string) => {
    setOpenRightSideBar(false);
    navigate(`/server-access/devices/getDeviceMethods/${id}`);
  };
  const redirectToCustomFiles = (id: string) => {
    setOpenRightSideBar(false);
    navigate(`/server-access/devices/customFiles/${id}`);
  };

  const redirectToRecords = (id: string) => {
    setOpenRightSideBar(false);
    navigate(`/server-access/devices/recordsDevice/${id}`);
  };

  const redirectToFileList = (id: string) => {
    setOpenRightSideBar(false);
    navigate(`/server-access/devices/fileList/${id}`);
  };

  const redirectToDesiredProperties = (id: string) => {
    setOpenRightSideBar(false);
    navigate(`/file-sync-db-reader/${id}`);
  };

  return (
    <>
      <StyledContainerItems>
        <StyledTitleSideBar>
          {Strings.serverAccess.devices.editDevice}
        </StyledTitleSideBar>
        <TabContext value={value}>
          {selectedDevice && (
            <StyledTabList
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="inherit"
            >
              <StyledTab label="Properties" value="1" key="properties" />
              <StyledTab label="Methods" value="2" key="methods" />
              <StyledTab label="Tables" value="3" key="tables" />
            </StyledTabList>
          )}
          <TabPanel value="1" sx={ContainerTab}>
            {device && (
              <SideBarReadOnly
                readOnlyItems={[
                  {
                    name: Strings.devices.productType,
                    value:
                      'productType' in device
                        ? device.productType.toString()
                        : ''
                  }
                ]}
              />
            )}
            <SideBarReadOnly
              readOnlyItems={[
                {
                  name: Strings.devices.deviceId,
                  value: macAddress
                }
              ]}
            />
            {deviceDetails &&
              selectedDevice &&
              deviceDetails.systemMethods.map((method) => {
                return (
                  <SideBarReadOnly
                    key={method.methodName}
                    readOnlyItems={[
                      {
                        name: method.methodName,
                        value: method.methodValue
                      }
                    ]}
                  />
                );
              })}
          </TabPanel>
          <TabPanel value="2" sx={ContainerTab}>
            <DeviceMethodsSideBar
              reloadTable={() => reloadTable()}
              openRightSideBarForMethod={openRightSideBarForMethod}
              deviceIds={[selectedDevice?.deviceId?.toString() || '']}
              idDevice={[selectedDevice?.id ?? 0]}
              openRightSideBar={openRightSideBar}
              setOpenRightSideBar={setOpenRightSideBar}
              deviceLocationView={deviceLocationView}
            />
          </TabPanel>
          <TabPanel value="3" sx={ContainerTab}>
            <SideBarSimpleMethodItem
              name={Strings.serverAccess.devices.FileSyncByDevice}
              action={() =>
                redirectToDesiredProperties(
                  selectedDevice?.deviceId.toString() || ''
                )
              }
              successAction={false}
              textSuccess="success"
            />
            <SideBarSimpleMethodItem
              name={Strings.serverAccess.devices.getDebugDeviceLabel}
              action={() =>
                redirectToGetDeviceDebug(
                  selectedDevice?.deviceId.toString() || ''
                )
              }
              successAction={false}
              textSuccess="success"
            />
            <SideBarSimpleMethodItem
              name={Strings.serverAccess.devices.getDeviceAnnounce}
              action={() =>
                redirectToGetDeviceAnnounce(
                  selectedDevice?.deviceId.toString() || ''
                )
              }
              successAction={false}
              textSuccess="success"
            />
            <SideBarSimpleMethodItem
              name={Strings.serverAccess.devices.records}
              action={() =>
                redirectToRecords(selectedDevice?.deviceId.toString() || '')
              }
              successAction={false}
              textSuccess="success"
            />
            <SideBarSimpleMethodItem
              name={Strings.serverAccess.devices.scheduleMethods}
              action={() =>
                redirectToGetDeviceMethods(
                  selectedDevice?.deviceId.toString() || ''
                )
              }
              successAction={false}
              textSuccess="success"
            />
            <SideBarSimpleMethodItem
              name={Strings.serverAccess.devices.customFiles}
              action={() =>
                redirectToCustomFiles(selectedDevice?.deviceId.toString() || '')
              }
              successAction={false}
              textSuccess="success"
            />
            <SideBarSimpleMethodItem
              name={Strings.serverAccess.devices.fileListLabel}
              action={() =>
                redirectToFileList(selectedDevice?.deviceId.toString() || '')
              }
              successAction={false}
              textSuccess="success"
            />
            <SideBarSimpleMethodItem
              name={Strings.serverAccess.devices.systemState}
              action={() =>
                navigate(
                  `/server-access/devices/systemState/${
                    selectedDevice?.deviceId.toString() || ''
                  }`
                )
              }
              successAction={false}
              textSuccess="success"
            />
          </TabPanel>
        </TabContext>
      </StyledContainerItems>
      {value === '1' && (
        <StyledContainerFooterSideBar>
          <button
            type="button"
            className="btn-cancel"
            onClick={() => {
              setOpenRightSideBar(false);
            }}
          >
            {Strings.common.cancel}
          </button>
          <button
            type="button"
            className="btn-save"
            disabled={Boolean(selectedDevice) || isLoading}
            onClick={() => {}}
          >
            {Strings.common.add}
          </button>
        </StyledContainerFooterSideBar>
      )}
    </>
  );
};
