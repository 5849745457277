import { styled, Select, TextField, FormControl } from '@mui/material';
import { StylesConfig } from 'react-select';
import { colors } from '../../../constants/colors';

export const CustomSelect = styled(Select)(({ theme }) => ({
  boxShadow: '2px 2px 2px 1px rgb(64 60 67 / 16%)',
  borderRadius: '5px',
  width: '100%',
  background: colors.white
}));

export const CustomInput = styled(TextField)(({ theme }) => ({
  boxShadow: '2px 2px 2px 1px rgb(64 60 67 / 16%)',
  borderRadius: '5px',
  background: colors.white,
  width: '100%'
}));

export const FormControlSelect = styled(FormControl)({
  minWidth: '150px'
});

export const customStyles: StylesConfig = {
  control: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    border: state.isFocused ? '2px solid black' : '1px solid #cccccc',
    boxShadow: '2px 2px 2px 1px rgb(64 60 67 / 16%)',
    '&:hover': {
      border: '1px solid black'
    }
  }),
  menuPortal: (base: Record<string, unknown>) => ({ ...base, zIndex: 9999 })
};
