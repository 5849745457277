import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Dialog } from 'primereact/dialog';
import JsonView from '@uiw/react-json-view';
// eslint-disable-next-line import/no-unresolved
import { githubLightTheme } from '@uiw/react-json-view/githubLight';
import { toast } from 'react-toastify';
import Strings from '../../../i18n';
import { viewRightSideBarEnum } from '../../../constants/sidebarEnums';
import { DeviceMethodsSideBarProps } from './deviceMethodsSideBarProps';
import {
  useDeleteDeviceCredentialsMutation,
  useDeleteDeviceMutation,
  useDeviceRebootCommandMutation,
  useGetCredentialsMutation,
  useGetTwinInfoMutation,
  usePostCustomFileDeviceMutation
} from '../../../services/device/deviceService';
import { SideBarSimpleMethodItem } from '../../../components/commons/SideBarComponents/SimpleMethodItem/sideBarSimpleMethodButtonItem';
import { SideBarDeleteActionWithPopup } from '../../../components/commons/SideBarComponents/DeleteActionWithPopup/sideBarDeleteActionWithPopup';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import { SideBarSimpleMethodWithValue } from '../../../components/commons/SideBarComponents/MethodWithValueItem/sideBarMethodWithValueItem';
import { JsonViewItem } from '../../../components/commons/JsonViewItem/jsonViewItem';
import { useDeleteDeviceFromLocationMutation } from '../../../services/deviceByLocation/deviceByLocationService';

export const DeviceMethodsSideBar = ({
  openRightSideBarForMethod,
  deviceIds,
  reloadTable,
  setOpenRightSideBar,
  deviceLocationView,
  clearSingleDevice,
  idDevice
}: DeviceMethodsSideBarProps) => {
  const [visibleCredentials, setVisible] = useState(false);
  const [visibleTwinInfo, setVisibleTwinInfo] = useState(false);
  const [fileUpload, setFileUpload] = useState<any>(null);
  const [
    getCredentials,
    {
      isLoading: isLoadingGetCredentials,
      isSuccess: isSuccessGetCredentials,
      data: dataGetCredentials
    }
  ] = useGetCredentialsMutation();
  const [
    getTwinInfo,
    {
      isLoading: isLoadingGetTwinInfo,
      isSuccess: isSuccessGetTwinInfo,
      data: dataGetTwinInfo
    }
  ] = useGetTwinInfoMutation();
  const [
    deleteCredentials,
    {
      isSuccess: isSuccessDeleteCredentials,
      isLoading: isLoadingDeleteCredentials
    }
  ] = useDeleteDeviceCredentialsMutation();
  const [deleteDevice, { isSuccess: isSuccessDeleted }] =
    useDeleteDeviceMutation();

  const [postCustomFile, { isSuccess: isSuccessPostCustom }] =
    usePostCustomFileDeviceMutation();

  const [rebootDevicesCommand, { isSuccess: isSuccessRebotDevice }] =
    useDeviceRebootCommandMutation();

  const [deleteFromLocation, { isSuccess: isSuccessDeleteFromLocation }] =
    useDeleteDeviceFromLocationMutation();

  useEffect(() => {
    if (isSuccessDeleted || isSuccessDeleteFromLocation) {
      toast.success(Strings.serverAccess.devices.successDeletedDevice);
      reloadTable();
      setOpenRightSideBar(false);
    }
  }, [isSuccessDeleted || isSuccessDeleteFromLocation]);

  useEffect(() => {
    if (isSuccessPostCustom) {
      toast.success(Strings.serverAccess.devices.successPostCustomFile);
      setFileUpload(null);
      setOpenRightSideBar(false);
    }
  }, [isSuccessPostCustom]);

  useEffect(() => {
    if (isSuccessRebotDevice) {
      toast.success(Strings.serverAccess.devices.successRebootDevice);
      reloadTable();
      setOpenRightSideBar(false);
    }
  }, [isSuccessRebotDevice]);

  const deleteItem = (ids: string[]) => {
    deleteDevice(ids);
  };

  const changeFileHandler = (event: any) => {
    setFileUpload(event.target.files[0]);
  };

  const sendFile = () => {
    if (fileUpload) {
      const data = new FormData();
      data.append('file', fileUpload);
      postCustomFile({ deviceId: deviceIds[0], data });
    }
  };

  const rebootDevices = (ids: string[]) => {
    rebootDevicesCommand(ids);
  };

  const getCredentialsAndOpenDialog = () => {
    setVisible(true);
    getCredentials(deviceIds[0]);
  };

  const getTwinInfoAndOpenDialog = () => {
    setVisibleTwinInfo(true);
    getTwinInfo(deviceIds[0]);
  };

  useEffect(() => {
    if (isSuccessDeleteCredentials) {
      toast.success(Strings.serverAccess.devices.successDeleteCredentials);
      reloadTable();
      setOpenRightSideBar(false);
    }
  }, [isSuccessDeleteCredentials]);

  return (
    <>
      <Grid container>
        <SideBarSimpleMethodItem
          name={Strings.serverAccess.devices.singletonMethodLabel}
          action={() =>
            openRightSideBarForMethod(viewRightSideBarEnum.singletonDevice)
          }
          successAction={false}
          textSuccess="success"
        />
        <SideBarSimpleMethodItem
          name={Strings.serverAccess.devices.staticMethodLabel}
          action={() =>
            openRightSideBarForMethod(viewRightSideBarEnum.staticDevice)
          }
          successAction={false}
          textSuccess="success"
        />
        {!deviceLocationView && (
          <SideBarSimpleMethodItem
            name={Strings.serverAccess.devices.addDeviceToLocationLabel}
            action={() => {
              clearSingleDevice();
              openRightSideBarForMethod(
                viewRightSideBarEnum.addDeviceToLocation
              );
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        {deviceIds.length === 1 && (
          <SideBarSimpleMethodItem
            name={Strings.serverAccess.devices.getCredentials}
            action={() => getCredentialsAndOpenDialog()}
            successAction={false}
            textSuccess="success"
          />
        )}
        {deviceIds.length === 1 && (
          <SideBarSimpleMethodItem
            name={Strings.notes.addNote}
            action={() => {
              openRightSideBarForMethod(viewRightSideBarEnum.addNote);
            }}
            successAction={false}
            textSuccess="success"
          />
        )}
        {deviceIds.length === 1 && (
          <SideBarSimpleMethodWithValue
            label="Custom File"
            type="inputFile"
            currentValue={fileUpload}
            onChange={(e) => changeFileHandler(e)}
            action={() => sendFile()}
            successAction
            textSuccess="success"
            fileName={fileUpload?.name ?? ''}
          />
        )}
        {deviceIds.length === 1 && (
          <SideBarSimpleMethodItem
            name={Strings.serverAccess.devices.twinInfo}
            action={() => getTwinInfoAndOpenDialog()}
            successAction={false}
            textSuccess="success"
          />
        )}
        <SideBarSimpleMethodItem
          name={Strings.serverAccess.devices.sendDirectMethodLabel}
          action={() => {
            openRightSideBarForMethod(
              viewRightSideBarEnum.sendDirectMethodText
            );
          }}
          successAction={false}
          textSuccess="success"
        />
        <SideBarSimpleMethodItem
          name={Strings.serverAccess.devices.directFOTA}
          action={() =>
            openRightSideBarForMethod(viewRightSideBarEnum.directFOTA)
          }
          successAction={false}
          textSuccess="success"
        />
        <SideBarSimpleMethodItem
          name={Strings.serverAccess.devices.moveLocation}
          action={() =>
            openRightSideBarForMethod(viewRightSideBarEnum.moveLocation)
          }
          successAction={false}
          textSuccess="success"
        />
        <SideBarSimpleMethodItem
          name={Strings.serverAccess.devices.rebootDevicesLabel}
          action={() => rebootDevices(deviceIds)}
          successAction={false}
          textSuccess="success"
        />
        <SideBarSimpleMethodItem
          name={Strings.serverAccess.devices.setWifiCredentialsLabel}
          action={() => {
            openRightSideBarForMethod(viewRightSideBarEnum.SetWifiCredentials);
          }}
          successAction={false}
          textSuccess="success"
        />
        {deviceIds.length === 1 && (
          <SideBarSimpleMethodItem
            name={Strings.serverAccess.devices.deleteCredentials}
            action={() => {
              deleteCredentials(deviceIds[0]);
            }}
            successAction={false}
            textSuccess="success"
            loading={isLoadingDeleteCredentials}
          />
        )}
        {deviceLocationView && (
          <SideBarDeleteActionWithPopup
            onDelete={() => deleteFromLocation(idDevice)}
            titleConfirmation={
              Strings.serverAccess.devices.deleteDeviceFromLocationTitle
            }
            textConfirmation={
              Strings.serverAccess.devices.deleteDeviceFromLocation
            }
            textIcon={Strings.devices.deleteAssignedLocation}
          />
        )}
        {!deviceLocationView && (
          <SideBarDeleteActionWithPopup
            onDelete={() => deleteItem(deviceIds)}
            titleConfirmation={Strings.serverAccess.devices.deleteDeviceTitle}
            textConfirmation={Strings.serverAccess.devices.deleteDeviceText}
          />
        )}
      </Grid>
      <Dialog
        header="Credentials"
        visible={visibleCredentials}
        style={{ width: '65vw', overflow: '999' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        onHide={() => {
          setVisible(false);
          setOpenRightSideBar(false);
        }}
      >
        {isLoadingGetCredentials && <LoadingRowsComponent />}
        {isSuccessGetCredentials &&
          dataGetCredentials &&
          dataGetCredentials.map((item, index) => (
            <JsonViewItem
              key={index}
              title={item.split(' : ')[0]}
              values={item.split(' : ')[1]}
            />
          ))}
      </Dialog>
      <Dialog
        header="Twin Info"
        visible={visibleTwinInfo}
        style={{ width: '65vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        onHide={() => {
          setVisibleTwinInfo(false);
          setOpenRightSideBar(false);
        }}
      >
        {isLoadingGetTwinInfo && <LoadingRowsComponent />}
        {isSuccessGetTwinInfo && dataGetTwinInfo && (
          <JsonView
            value={dataGetTwinInfo}
            shortenTextAfterLength={1000}
            displayDataTypes={false}
            displayObjectSize={false}
            indentWidth={2}
            style={githubLightTheme}
          />
        )}
      </Dialog>
    </>
  );
};

DeviceMethodsSideBar.defaultProps = {
  clearSingleDevice: () => {}
};
