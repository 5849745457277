import React, { ReactNode, createContext, useMemo, useState } from 'react';
import {
  ConfigDynamicReports,
  ContextState
} from './dynamicReportsProviderInterfaces';

const initialConfig: ConfigDynamicReports = {
  isReportExist: false,
  isSelected: false,
  reportSelected: '',
  isOpenModalReports: false,
  filterText: '',
  isOpenModalFilters: false,
  dataTable: [],
  isReportHaveFilters: false
};

const initialState: ContextState = {
  configDynamicReports: initialConfig,
  addConfigDynamicReports: () => {}
};
export const DynamicReportsContext = createContext<ContextState>(initialState);

export const DynamicReportsProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [configDynamicReports, setConfigDynamicReports] = useState(
    initialState.configDynamicReports
  );

  const value = useMemo(
    () => ({
      configDynamicReports,
      addConfigDynamicReports: (data: any) =>
        setConfigDynamicReports({ ...configDynamicReports, ...data })
    }),
    [configDynamicReports]
  );

  return (
    <DynamicReportsContext.Provider value={value}>
      {children}
    </DynamicReportsContext.Provider>
  );
};
