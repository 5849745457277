import React, { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContainerTable } from '../../locations/locationsList/locationListStyles';

interface DynamicTableProps {
  data: {
    columns: string[];
    values: any[][];
  };
}

export const DbDynamicTable: React.FC<DynamicTableProps> = ({ data }) => {
  const rows = data.values.map((row) => {
    const rowData: { [key: string]: any } = {};
    data.columns.forEach((col, index) => {
      rowData[col] = row[index];
    });
    return rowData;
  });

  return (
    <ContainerTable component="main">
      <DataTable
        value={rows}
        scrollable
        rowHover
        scrollHeight="flex"
        size="small"
      >
        {data.columns.map((col) => (
          <Column key={col} field={col} header={col} />
        ))}
      </DataTable>
    </ContainerTable>
  );
};
