import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import {
  ListContainer,
  TopGrid,
  MiddleGrid,
  GridItem,
  FullWidthStack,
  CustomDivider,
  BottomGrid
} from './statusListStyles.component';
import { StatusListProps } from './statusListProps';
import {
  SplitMacs,
  utcDateToLocalDateTime
} from '../../../utils/dynamicReportsUtils';
import { HealthServices } from '../../../types/dynamicReports/dynamicReportsInterface';
import { ActionService } from '../actionsService/actionService';
import Strings from '../../../i18n';
import { selectCurrentUser } from '../../../store/slices/login/login';

export const StatusList: React.FC<StatusListProps> = ({ data }) => {
  const { isAuthenticated } = useAuth0();
  const currentUser = useSelector(selectCurrentUser);
  return (
    <ListContainer>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
      >
        {data?.map((process: HealthServices) => (
          <GridItem item xs={12} sm={5} xl={2.5} key={process.processName}>
            <TopGrid backgroundColor={process.status ? 'green' : 'red'}>
              <FullWidthStack direction="row" justifyContent="space-between">
                <Typography variant="h6" overflow="auto">
                  {process.processName}
                </Typography>
                {process.status ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <WarningAmberIcon />
                )}
              </FullWidthStack>
            </TopGrid>

            <MiddleGrid>
              <Typography variant="subtitle2">Last updated</Typography>
              <Typography>
                {utcDateToLocalDateTime(process.createdAtFormatted)}
              </Typography>
              <CustomDivider />
              <Typography variant="subtitle2">Message</Typography>
              <Typography>
                {process.statusMessage === 'OK'
                  ? Strings.dynamicReports.ok
                  : SplitMacs(process.statusMessage)}
              </Typography>
            </MiddleGrid>
            {process.action && (isAuthenticated || currentUser?.token) && (
              <BottomGrid>
                <CustomDivider />
                <Typography variant="subtitle2">Actions</Typography>
                <ActionService actions={process.action} />
              </BottomGrid>
            )}
          </GridItem>
        ))}
      </Grid>
    </ListContainer>
  );
};
