import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack, Refresh } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Header } from '../../../components/header/header';
import { ContainerStyled } from '../../../components/layout/main/mainStyles';
import { colors } from '../../../constants/colors';
import Strings from '../../../i18n';
import {
  ButtonIconStyled,
  TitleStyled
} from '../../../views/devices/devicesStyles';
import { useGetSystemStateMutation } from '../../../services/device/deviceService';
import { ContainerTable } from '../../locations/locationsList/locationListStyles';

export const SystemStateByDevice = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [
    getSystemState,
    { isSuccess: isSuccessSystemState, data, isError, isLoading, error }
  ] = useGetSystemStateMutation();
  useEffect(() => {
    if (deviceId) {
      getSystemState(deviceId);
    }
  }, []);

  useEffect(() => {
    if (isError && error) {
      if ('message' in error) {
        toast.error(error.message);
      }
    }
  }, [isError, error]);
  return (
    <>
      <Header>
        <ContainerStyled>
          <div className="row justify-content-between">
            <div className="col-4 text-left">
              <ButtonIconStyled
                size="small"
                onClick={() => navigate('/server-access/devices')}
              >
                <ArrowBack />
              </ButtonIconStyled>
            </div>
            <div className="col-4 text-center">
              <TitleStyled>
                {Strings.serverAccess.devices.systemState}
                <Typography variant="subtitle2">{deviceId}</Typography>
              </TitleStyled>
            </div>
            <div className="col-8 col-sm-4 text-right">
              <Tooltip title="Refresh">
                <ButtonIconStyled
                  size="small"
                  onClick={() => deviceId && getSystemState(deviceId)}
                >
                  <Refresh />
                </ButtonIconStyled>
              </Tooltip>
            </div>
          </div>
        </ContainerStyled>
      </Header>
      <ContainerTable component="main">
        {isSuccessSystemState && data && 'states' in data && (
          <DataTable
            value={data.states}
            size="small"
            scrollable
            paginator={data && data.states.length >= 50}
            selectionPageOnly
            scrollHeight="flex"
            rows={50}
            rowsPerPageOptions={[5, 10, 25, data ? data.states.length : 50]}
            tableStyle={{ fontSize: '14px' }}
          >
            <Column field="name" header="Name" sortable />
            <Column field="value" header="Value" sortable />
            <Column field="lastChanged" header="Last Changed" sortable />
          </DataTable>
        )}
      </ContainerTable>
    </>
  );
};
