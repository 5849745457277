import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { Grid, Tooltip, Typography } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { useGetDeviceAnnounceMutation } from '../../../services/device/deviceService';
import { Header } from '../../../components/header/header';
import { MarginComponent } from '../../../components/marginComponent/marginComponent';
import Strings from '../../../i18n';
import { ButtonIconStyled } from '../../../views/devices/devicesStyles';
import { LoadingRowsComponent } from '../../../components/commons/LoadingRows/LoadingRows';
import { StyledBoxTable } from '../../../components/layout/main/mainStyles';
import {
  DeviceAnnounceInf0,
  IDeviceAnnounceByDeviceId,
  IPayloadInfoDeviceAnnounce,
  IResponseInfoDeviceAnnounce
} from '../../../types/Device/DeviceInterfaces';
import { StyledButtonAction } from '../../deviceAnnounce/globalDeviceAnnounceTemplateStyles';
import { JsonViewItem } from '../../../components/commons/JsonViewItem/jsonViewItem';

const payloadIcon = require('../../../assets/images/deviceAnnounce/payloadAnnounce.png');
const responseIcon = require('../../../assets/images/deviceAnnounce/responseAnnounce.png');

export const DeviceAnnounceTemplate = () => {
  const [dialogType, setDialogType] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const { deviceId } = useParams();
  const [selectedData, setSelectedData] = useState<IDeviceAnnounceByDeviceId>();
  const payloadValues = [
    'deviceId',
    'uniqueProductName',
    'firmwareVersions',
    'hardwareVersions',
    'utcDate'
  ];
  const responseValues = ['sessionId', 'methods'];
  const navigate = useNavigate();

  const [
    getDebugDevice,
    {
      isLoading: isLoadingGetDebugDevice,
      isSuccess: isSuccessGetDebugDevice,
      data: dataDebugDevice
    }
  ] = useGetDeviceAnnounceMutation();

  useEffect(() => {
    if (deviceId) {
      getDebugDevice(deviceId);
    }
  }, []);

  const dataTableSortFunction =
    (value: any[]) =>
    (event: any): any[] => {
      return [...value].sort((data1, data2) => {
        const value1 = new Date(data1[event.field]);
        const value2 = new Date(data2[event.field]);
        if (value1 == null && value2 == null) return 0;
        if (value2 == null) return -1;
        if (value1 == null) return 1;
        if (value1 < value2) return -1 * event.order;
        if (value1 > value2) return 1 * event.order;
        return 0;
      });
    };
  const myProductSort = dataTableSortFunction(dataDebugDevice as any[]);
  const getValuesForPayload = (
    info: IPayloadInfoDeviceAnnounce,
    key: string
  ) => {
    return JSON.stringify(info[key]);
  };
  const getValuesForResponse = (
    info: IResponseInfoDeviceAnnounce | null,
    key: string
  ) => {
    if (info) {
      return JSON.stringify(info[key]);
    }
    return '';
  };
  const getAckString = (rowData: IDeviceAnnounceByDeviceId) => {
    return rowData.ackDate ? Strings.common.yes : Strings.common.no;
  };
  const openToSee = (rowData: IDeviceAnnounceByDeviceId, type: string) => {
    setDialogType(type);
    setSelectedData(rowData);
    setShowDialog(true);
  };
  const getActionButtons = (rowData: IDeviceAnnounceByDeviceId) => {
    return (
      <div>
        <Tooltip title={Strings.common.payload}>
          <StyledButtonAction
            type="button"
            onClick={() => {
              openToSee(rowData, Strings.common.payload);
            }}
          >
            <img src={payloadIcon} alt="Payload" width={30} />
          </StyledButtonAction>
        </Tooltip>
        <Tooltip title={Strings.common.response}>
          <StyledButtonAction
            type="button"
            onClick={() => {
              openToSee(rowData, Strings.common.response);
            }}
          >
            <img src={responseIcon} alt="Payload" width={30} />
          </StyledButtonAction>
        </Tooltip>
      </div>
    );
  };
  const getTime = (rowData: IDeviceAnnounceByDeviceId) => {
    return moment(rowData.createdAt).format('YYYY/MM/DD, h:mmA');
  };
  return (
    <>
      <Header>
        <MarginComponent ml={30} mr={30}>
          <ButtonIconStyled size="small" onClick={() => navigate(-1)}>
            <ArrowBackIos />
          </ButtonIconStyled>
        </MarginComponent>
        <Grid
          container
          justifyContent="center"
          direction="column"
          marginBottom={1}
        >
          <Typography variant="h6" color="initial" textAlign="center">
            {Strings.serverAccess.devices.getDeviceAnnounce}
          </Typography>
          <Typography
            variant="subtitle1"
            color="initial"
            textAlign="center"
            fontSize={12}
          >
            {Strings.serverAccess.devices.currentDevice} {deviceId}
          </Typography>
        </Grid>
      </Header>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <StyledBoxTable>
            {isLoadingGetDebugDevice && <LoadingRowsComponent />}
            {isSuccessGetDebugDevice && dataDebugDevice && (
              <DataTable
                value={dataDebugDevice}
                size="small"
                scrollHeight="flex"
                scrollable
                paginator={dataDebugDevice && dataDebugDevice.length >= 50}
                selectionPageOnly
                rows={50}
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  dataDebugDevice ? dataDebugDevice.length : 50
                ]}
                tableStyle={{ fontSize: '14px' }}
              >
                <Column
                  field="createdAt"
                  header="Time stamp"
                  sortable
                  style={{ width: '300px' }}
                  sortFunction={myProductSort}
                  body={getTime}
                />
                <Column field="" header="Payload" body={getActionButtons} />
                <Column field="firmwareVersion" header="Firmware Version" />
                <Column
                  field="ackDate"
                  header="Method ACK"
                  body={getAckString}
                />
              </DataTable>
            )}
          </StyledBoxTable>
        </Grid>
      </Grid>
      <Dialog
        header={dialogType}
        visible={showDialog}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
        onHide={() => setShowDialog(false)}
      >
        {dialogType === Strings.common.payload && selectedData
          ? payloadValues.map((value) => {
              return (
                <div key={value}>
                  <JsonViewItem
                    title={value}
                    values={getValuesForPayload(selectedData.payload, value)}
                  />
                </div>
              );
            })
          : responseValues.map((value) => {
              return (
                <div key={value}>
                  <JsonViewItem
                    title={value}
                    values={getValuesForResponse(
                      selectedData ? selectedData.response : null,
                      value
                    )}
                  />
                </div>
              );
            })}
      </Dialog>
    </>
  );
};
